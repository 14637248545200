import { useMemo } from 'react'
import {
  CCopyBlock,
  CCopyBlockBlock,
  CCopyBlockFragments,
} from 'ui/components/organisms/c-copy-block'

type Props = {
  field: Array<CCopyBlockBlock>
}

export const CWysiwyg = ({ field }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field : []
  }, [field])

  return (
    <div className="c-wysiwyg" style={{wordWrap: 'break-word'}}>
      {content.map((block, i) => {
        if (block.typeHandle === 'copyBlock') {
          return <CCopyBlock key={i} field={[block]} />
        }

        return <></>
      })}
    </div>
  )
}

export const CWysiwygFragments = {
  field: `
    fragment wysiwyg on wysiwyg_MatrixField {
      ...wysiwyg_copyBlock
    }
    ${CCopyBlockFragments.wysiwygBlock}
  `,
}
