import Head from 'next/head'
import Script from 'next/script'
import { ReactNode } from 'react'

export type SeomaticData = {
  metaTitleContainer: string
  metaTagContainer: string
  metaScriptContainer: string
  metaLinkContainer: string
  metaJsonLdContainer: string
}

type Props = {
  overrideTitle?: string | null
  data: SeomaticData | null
}

type MetaItem = {
  property?: string
  content?: string
}

type LinkItem = {
  href?: string
  rel?: string
  hreflang: string
  type?: string
}

type JsonLdItem = {
  type?: string
  innerHtml?: string
}

export const CSeomatic = ({ data, overrideTitle }: Props) => {
  const normalizedSeoData = (seoData: SeomaticData): ReactNode => {
    const metaTitleContainer = JSON.parse(seoData.metaTitleContainer)
    const metaTagContainer = JSON.parse(seoData.metaTagContainer)
    const metaLinkContainer = JSON.parse(seoData.metaLinkContainer)

    const meta: Array<MetaItem> = metaTagContainer
      ? Object.values(metaTagContainer).reduce<Array<MetaItem>>(
          (flat: any, next: any) => flat.concat(next),
          []
        )
      : []
    const link: Array<LinkItem> = metaLinkContainer
      ? Object.values(metaLinkContainer).reduce<Array<LinkItem>>(
          (flat: any, next: any) => flat.concat(next),
          []
        )
      : []

    return (
      <>
        {!overrideTitle ? (
          <title>{metaTitleContainer?.title?.title}</title>
        ) : (
          <></>
        )}
        {meta &&
          meta.length &&
          meta.map((item: MetaItem, i: number) => {
            return <meta key={i} name={item.property} content={item.content} />
          })}
        {link &&
          link.length &&
          link.map((item: LinkItem, i: number) => {
            return (
              <link
                key={i}
                href={item.href}
                rel={item.rel}
                hrefLang={item.hreflang}
                type={item.type}
              />
            )
          })}
      </>
    )
  }

  const normalizedSeoScripts = (seoData: SeomaticData) => {
    const metaJsonLdContainer = JSON.parse(seoData.metaJsonLdContainer)
    const jsonLd: Array<JsonLdItem> = metaJsonLdContainer
      ? [
          {
            type: 'application/ld+json',
            innerHtml: JSON.stringify(Object.values(metaJsonLdContainer)),
          },
        ]
      : []

    return (
      <>
        {jsonLd &&
          jsonLd.length &&
          jsonLd.map((item: JsonLdItem, i: number) => {
            return (
              <Script
                id={`jsonLd${jsonLd.length === 1 ? '' : i}`}
                type={item.type}
                key={i}
              >
                {item.innerHtml}
              </Script>
            )
          })}
      </>
    )
  }

  return (
    <>
      <Head>
        {overrideTitle ? <title>{overrideTitle}</title> : <></>}
        {data ? normalizedSeoData(data) : <></>}
      </Head>
      {data ? normalizedSeoScripts(data) : <></>}
    </>
  )
}
