import { CraftAssetField } from "tsconfig/craft-types";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { useQuery } from "urql";
import { gql } from "@urql/core";
import { MArticleLink } from "../molecules/m-article-link";
import { AButton } from "../atoms/a-button";
import "./c-article-listing.css";
import { useSiteInfo } from "shared";

type Article = {
  id?: string;
  title?: string;
  typeHandle?: string;
  uri?: string;
  testimonialOccupation?: string;
  image?: Array<CraftAssetField>;
};

export type CArticleListingBlock = {
  id?: string;
  typeHandle?: string;
  idName?: string;
  trendingArticles: Array<Article>;
};

type Props = {
  field: Array<CArticleListingBlock>;
  excludeId?: number;
};

export const CArticleListing = ({ field }: Props) => {
  const { t } = useTranslation("common");
  const siteInfo = useSiteInfo();

  const content = useMemo(() => {
    return field && field.length ? field[0] : null;
  }, [field]);

  const excludeIds = useMemo(() => {
    return (
      content?.trendingArticles
        ?.filter((article) => {
          return (
            article.typeHandle === "blog" || article.typeHandle === "legacyBlog"
          );
        })
        .map((article) => {
          return article.id;
        }) || []
    );
  }, [content]);

  const getArticleTitle = (article: Article) => {
    if (article.typeHandle === "testimonialPages") {
      return `Meet ${article.testimonialOccupation} ${article.title}`;
    } else if (["blog", "legacyBlog"].includes(article.typeHandle ?? "")) {
      return article.title;
    }

    return "";
  };

  const [{ data: articlesCountQuery }] = useQuery({
    query: gql`
      query ($site: [String]!) {
        articlesCount: entryCount(section: "blog", site: $site)
      }
    `,
    variables: {
      site: siteInfo.handle,
    },
  });

  const [{ data: articlesQuery }] = useQuery({
    query: gql`
      query Entry(
        $section: [String]
        $limit: Int
        $id: [QueryArgument]
        $offset: Int
        $site: [String]!
      ) {
        articles: entries(
          section: $section
          orderBy: "postDate DESC"
          limit: $limit
          id: $id
          offset: $offset
          site: $site
        ) {
          id
          sectionHandle
          typeHandle
          uri
          title
          ... on blog_blog_Entry {
            image: blogImage {
              url @transform(mode: "crop", width: 600, immediately: true)
              title
            }
          }
          ... on blog_legacyBlog_Entry {
            image: blogImage {
              url @transform(mode: "crop", width: 600, immediately: true)
              title
            }
          }
        }
      }
    `,
    variables: {
      site: siteInfo.handle,
      section: "blog",
      offset: 0,
      limit: 9,
      id: ["not", ...excludeIds],
    },
  });

  const fetchMoreArticles = () => {
    articlesQuery.fetchMore({
      variables: {
        section: "blog",
        offset: articlesQuery?.data?.articles?.length,
        limit: 9,
        id: ["not", ...excludeIds],
      },
      updateQuery: (
        previousResult: any,
        { fetchMoreResult }: { fetchMoreResult: any }
      ) => {
        fetchMoreResult.articles = [
          ...previousResult.articles,
          ...fetchMoreResult.articles,
        ];
        return fetchMoreResult;
      },
    });
  };

  return (
    <section className="c-article-listing mt-8 md:mt-20 mb-14 md:mb-27">
      <div className="o-container o-container--xl">
        <h2 className="text-h6 md:text-h4 text-neutral-dark-gray">
          {t("trending-news")}
        </h2>
        <div className="c-article-listing__trending mt-10 md:flex">
          {content?.trendingArticles.map((article: Article) => {
            return (
              <MArticleLink
                key={article.id}
                className="c-article-listing__trending-item"
                url={`/${article.uri}`}
                heading={getArticleTitle(article) ?? ""}
                image={article.image?.length ? article.image[0].url : null}
                imageAlt={article.image?.length ? article.image[0].title : null}
                linkText={t("read-article") ?? "Read Story"}
              />
            );
          })}
        </div>
        <div className="c-article-listing__articles flex flex-wrap">
          {articlesQuery?.data?.articles?.map((article: Article) => {
            return (
              <MArticleLink
                key={article.id}
                className="c-article-listing__articles-item"
                url={`/${article.uri}`}
                heading={getArticleTitle(article) ?? ""}
                image={article.image?.length ? article.image[0].url : null}
                imageAlt={article.image?.length ? article.image[0].title : null}
                linkText={t("read-article") ?? "Read Story"}
              />
            );
          })}
        </div>
        {(articlesQuery?.data?.articles?.length ?? 0) <
          (articlesCountQuery?.data?.articlesCount ?? 0) && (
          <div className="flex justify-center mt-14">
            <AButton
              type="button"
              style="solid"
              color="black"
              size="lg"
              onClick={fetchMoreArticles}
            >
              <span>{t("more-articles")}</span>
            </AButton>
          </div>
        )}
      </div>
    </section>
  );
};

export const CArticleListingFragments = {
  field: `
    fragment articleListing on articleListing_MatrixField {
      ... on articleListing_block_BlockType {
        id
        typeHandle
        idName
        trendingArticles {
          id
          title
          typeHandle
          uri
          ... on testimonialPages_testimonialPages_Entry {
            testimonialOccupation
            image: testimonialImage {
              url @transform(mode: "crop", width: 600, immediately: true)
              title
            }
          }
          ... on blog_blog_Entry {
            image: blogImage {
              url @transform(mode: "crop", width: 600, immediately: true)
              title
            }
          }
          ... on blog_legacyBlog_Entry {
            image: blogImage {
              url @transform(mode: "crop", width: 600, immediately: true)
              title
            }
          }
        }
      }
    }
  `,
};
