import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { ASelect } from '../atoms/a-select'
import { MFormSearch } from '../molecules/m-form-search'
import { ASvg } from '../atoms/a-svg'
import SvgSearchIcon from '../../svg/icons/stroke/search.svg'
import { CFindADoctorBlock } from './c-find-a-doctor'
import Image from 'next/image'

// TODO: Fix types
interface CFindADoctorUsProps {
  content: CFindADoctorBlock
  regions: any[]
  submitRegionSearch: (region: string) => void
  submitDoctorSearch: any
}
export const CFindADoctorUs = ({
  content,
  regions,
  submitRegionSearch,
  submitDoctorSearch,
}: CFindADoctorUsProps) => {
  const { t } = useTranslation('common')

  return (
    <section
      id={content.idName}
      className={classNames(
        'relative c-find-a-doctor-us bg-neutral-teal-light -mt-[30px]'
      )}
    >
      {!!content.backgroundImage?.length && (
        <Image
          src={content.backgroundImage[0].url ?? ''}
          alt={content.backgroundImage[0].title ?? ''}
          fill
          style={{ objectFit: 'cover' }}
          className="c-find-a-doctor-us__background-image"
        />
      )}
      <div className="relative flex items-center px-5 py-16 o-container o-container--lg">
        <div className="w-full c-find-a-doctor-us-us__content flex flex-col md:flex-row items-center">
          <div className="mx-auto c-find-a-doctor-us__body flex-1">
            {content.heading && (
              <h2
                className={classNames(
                  'font-normal text-center text-2xl md:text-3xl text-primary-dark-teal whitespace-pre-line c-find-a-doctor-us__heading'
                )}
                dangerouslySetInnerHTML={{ __html: content.heading }}
              />
            )}
            {content.copy && (
              <div
                className="mt-4 font-semibold text-center text-p text-neutral-white s-wysiwyg"
                dangerouslySetInnerHTML={{ __html: content.copy }}
              />
            )}
          </div>
          <div
            className={classNames(
              'mx-auto mt-8 md:mt-0 md:flex md:rounded-full md:p-6 w-full md:w-auto md:min-w-[61%] flex-1',
              regions.length
                ? 'c-find-a-doctor-us__regions-box'
                : 'c-find-a-doctor-us__search-box'
            )}
          >
            {!!regions.length && (
              <ASelect
                className="regions"
                options={regions}
                value=""
                onChangeHandler={submitRegionSearch}
              />
            )}

            {!regions.length && (
              <>
                <div className="flex-grow w-full rounded-full md:bg-transparent text-primary-dark-teal">
                  <MFormSearch
                    icon={
                      <ASvg
                        aria-label="Search"
                        className="stroke-current w-8 h-8"
                        svg={SvgSearchIcon}
                      />
                    }
                    text={t('enter-a-city-state-or-region') || ''}
                    type="text"
                    onSubmit={submitDoctorSearch}
                    className="md:text-lg"
                  />
                </div>
              </>
            )}
          </div>
          {content.additionalCopy && (
            <div className="mx-auto c-find-a-doctor-us__body">
              <div
                className="mt-8 italic text-center text-neutral-white c-find-a-doctor-us__additional-copy"
                dangerouslySetInnerHTML={{ __html: content.additionalCopy }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
