import { useEffect, useMemo, useRef, useState } from "react";
import { CraftAssetField } from "tsconfig/craft-types";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import { gql } from "@urql/core";
import { useQuery } from "urql";
import Glide from "@glidejs/glide";
import { throttle } from "lodash";
import { MArticleLink } from "../molecules/m-article-link";
import { AButton } from "../atoms/a-button";
import "./c-related-articles.css";
import { useSiteInfo } from "shared";

type Article = {
  id?: string;
  uri?: string;
  title?: string;
  typeHandle?: string;
  testimonialOccupation?: string;
  image?: Array<CraftAssetField>;
};

export type CRelatedArticlesBlock = {
  id?: string;
  typeHandle?: string;
  idName?: string;
  heading?: string;
  articles?: Array<Article>;
};

type Props = {
  field: Array<CRelatedArticlesBlock>;
  excludeId?: number | null;
  loadMoreLabel?: string;
};

const defaultProps = {
  excludeId: null,
  loadMoreLabel: "More",
};

const CRelatedArticles = ({ field, excludeId, loadMoreLabel }: Props) => {
  const { t } = useTranslation("common");
  const siteInfo = useSiteInfo();

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  const mobileBreakpoint = 768;

  const sliderRef = useRef<HTMLDivElement | null>(null);
  const glideRef = useRef<Glide | null>(null);

  const content = useMemo(() => {
    return field && field.length ? field[0] : null;
  }, [field]);

  const extraType = useMemo(() => {
    return content && content.articles?.length
      ? content.articles[0].typeHandle
      : "testimonialPages";
  }, [content]);

  const extraLimit = useMemo(() => {
    return content ? 3 - (content.articles?.length ?? 0) : 0;
  }, [content]);

  const extraExclude = useMemo(() => {
    if (content && content.articles) {
      const ids = content.articles.map((item) => {
        return item.id;
      });
      const result = ["not", ...ids];
      if (excludeId) {
        result.push(excludeId.toString());
      }
      return result;
    } else {
      return ["not", 0];
    }
  }, [content]);

  const [{ data: articlesCountQuery }] = useQuery({
    query: gql`
      query ($type: [String], $id: [QueryArgument], $site: [String]!) {
        articlesCount: entryCount(type: $type, id: $id, site: $site)
      }
    `,
    variables: {
      type: extraType,
      id: extraExclude,
      site: siteInfo.handle,
    },
  });

  const [{ data: extraArticlesQuery }] = useQuery({
    query: gql`
      query Entry(
        $type: [String]
        $limit: Int
        $offset: Int
        $id: [QueryArgument]
        $site: [String]!
      ) {
        extraArticles: entries(
          type: $type
          orderBy: "dateCreated DESC"
          limit: $limit
          offset: $offset
          id: $id
          site: $site
        ) {
          id
          sectionHandle
          typeHandle
          uri
          title
          ... on testimonialPages_testimonialPages_Entry {
            testimonialOccupation
            image: testimonialImage {
              url @transform(mode: "crop", width: 800, immediately: true)
              title
            }
          }
        }
      }
    `,
    variables: {
      type: extraType,
      offset: 0,
      limit: extraLimit,
      id: extraExclude,
      site: siteInfo.handle,
    },
  });

  const articles = useMemo(() => {
    return (
      content?.articles?.concat(
        extraArticlesQuery?.data?.extraArticles?.length
          ? extraArticlesQuery?.data?.extraArticles
          : []
      ) || []
    );
  }, [content, extraArticlesQuery]);

  const getArticleTitle = (article: Article) => {
    if (article.typeHandle === "testimonialPages") {
      if (siteInfo.isJP) {
        return `${article.title}`;
      }
      return `${article.testimonialOccupation} ${article.title}`;
    } else {
      return "";
    }
  };

  const getArticleSubtitle = (article: Article) => {
    if (article.typeHandle === "testimonialPages" && siteInfo.isJP) {
      return article.testimonialOccupation;
    }
  };

  const loadMore = () => {
    extraArticlesQuery.fetchMore({
      variables: {
        type: extraType,
        offset: extraArticlesQuery?.data?.extraArticles?.length,
        limit: 3,
        id: extraExclude,
      },
      updateQuery: (
        previousResult: any,
        { fetchMoreResult }: { fetchMoreResult: any }
      ) => {
        fetchMoreResult.extraArticles = [
          ...previousResult.extraArticles,
          ...fetchMoreResult.extraArticles,
        ];
        return fetchMoreResult;
      },
    });
  };

  const goToSlide = (i: number) => {
    if (glideRef.current) {
      glideRef.current.go(`=${i}`);
    }
  };

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth < mobileBreakpoint && !glideRef.current) {
      mountSlider();
    } else if (window.innerWidth >= mobileBreakpoint && glideRef.current) {
      unmountSlider();
    }
  };

  const handleWindowResizeThrottled = throttle(handleWindowResize, 100);

  const mountSlider = () => {
    if (sliderRef.current) {
      const slider = new Glide(sliderRef.current, {
        direction: siteInfo.dir as "ltr" | "rtl" | undefined,
        gap: 20,
        rewind: false,
      });
      slider.mount();
      slider.on("run", () => {
        setActiveSlide(slider.index);
      });

      glideRef.current = slider;
    }
  };

  const unmountSlider = () => {
    if (glideRef.current) {
      glideRef.current.destroy();
      glideRef.current = null;
    }
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    if (content) {
      window.addEventListener("resize", handleWindowResizeThrottled);
    }
    return () => {
      if (content) {
        window.removeEventListener("resize", handleWindowResizeThrottled);
      }
    };
  }, []);

  useEffect(() => {
    if (content?.articles?.length && window.innerWidth < mobileBreakpoint) {
      mountSlider();
    }

    return () => {
      if (content && glideRef.current) {
        glideRef.current.destroy();
        glideRef.current = null;
      }
    };
  }, [content, extraArticlesQuery]);

  if (!content?.articles?.length) {
    return <></>;
  }

  return (
    <section className="c-related-articles mt-26 mb-14 w-full">
      <div className="c-related-article-container o-container o-container--lg w-full">
        {content.heading && (
          <h2 className="text-h6 md:text-h4 font-light text-neutral-dark-gray mb-6 md:mb-8">
            {content.heading}
          </h2>
        )}
        <div ref={sliderRef} className="c-related-articles__slider">
          <div className="glide__track" data-glide-el="track">
            <div
              className={classNames("c-related-articles", {
                glide__slides: windowWidth <= mobileBreakpoint,
                "flex flex-wrap": windowWidth > mobileBreakpoint,
              })}
            >
              {articles.map((article: Article) => {
                return (
                  <MArticleLink
                    key={article.id}
                    className="c-related-articles__item glide__slide"
                    url={`/${article.uri}`}
                    heading={getArticleTitle(article) ?? ""}
                    subheading={getArticleSubtitle(article)}
                    image={article.image?.length ? article.image[0].url : null}
                    imageAlt={
                      article.image?.length ? article.image[0].title : null
                    }
                    linkText={t("read-story") ?? "Read Story"}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {(extraArticlesQuery?.data?.extraArticles?.length ?? 0) <
          (articlesCountQuery?.data?.articlesCount ?? 0) && (
          <div className="hidden md:flex justify-center mt-12">
            <AButton
              type="button"
              style="outline"
              color="gray"
              size="lg"
              onClick={loadMore}
            >
              <span>{loadMoreLabel}</span>
            </AButton>
          </div>
        )}
        <div className="md:hidden flex justify-center mt-9">
          {articles.map((item, n) => {
            return (
              <button
                key={n}
                className="flex items-center justify-center w-6 h-6"
                onClick={() => goToSlide(n)}
              >
                <div
                  className={classNames(
                    "w-2 h-2 rounded-full transition-colors duration-200 ease-out",
                    {
                      "bg-primary-gray": activeSlide !== n,
                      "bg-primary-teal": activeSlide === n,
                    }
                  )}
                />
              </button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

CRelatedArticles.defaultProps = defaultProps;

export { CRelatedArticles };

export const CRelatedArticlesFragmets = {
  field: `
    fragment relatedArticles on relatedArticles_MatrixField {
      ... on relatedArticles_block_BlockType {
        id
        typeHandle
        idName
        heading
        articles {
          id
          uri
          title
          typeHandle
          ... on testimonialPages_testimonialPages_Entry {
            testimonialOccupation
            image: testimonialImage {
          url @transform(mode: "crop", width: 800, immediately: true)
          title
            }
          }
        }
      }
    }
  `,
};
