import classNames from 'classnames'
import { ChangeEvent, useMemo, useState } from 'react'
import css from 'styled-jsx/css'
import { ASvg } from 'ui'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'
import useSettings from '../../hooks/use-settings'

export type MDropdownOption = {
  option: string
  value: string
  selected: boolean
}

type Props = {
  label: string
  full?: boolean
  placeholder?: string
  fullRounded?: boolean
  options: Array<MDropdownOption>
  onChange: (option: MDropdownOption) => void
}

const defaultProps = {
  full: false,
  placeholder: '',
  fullRounded: false,
}

const MDropdown = ({
  label,
  full,
  fullRounded,
  placeholder,
  options,
  onChange,
}: Props) => {
  const settings = useSettings()
  const siteInfo = useSiteInfo()

  const [open, setOpen] = useState<boolean>(false)

  const selected = useMemo(() => {
    return options.find((v) => v.selected) ?? false
  }, [options])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleOptionClick = (option: MDropdownOption) => {
    setOpen(false)
    if (onChange instanceof Function) {
      onChange(option)
    }
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const option = options.find((v) => v.value === event.target.value)
    if (option) {
      handleOptionClick(option)
    }
  }

  return (
    <div
      className={classNames(
        'm-dropdown relative font-semibold outline-none select-none font-body font-base',
        {
          'border border-gray-700': full,
          'bg-gray-100 rounded-lg':
            full && siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
          'bg-white': full && siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
          'flex-1 md:flex-initial': fullRounded,
          'text-neutral-black flex items-center': siteInfo.isUS,
          'text-gray-800': !siteInfo.isUS,
        }
      )}
      tabIndex={0}
      role="listbox"
      aria-label="label"
      onBlur={handleBlur}
    >
      {!full && siteInfo.isUS && (
        <span className="mr-2">
          {label}
          {!fullRounded && ':'}&nbsp;
        </span>
      )}
      <div
        className={classNames('flex items-center py-4 cursor-pointer', {
          'justify-between px-4': full,
          'border border-gray-700 py-0 rounded-full pr-2 pl-4 justify-between m-dropdown-select w-full sm:min-w-[160px]':
            fullRounded,
        })}
        onClick={toggleOpen}
      >
        {!full && !siteInfo.isUS && (
          <span className="mr-4">{label}:&nbsp;</span>
        )}
        <span>{selected ? selected.option : placeholder}</span>
        <ASvg
          className={classNames(
            'w-8 h-8 transition-transform duration-100 ease-out transform stroke-current',
            {
              'text-pink-500': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
              'text-primary-teal': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
              'rotate-180': open,
              'ml-2': !settings.rtl,
              'mr-2': settings.rtl,
              'text-neutral-black': siteInfo.isUS,
            }
          )}
          svg={SvgArrowDownIcon}
        />
      </div>
      <select
        className="absolute top-0 left-0 w-full h-full outline-none opacity-0 appearance-none md:hidden"
        aria-label="label"
        onChange={handleSelectChange}
        defaultValue=""
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options?.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              disabled={!option.value}
            >
              {option.option}
            </option>
          )
        })}
      </select>
      {open && (
        <ul
          className={classNames(
            'absolute z-50 invisible overflow-y-auto max-h-[296px] md:visible',
            {
              'm-dropdown__list right-0 bg-white': !full,
              'm-dropdown__full-list border-l border-r border-b border-gray-900 overflow-hidden pt-2':
                full,
              'bg-gray-100 rounded-lg':
                full && siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
              'bg-white': full && siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
              'top-[95%] min-w-[65%]': siteInfo.isUS,
            }
          )}
        >
          {options?.map((option) => {
            return (
              <li
                key={option.value}
                className={classNames('py-3 cursor-pointer', {
                  'hover:text-pink-500':
                    siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                  'hover:text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                  'text-pink-500':
                    siteInfo?.layout === CONST.SITE_LAYOUT.EVO &&
                    selected &&
                    selected.value === option.value,
                  'text-primary-teal':
                    siteInfo?.layout === CONST.SITE_LAYOUT.ICL &&
                    selected &&
                    selected.value === option.value,
                  'px-2': !full,
                  'px-4': full,
                })}
                onClick={() => option.value && handleOptionClick(option)}
              >
                {option.option}
              </li>
            )
          })}
        </ul>
      )}
      <style jsx>{styles}</style>
    </div>
  )
}

const styles = css`
  .m-dropdown__icon {
    height: 9px;
    width: 15px;
  }

  .m-dropdown__list {
    box-shadow: 1px 0.1px 12px rgba(4, 29, 62, 0.0898711);
  }

  .m-dropdown__full-list {
    top: 100%;
    width: calc(100% + 2px);
    left: -1px;
  }

  .m-dropdown__full-list.rounded-lg {
    border-radius: 0 0 0.5rem 0.5rem !important;
  }
`

MDropdown.defaultProps = defaultProps

export { MDropdown }
