import { CraftLinkItField } from 'tsconfig/craft-types'
import { AButton } from '../atoms/a-button'
import './c-expanded-cta-section.css'

export interface CExpandedCtaSectionBlock {
  heading?: string
  subHeading?: string
  cta?: CraftLinkItField
  copy?: string
}
interface CExpandedCtaSectionProps {
  field: Array<CExpandedCtaSectionBlock>
}

export const CExpandedCtaSection = ({ field }: CExpandedCtaSectionProps) => {
  const { heading, subHeading, cta, copy } = field?.[0] ?? {}

  return (
    <>
      {field?.length && (
        <div className="c-expanded-cta-section">
          <div className="c-expanded-cta-section__icons">
            <div className="o-container o-container--xl relative z-10">
              {heading && (
                <h2 className="text-center text-3xl md:text-5xl mb-[40px]">
                  {heading}
                </h2>
              )}
              {subHeading && (
                <h3 className="text-center text-xl md:text-2xl mb-[40px] md:mb-[55px]">
                  {subHeading}
                </h3>
              )}
              {cta?.url && (
                <div className="flex justify-center">
                  <AButton
                    className="w-[290px] md:w-[340px]"
                    type={cta.type}
                    href={cta?.url}
                    rel={cta.type === 'url' ? 'noreferrer' : ''}
                    target={cta.target}
                    color="white"
                  >
                    <span>{cta.text}</span>
                  </AButton>
                </div>
              )}
              {copy && (
                <div
                  className="c-expanded-cta-section__copy mt-[60px] md:mt-[85px] text-lg"
                  dangerouslySetInnerHTML={{ __html: copy }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const CExpandedCtaSectionFragment = `
  fragment expandedCtaSection on expandedCtaSection_MatrixField {
    ... on expandedCtaSection_block_BlockType {
      heading
      subHeading
      cta {
        type
        url
        text
        target
      }
      copy
    }
  }
`
