import classNames from 'classnames'
import useStateRef from 'discovericl/hooks/use-state-ref'
import { throttle } from 'lodash'
import { useRef, useMemo, useEffect } from 'react'
import { CraftLinkItField } from 'tsconfig/craft-types'
import { AButton, AHyperlink } from 'ui'
import { AnimationItem } from 'lottie-web'
import './c-counter.css'
// import SvgArrowRightIcon from 'ui/svg/icons/stroke/arrow-right.svg'

export type CCounterBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  copy?: string
  hyperlink?: CraftLinkItField
  button?: CraftLinkItField
}

type Props = {
  field: Array<CCounterBlock>
}

export const CCounter = ({ field }: Props) => {
  const [active, setActive, activeRef] = useStateRef(false)
  const [, setAnimated, animatedRef] = useStateRef(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const animationRef = useRef<HTMLDivElement>(null)
  const lottieRef = useRef<AnimationItem | null>(null)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const loadLottieAnimation = async () => {
    const { default: lottie } = await import('lottie-web')
    if (animationRef.current && !lottieRef.current) {
      lottieRef.current = lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/animations/30-minute-counter.json',
      })
    }
  }

  const handleWindowScroll = () => {
    if (containerRef.current) {
      setActive(
        containerRef.current.getBoundingClientRect().top <=
          window.innerHeight * 0.5
      )
    }

    if (!activeRef.current && lottieRef.current) {
      setAnimated(false)
      lottieRef.current.goToAndStop(0)
    }

    if (activeRef.current && !animatedRef.current && lottieRef.current) {
      lottieRef.current.goToAndPlay(0)
      setAnimated(true)
    }
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    setActive(false)
    window.addEventListener('scroll', handleWindowScrollThrottled)
    handleWindowScroll()
    loadLottieAnimation()

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
    }
  }, [])

  return (
    <div id={content?.idName} className="c-counter">
      <section
        ref={containerRef}
        className={classNames(
          'm-3 c-counter__container md:m-6 lg:mx-6 lg:flex bg-primary-bright-teal text-neutral-white lg:py-32',
          {
            'is-active': active,
          }
        )}
      >
        <div className="relative flex flex-col items-center justify-center w-full px-6 pt-8 mt-3 md:mt-6 lg:mt-0 lg:w-1/2 lg:mx-3 lg:py-14">
          <div className="w-full transition-opacity c-counter__body">
            <h2
              className="font-normal duration-500 c-counter__heading text-h6 whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: content?.heading ?? '' }}
            />
            {content?.copy && (
              <div
                className="mt-4 font-semibold duration-500 c-counter__copy c-counter__heading text-p s-wysiwyg"
                dangerouslySetInnerHTML={{ __html: content?.copy ?? '' }}
              />
            )}
            {content?.button?.url && (
              <div className="mt-8">
                <AButton
                  type={content.button.type === 'url' ? 'a' : 'link'}
                  href={content.button.url}
                  style="solid"
                  color="white"
                  rel={content.button.type === 'url' ? 'noreferrer' : ''}
                  target={content.button.target}
                >
                  <span>{content.button.text}</span>
                </AButton>
              </div>
            )}
            {content?.hyperlink?.url && (
              <AHyperlink
                type={content.hyperlink.type === 'url' ? 'a' : 'link'}
                href={content.hyperlink.url}
                className="mt-8"
              >
                <span>{content.hyperlink.text}</span>
              </AHyperlink>
            )}
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-center w-full px-6 md:mt-6 lg:mt-0 lg:w-1/2 lg:mx-3 lg:py-14">
          <div
            ref={animationRef}
            className="c-counter__animation"
            aria-label="Counter Animation"
            role="img"
          />
        </div>
      </section>
    </div>
  )
}

export const CCounterFragments = {
  field: `
    fragment counter on counter_MatrixField {
      ... on counter_block_BlockType {
        id
        typeHandle
        idName
        heading
        copy
        hyperlink {
          type
          url
          text
          target
        }
        button {
          type
          url
          text
          target
        }
      }
    }
  `,
}
