import classNames from 'classnames'
import { AVideo, AVideoMethods } from '../atoms/a-video'
import { ASvg } from '../atoms/a-svg'
import SvgPlayIcon from '../../svg/icons/stroke/play.svg'
import { useRef, useState } from 'react'
import { CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import Image from 'next/image'
import { MVideoModal } from './m-video-modal'

interface MVideoThumbnailProps {
  video?: CraftEmbeddedAssetField[] | undefined
  vidRef?: AVideoMethods | HTMLImageElement | null | any
  className?: string
  thumbnail?: string
  playTop?: boolean
  iframeUrl?: string
  notEmbedded?: boolean
  position?: string
  onClick?: (param: string | undefined) => void
}

export const MVideoThumbnail = ({
  video,
  vidRef,
  className,
  thumbnail,
  playTop,
  position = 'center',
  notEmbedded = false,
  onClick,
}: MVideoThumbnailProps) => {
  const videoRef = useRef<AVideoMethods | HTMLImageElement | null>(
    vidRef ?? null
  )
  const [videoPlayed, setVideoPlayed] = useState<boolean>(false)

  const playVideo = () => {
    setVideoPlayed(true)

    if (videoRef?.current) {
      const video = videoRef?.current as AVideoMethods
      video.play()
    }
  }

  const pauseVideo = () => {
    setVideoPlayed(false)

    if (videoRef?.current) {
      const video = videoRef?.current as AVideoMethods
      video.pause()
    }
  }

  return (
    <div
      className={`relative ${className ?? ''}`}
      onClick={videoPlayed ? pauseVideo : undefined}
    >
      {!!(video && video.length) && (
        <div
          className={classNames('absolute inset-0 z-10', {
            hidden: videoPlayed,
          })}
        >
          {thumbnail ? (
            <Image
              src={thumbnail}
              alt={video?.[0]?.title ?? ''}
              fill
              style={{
                objectFit: 'cover',
                objectPosition: `center ${position}`,
              }}
            />
          ) : (
            <>
              {notEmbedded ? (
                <video className="w-full" width="657" height="452" controls>
                  <source src={video?.[0]?.url} type="video/mp4" />
                </video>
              ) : (
                <AVideo
                  className="block w-full c-video__video h-full"
                  playsInline
                  controls={false}
                  src={(video?.[0].embeddedAsset || {}).url}
                  aria-hidden="true"
                />
              )}
            </>
          )}
          <button
            className={classNames(
              'c-video__cover w-full h-full absolute inset-0 flex z-20 p-[20px] bg-red',
              {
                'items-top justify-start': playTop,
                'items-center justify-center': !playTop,
              }
            )}
            aria-label={`Play Video: ${video?.[0]?.title}`}
            onClick={() =>
              onClick
                ? onClick((video?.[0].embeddedAsset || {}).url)
                : playVideo()
            }
          >
            <div
              className={classNames(
                'relative z-1 w-18 h-18 hover:text-neutral-white rounded-full flex items-center justify-center hover:bg-primary-teal transition-colors duration-300 ease-out',
                {
                  'bg-neutral-light-gray text-secondary-violet': playTop,
                  'bg-neutral-white text-neutral-dark-gray': !playTop,
                }
              )}
            >
              <ASvg
                className={classNames('stroke-current fill-current ml-2', {
                  'w-18 h-18': !playTop,
                  'w-10 h-10': playTop,
                })}
                svg={SvgPlayIcon}
                ariaLabel="Play"
              />
            </div>
          </button>
        </div>
      )}
      {!!(video && video.length) && (
        <>
          {notEmbedded ? (
            <video className="w-full" width="657" height="452" controls>
              <source src={video?.[0]?.url} type="video/mp4" />
            </video>
          ) : (
            <AVideo
              ref={(el) => {
                videoRef.current = el as AVideoMethods
              }}
              className="block w-full c-video__video h-full"
              playsInline
              controls={true}
              src={(video?.[0].embeddedAsset || {}).url}
            />
          )}
        </>
      )}
    </div>
  )
}
