import { useEffect, useMemo, useRef, useState } from 'react'
import throttle from 'lodash/throttle'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import SvgIconArrow from 'ui/svg/icons/stroke/arrow.svg'
import classNames from 'classnames'
import { AButton, APicture, ASvg } from 'ui'
import { useTranslation } from 'next-i18next'
import Hls from 'hls.js'
import './c-testimonial-masthead.css'

export type CTestimonialMastheadBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  color?: string
  heading?: string
  copy?: string
  desktopImage?: Array<CraftAssetField>
  mobileImage?: Array<CraftAssetField>
  backgroundVideo?: Array<CraftEmbeddedAssetField>
}

type Props = {
  field: Array<CTestimonialMastheadBlock>
  name?: string
  displayImage?: boolean
}

export const CTestimonialMasthead = ({
  field,
  name,
  displayImage = true,
}: Props) => {
  const { t } = useTranslation('common')
  const [infoExpanded, setInfoExpanded] = useState(false)
  const [infoBoxHeight, setInfoBoxHeight] = useState(0)

  const backgroundVideoRef = useRef<HTMLVideoElement>(null)
  const infoBoxRef = useRef<HTMLDivElement>(null)
  const sectionRef = useRef<HTMLElement>(null)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const personName = useMemo(() => {
    if (name) {
      const split = name.split(' ')
      return split[0]
    } else {
      return null
    }
  }, [name])

  const getInfoBoxHeight = () => {
    if (infoBoxRef.current) {
      return infoBoxRef.current.scrollHeight
    } else {
      return 0
    }
  }

  const handleWindowResize = () => {
    setInfoExpanded(false)
    setInfoBoxHeight(getInfoBoxHeight())
  }

  const windowResizeEventThrottled = throttle(handleWindowResize, 100)

  const handleScrollButtonClick = () => {
    window.scrollTo({
      top: sectionRef?.current?.scrollHeight,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    setInfoBoxHeight(getInfoBoxHeight())
    window.addEventListener('resize', windowResizeEventThrottled)

    if (content?.backgroundVideo?.length) {
      const assetUrl = content.backgroundVideo[0].embeddedAsset?.url || ''
      const videoUrl = content.backgroundVideo[0].url || ''
      const videoEl = backgroundVideoRef.current!

      if (assetUrl && Hls.isSupported()) {
        const hls = new Hls()
        hls.on(Hls.Events.ERROR, console.error)
        hls.loadSource(assetUrl)
        hls.attachMedia(videoEl)
      } else if (
        assetUrl &&
        videoEl.canPlayType('application/vnd.apple.mpegurl')
      ) {
        videoEl.src = assetUrl
      } else if (videoUrl) {
        videoEl.src = videoUrl
      }
    }

    return () => {
      window.removeEventListener('resize', windowResizeEventThrottled)
    }
  }, [])

  return (
    <section ref={sectionRef} className="c-testimonial-masthead">
      <div className="c-testimonial-masthead__spacer" />
      <div
        className={classNames(
          'relative flex justify-between px-6 py-8 c-testimonial-masthead__content lg:m-6 lg:py-20 lg:px-24',
          {
            'bg-primary-dark-teal':
              content?.color === 'teal' || content?.color === null,
            'bg-secondary-orange': content?.color === 'orange',
            'bg-secondary-green': content?.color === 'green',
            'bg-neutral-dark-gray': content?.color === 'gray',
          }
        )}
      >
        <div className="flex-shrink-0 w-full c-testimonial-masthead__heading-column lg:mie-12">
          <h1 className="text-h3 text-neutral-white">{content?.heading}</h1>
          <AButton
            type="button"
            style="outline"
            color="white"
            className="hidden mt-10 rounded-full lg:block c-testimonial-masthead__scroll-button"
            onClick={handleScrollButtonClick}
          >
            <ASvg
              className="transform rotate-90 stroke-current"
              svg={SvgIconArrow}
              ariaLabel="Arrow"
            />
          </AButton>
        </div>
        <div
          className="hidden w-full lg:block c-testimonial-masthead__copy text-p-lg text-neutral-white"
          dangerouslySetInnerHTML={{ __html: content?.copy || '' }}
        />
      </div>

      <div
        ref={infoBoxRef}
        className={classNames(
          'overflow-hidden c-testimonial-masthead__info lg:hidden',
          {
            'c-testimonial-masthead__info--expanded': infoExpanded,
            'bg-primary-dark-teal':
              content?.color === 'teal' || content?.color === null,
            'bg-secondary-orange': content?.color === 'orange',
            'bg-secondary-green': content?.color === 'green',
            'bg-neutral-dark-gray': content?.color === 'gray',
          }
        )}
        style={{
          height: !infoExpanded ? '64px' : `${infoBoxHeight + 46}px`,
        }}
      >
        <button
          className="w-full px-6 c-testimonial-masthead__expand-button"
          onClick={() => setInfoExpanded(!infoExpanded)}
        >
          <div className="flex justify-between text-neutral-white">
            <div className="font-semibold text-p">
              {t('about', { name: personName })}
            </div>
            <div className="flex-shrink-0 c-testimonial-masthead__plus">
              <div className="c-testimonial-masthead__plus-bar" />
              <div className="c-testimonial-masthead__plus-bar c-testimonial-masthead__plus-bar--vertical" />
            </div>
          </div>
        </button>
        <div
          className="mx-6 text-neutral-white text-p-lg pt-11 pb"
          dangerouslySetInnerHTML={{ __html: content?.copy || '' }}
        />
        <AButton
          type="button"
          style="outline"
          color="white"
          className="mt-6 rounded-full mis-6 c-testimonial-masthead__scroll-button"
          onClick={handleScrollButtonClick}
        >
          <ASvg
            className="transform rotate-90 stroke-current"
            svg={SvgIconArrow}
            ariaLabel="Arrow"
          />
        </AButton>
      </div>

      {displayImage && (
        <div className="relative c-testimonial-masthead__image">
          {!!content?.backgroundVideo?.length && (
            <video
              ref={backgroundVideoRef}
              muted
              playsInline
              autoPlay
              loop
              aria-label={content.backgroundVideo[0]?.title}
              className="absolute top-0 left-0 object-cover w-full h-full c-testimonial-masthead__image"
            />
          )}

          {!!content?.desktopImage?.length && (
            <APicture
              className="absolute top-0 left-0 object-cover w-full h-full c-testimonial-masthead__image"
              imageDesktop={content?.desktopImage && content?.desktopImage[0]}
              imageMobile={content?.mobileImage && content?.mobileImage[0]}
            />
          )}
        </div>
      )}
    </section>
  )
}

export const CTestimonialMastheadFragments = {
  field: `
    fragment testimonialMasthead on testimonialMasthead_MatrixField {
      ... on testimonialMasthead_block_BlockType {
        id
        typeHandle
        idName
        color
        heading
        copy
        desktopImage {
          url @transform(mode: "crop", width: 2000, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        mobileImage {
          url @transform(mode: "crop", width: 640, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        backgroundVideo {
          url
          title
          embeddedAsset {
            url
            title
            providerName
          }
        }
      }
    }
  `,
}
