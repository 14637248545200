import Link from "next/link";
import { useMemo } from "react";
import SvgArrowRightIcon from "ui/svg/icons/stroke/arrow-right.svg";
import * as CONST from "ui/constants/index";
import { ASvg } from "../atoms/a-svg";
import "./m-article-link.css";
import { useSiteInfo } from "shared";

type Props = {
  url: string;
  heading: string;
  subheading?: string | null;
  image?: string | null;
  imageAlt?: string | null;
  linkText?: string;
  className?: string;
};

const defaultProps = {
  subheading: null,
  image: null,
  imageAlt: null,
  linkText: "Read Story",
};

const MArticleLink = (props: Props) => {
  const siteInfo = useSiteInfo();

  const brandedPlaceholder = useMemo(() => {
    let src: string = "";
    switch (siteInfo.branded) {
      case CONST.BRANDED.VISIAN_ICL:
        src = `${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/listing-placeholder-icl.jpg`;
        break;
      case CONST.BRANDED.EVO_VISIAN_ICL:
        src = `${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/listing-placeholder-evo.jpg`;
        break;
      case CONST.BRANDED.STAAR_SURGICAL:
        src = `${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/listing-placeholder-staar.jpeg`;
        break;
      default:
        src = `${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/listing-placeholder-generic.jpg`;
    }
    return src;
  }, []);

  /* eslint-disable @next/next/no-img-element */
  return (
    <div className={`m-article-link block ${props.className}`}>
      <Link className="w-full" href={props.url} prefetch={false}>
        <div className="m-article-link__ratio relative w-full">
          <img
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={props.image ? props.image : brandedPlaceholder}
            alt={props.imageAlt ? props.imageAlt : "Article placeholder image"}
          />
        </div>
        <h3 className="mt-4 text-h7 w-full whitespace-normal text-neutral-dark-gray">
          {props.heading}
        </h3>
        {props.subheading && <h4>{props.subheading}</h4>}
        <div className="text-primary-teal flex items-center text-button uppercase font-bold mt-4 w-full">
          <span>{props.linkText}</span>
          <ASvg
            className="m-article-link__arrow stroke-current w-6 h-6"
            svg={SvgArrowRightIcon}
          />
        </div>
      </Link>
    </div>
  );
};

MArticleLink.defaultProps = defaultProps;

export { MArticleLink };
