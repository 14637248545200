/* eslint-disable @next/next/no-img-element */
import { useEffect, useMemo, useState } from 'react'
import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import { useSiteInfo } from 'shared'
import { CContactModal } from 'ui/components/organisms/c-contact-modal'
import { CFormie, CFormieFragments } from 'ui/components/organisms/c-formie'
import { CContactFormUs } from './c-contact-form-us'

export type CContactFormBlock = {
  idName?: string
  heading?: string
  subheading?: string
  description?: string
  bottomContent?: string
  imageDesktop: Array<CraftAssetField>
  formie?: any[]
  hiddenFormie?: any[]
  formieHeading?: string
  successHeading?: string
  successSubheading?: string
  successButton?: CraftLinkItField
  actions?: Array<{
    actionTitle?: string
    actionType?: 'phone' | 'chat' | 'email' | undefined
    actionValue?: string | null
  }>
}

type Props = {
  field: Array<CContactFormBlock>
}

export const CContactForm = ({ field }: Props) => {
  const siteInfo = useSiteInfo()
  const [csrfToken, setCsrfToken] = useState<string | undefined>()
  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const [showModal, setShowModal] = useState<boolean>(false)

  const getFormTokenInfo = async () => {
    fetch('/actions/users/session-info', {
      cache: 'no-cache',
      headers: { accept: 'application/json' },
    })
      .then((res) => {
        return res.json()
      })
      .then((res) => {
        if (!csrfToken) {
          setCsrfToken(res?.csrfTokenValue || '')
        }
      })
      .catch((err) => {
        console.log('Error getting form token:', err)
      })
  }

  useEffect(() => {
    if (csrfToken) {
      return
    }
    getFormTokenInfo()
  }, [content])

  if (!content) {
    return <></>
  }

  if (siteInfo.isUS) {
    return (
      <CContactFormUs
        content={content}
        setShowModal={setShowModal}
        showModal={showModal}
        csrfToken={csrfToken}
      />
    )
  }

  return (
    <section id={content.idName} className="c-contact-form">
      <div className="h-24 md:h-32" />

      <div className="mb-6 o-container o-container--2xl">
        <div className="flex w-full p-4 bg-neutral-light-gray md:pb-20">
          <div className="w-full md:px-20">
            {content.heading && (
              <h1 className="mt-6 md:mt-12 text-h6 md:text-h5 text-neutral-dark-gray">
                {content.heading}
              </h1>
            )}

            {content.subheading && (
              <h2 className="mt-6 text-p md:text-p-lg text-neutral-dark-gray">
                {content.subheading}
              </h2>
            )}

            <CFormie
              field={content.formie}
              showModal={() => setShowModal(true)}
              csrfToken={csrfToken}
            />

            <CContactModal
              open={showModal}
              onClose={() => setShowModal(false)}
              heading={content.successHeading}
              subheading={content.successSubheading}
              button={content.successButton}
            />
          </div>

          {content.imageDesktop?.length > 0 && (
            <div className="flex-shrink-0 hidden pl-4 xl:block">
              <img
                className="block"
                alt={content.imageDesktop[0].title}
                src={content.imageDesktop[0].url}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const CContactFormFragments = {
  field: `
    fragment contactForm on contactForm_MatrixField {
      ...on contactForm_block_BlockType {
        idName
        heading
        subheading
        description
        bottomContent
        imageDesktop {
          title
          url
        }
        actions {
          ... on actions_BlockType {
            actionType
            actionTitle
            actionValue
          }
        }
        formieHeading
        formie {
          ...FormieFormFragment
        }
        hiddenFormie {
          ...FormieFormFragment
        }
        successHeading
        successSubheading
        successButton {
          text
          url
        }
      }
    }
    ${CFormieFragments.field}
  `,
}
