import { useMemo } from 'react'
import { CraftGlobals } from 'tsconfig/craft-types'
import { CCtaBox, CCtaBoxBlock, CCtaBoxFragments } from '../organisms/c-cta-box'
import {
  CFullWidthTestimonial,
  CFullWidthTestimonialBlock,
  CFullWidthTestimonialFragments,
} from '../organisms/c-full-width-testimonial'
import { CCounter } from './c-counter'
import { CFindADoctor } from './c-find-a-doctor'
import { CStats } from './c-stats'

export type CCustomComponentsBlogBlock = {
  typeHandle: string
}

type Props = {
  field: Array<CCustomComponentsBlogBlock>
  global: CraftGlobals
}

export const CCustomComponentsBlog = ({ field, global }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field : []
  }, [field])

  return (
    <div>
      {content.map((block) => {
        if (block.typeHandle === 'counter') {
          return <CCounter field={global.counter} />
        } else if (block.typeHandle === 'stats') {
          return <CStats field={global.stats} />
        } else if (block.typeHandle === 'ctaBox2') {
          return <CCtaBox field={global.ctaBox} />
        } else if (block.typeHandle === 'findADoctor') {
          return <CFindADoctor field={global.findADoctor} />
        } else if (block.typeHandle === 'fullWidthTestimonial') {
          return (
            <CFullWidthTestimonial
              field={[block as CFullWidthTestimonialBlock]}
            />
          )
        } else if (block.typeHandle === 'ctaBox') {
          return <CCtaBox field={[block as CCtaBoxBlock]} />
        }

        return <></>
      })}
    </div>
  )
}

export const CCustomComponentsBlogFragments = {
  field: `
    fragment customComponentsBlog on customComponentsBlog_MatrixField {
      ... on customComponentsBlog_counter_BlockType {
        typeHandle
      }
      ...customComponentsBlog_ctaBox
      ... on customComponentsBlog_ctaBox2_BlockType {
        typeHandle
      }
      ... on customComponentsBlog_findADoctor_BlockType {
        typeHandle
      }
      ...customComponentsBlog_fullWidthTestimonial
      ... on customComponentsBlog_stats_BlockType {
        typeHandle
      }
    }
    ${CCtaBoxFragments.customComponentsBlogField}
    ${CFullWidthTestimonialFragments.customComponentsBlogField}
  `,
}
