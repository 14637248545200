import {
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import classNames from 'classnames'
import GetVideoId from 'get-video-id'
import { YouTubePlayer } from 'youtube-player/dist/types'
import { Options, Player } from 'vimeo__player'
import './a-video.css'

type Props = {
  src?: string
  controls?: boolean
  playsInline?: boolean
  className?: string
  orientation?: 'horizontal' | 'vertical'
}

export type AVideoMethods = {
  play: () => void
  pause: () => void
}

const defaultProps = {
  src: '',
}

const AVideo = forwardRef(
  (
    { src, controls, playsInline = false, className, orientation }: Props,
    ref
  ) => {
    const ytPlayer = useRef<YouTubePlayer | null>(null)
    const vimeoPlayer = useRef<Player | null>(null)

    const contentRef = useRef(null)

    const meta = useMemo(() => {
      return GetVideoId(src ?? '')
    }, [src])

    const createPlayer = async () => {
      vimeoPlayer.current?.destroy()
      ytPlayer.current?.destroy()

      if (meta.service === 'youtube') {
        const { default: YouTubePlayer } = await import('youtube-player')
        const player = YouTubePlayer(contentRef.current ?? '', {
          videoId: meta.id ?? '',
          playerVars: {
            controls: controls ? 1 : 0,
            playsinline: playsInline ? 1 : 0,
            modestbranding: 1,
          },
        })
        ytPlayer.current = player
      } else if (meta.service === 'vimeo') {
        const { default: VimeoPlayer } = await import('@vimeo/player')
        const playerOptions: Options = {
          controls,
          playsinline: playsInline,
        }

        if (isVimeoPrivateUrl(src)) {
          playerOptions.url = src
        } else {
          playerOptions.id = parseInt(meta.id ?? '0')
        }

        vimeoPlayer.current = new VimeoPlayer(
          contentRef.current ?? '',
          playerOptions
        )
      }
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          play() {
            if (meta.service === 'youtube' && ytPlayer.current) {
              ytPlayer.current.playVideo()
            } else if (meta.service === 'vimeo' && vimeoPlayer.current) {
              vimeoPlayer.current.play()
            }
          },
          pause() {
            if (meta.service === 'youtube' && ytPlayer.current) {
              ytPlayer.current.pauseVideo()
            } else if (meta.service === 'vimeo' && vimeoPlayer.current) {
              vimeoPlayer.current.pause()
            }
          },
        }
      },
      []
    )

    useEffect(() => {
      createPlayer()
    }, [meta])

    return (
      <div
        className={classNames(
          'a-video relative',
          `a-video__${orientation || 'horizontal'}`,
          className
        )}
      >
        <div
          ref={contentRef}
          className="a-video__content absolute inset-0 w-full h-full"
        />
      </div>
    )
  }
)

const isVimeoPrivateUrl = (src?: string) =>
  src && /https?:\/\/vimeo\.com\/(.*)+\/(.*)+$/.test(src)

AVideo.defaultProps = defaultProps

export { AVideo }
