import classNames from 'classnames'
import { useMemo } from 'react'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { MTestimonial } from '../molecules/m-testimonial'
import './c-small-testimonial-grid.css'

type Testimonial = {
  id?: string
  title?: string
  typeHandle?: string
  uri?: string
  testimonialOccupation?: string
  testimonialAvatar?: Array<CraftAssetField>
  testimonialQuote?: string
  testimonialImage?: Array<CraftAssetField>
  testimonialVideo?: Array<CraftEmbeddedAssetField>
}

export type CSmallTestimonialGridBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  showPlayVideoButtons?: boolean
  testimonials?: Array<Testimonial>
}

type Props = {
  field: Array<CSmallTestimonialGridBlock>
}

export const CSmallTestimonialGrid = ({ field }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const filteredTestimonials = useMemo(() => {
    if (content && content.testimonials?.length) {
      return content.testimonials.filter((testimonial) => {
        return (
          testimonial.testimonialQuote &&
          testimonial.title &&
          testimonial.testimonialOccupation &&
          testimonial.testimonialAvatar?.length &&
          testimonial.testimonialAvatar[0].url
        )
      })
    } else {
      return []
    }
  }, [content])

  const computeTheme = (i: number) => {
    if (filteredTestimonials.length % 2) {
      // odd number of testimonials
      if (i === 2) {
        return 'full-width'
      } else if ([0, 4].includes(i)) {
        return 'gray'
      } else if ([1, 3].includes(i)) {
        return 'teal'
      }
    } else if ([0, 3, 4].includes(i)) {
      // even number of testimonials (gray)
      return 'gray'
    } else if ([1, 2, 5].includes(i)) {
      // even numbe of testimonials (teal)
      return 'teal'
    }
  }

  if (!content || !filteredTestimonials.length) {
    return <></>
  }

  return (
    <section className="c-small-testimonial-grid m-3 md:m-6">
      <div className="c-small-testimonial-grid__content lg:-m-3 flex flex-wrap">
        {filteredTestimonials.map((testimonial, i) => {
          return (
            <MTestimonial
              key={testimonial.id}
              quote={testimonial.testimonialQuote ?? ''}
              name={testimonial.title ?? ''}
              occupation={testimonial.testimonialOccupation ?? ''}
              avatar={
                testimonial.testimonialAvatar?.length
                  ? testimonial.testimonialAvatar[0].url ?? ''
                  : ''
              }
              avatarAlt={
                testimonial.testimonialAvatar?.length
                  ? testimonial.testimonialAvatar[0].title ?? ''
                  : ''
              }
              video={
                testimonial.testimonialVideo?.length
                  ? testimonial.testimonialVideo[0].embeddedAsset?.url
                  : ''
              }
              showVideoButton={content?.showPlayVideoButtons}
              theme={computeTheme(i)}
              url={
                testimonial.typeHandle === 'testimonialPages'
                  ? testimonial.uri
                  : ''
              }
              mobileTheme={i % 2 ? 'gray' : 'teal'}
              className={classNames('c-small-testimonial-grid__item lg:m-3', {
                'c-small-testimonial-grid__item--full-width':
                  filteredTestimonials.length === 1 ||
                  (filteredTestimonials.length % 2 && i === 2),
              })}
            />
          )
        })}
      </div>
    </section>
  )
}

export const CSmallTestimonialGridFragments = {
  field: `
    fragment smallTestimonialGrid on smallTestimonialGrid_MatrixField {
      ... on smallTestimonialGrid_block_BlockType {
        id
        typeHandle
        idName
        showPlayVideoButtons
        testimonials {
          id
          title
          typeHandle
          uri
          ... on testimonials_testimonials_Entry {
            testimonialOccupation
            testimonialAvatar {
              url @transform(mode: "crop", width: 88, height: 88, immediately: true)
              title
            }
            testimonialQuote
            testimonialImage {
              url @transform(mode: "crop", width: 530, height: 668, immediately: true)
              title
            }
            testimonialVideo {
              url
              title
              embeddedAsset {
                url
                title
              }
            }
          }
          ... on testimonialPages_testimonialPages_Entry {
            testimonialOccupation
            testimonialAvatar {
              url @transform(mode: "crop", width: 44, height: 44, immediately: true)
              title
            }
            testimonialQuote
            testimonialImage {
              url @transform(mode: "crop", width: 530, height: 668, immediately: true)
              title
            }
            testimonialVideo {
              url
              title
              embeddedAsset {
                url
                title
              }
            }
          }
        }
      }
    }
  `,
}
