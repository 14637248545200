import classNames from 'classnames'
import { throttle } from 'lodash'
import { useEffect, useState } from 'react'
import { AButton } from '../atoms/a-button'

type Props = {
  label: string
}

export const CBackToTop = ({ label }: Props) => {
  const [active, setActive] = useState<boolean>(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleWindowScroll = () => {
    setActive(window.scrollY > 200)
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScrollThrottled)

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
    }
  })

  return (
    <AButton
      className={classNames(
        'c-back-to-top md:hidden bg-neutral-black text-neutral-white fixed top-22 left-1/2 transform -translate-x-1/2 z-40',
        {
          'opacity-0 pointer-events-none': !active,
          'opacity-100 pointer-events-auto': active,
        }
      )}
      type="button"
      style="solid"
      color="black"
      onClick={scrollToTop}
    >
      <span>{label}</span>
    </AButton>
  )
}
