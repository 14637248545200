import syncICL from 'sync/icl.json'
import syncEVO from 'sync/evo.json'
import * as CONST from 'ui/constants/index'

const getAllLocaleInfo = (layout: string) => {
  if (layout === CONST.SITE_LAYOUT.EVO) {
    return syncEVO.sites
  }

  return syncICL.sites
}

export default getAllLocaleInfo
