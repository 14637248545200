import { Settings } from '../settings-types'
import getSettings from 'shared/helpers/get-settings'
import { useSiteInfo } from 'shared'

export const useSettings = (): Settings => {
  const siteInfo = useSiteInfo()

  return getSettings(siteInfo?.layout ?? 'icl', siteInfo?.iso ?? 'en-US')
}

export default useSettings
