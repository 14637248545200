import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'

interface APopupProps {
  content: string
}
export const AInformationPopup = ({ content }: APopupProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const popupRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={popupRef} className="relative">
      <div
        className={classNames('rounded-[15px] absolute', {
          'border border-primary-teal p-[9px] bg-white w-[160px] -top-[22px] -left-[8px] z-20':
            isOpen,
          '-top-[12px]': !isOpen,
        })}
      >
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={classNames(
            'w-[14px] h-[14px] leading-none text-sm rounded-full p-[2px]',
            {
              'bg-primary-teal text-white': isOpen,
              'bg-neutral-teal': !isOpen,
            }
          )}
        >
          i
        </button>
        {isOpen && <div className="inline ml-[6px]">{content}</div>}
      </div>
    </div>
  )
}
