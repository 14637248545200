import classNames from "classnames";
import { ReactNode } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import "./m-form-search.css";
import { useSiteInfo } from "shared";

export type FormData = {
  q: string;
};

type Props = {
  icon: ReactNode;
  text: string;
  type: "email" | "text";
  errorMessage?: string;
  successMessage?: string;
  onSubmit: SubmitHandler<FormData>;
  className?: string;
};

const maxLength = {
  message: "This field exceed max length",
  value: 255,
};
const pattern = {
  message: "This field requires a valid email",
  value:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};
const required = {
  message: "This field is required",
  value: true,
};

export const MFormSearch = ({
  icon,
  text,
  type,
  errorMessage,
  successMessage,
  onSubmit,
  className,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const siteInfo = useSiteInfo();

  return (
    <div className="relative">
      <form
        className="relative m-form-search"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          {...register(
            "q",
            type === "email"
              ? { required, pattern, maxLength }
              : { required, maxLength }
          )}
          aria-label={text}
          autoComplete="off"
          className={classNames(
            `w-full transition-all duration-300 bg-transparent pie-14 m-form-search__input text-button ${className}`,
            {
              "font-semibold border border-primary-dark-teal placeholder-gray-600":
                siteInfo.isUS,
              "font-bold uppercase border-2 border-current placeholder-current":
                !siteInfo.isUS,
            }
          )}
          placeholder={text}
          type={type}
        />
        <button
          className="absolute top-0 inline-end-0 inline-flex items-center justify-center h-full w-14 m-form-search__button"
          type="submit"
        >
          {icon}
        </button>
      </form>

      {type === "email" && (errors.q || errorMessage || successMessage) && (
        <div
          className={classNames(
            "m-form-search__message text-p-sm font-bold absolute w-full",
            { "text-secondary-red": errors.q || errorMessage }
          )}
        >
          {errors.q?.message || errorMessage || successMessage}
        </div>
      )}
    </div>
  );
};
