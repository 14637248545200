import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import locales from 'sync/locales.json'
import { createLocaleUrl } from 'discovericl/helpers/locale-tools'

type Props = {
  domain: string
}

export const CLocaleSwitcherBody = ({ domain }: Props) => {
  const { t } = useTranslation('common')

  return (
    <div className="md:pb-6 max-w-5xl bg-neutral-white px-2 md:pd-0 w-full z-10">
      <h2 className="text-h7 md:text-h6 py-8 font-semibold md:font-light text-neutral-dark-gray md:text-primary-teal text-center w-full">
        {t('select-your-region')}
      </h2>
      <div className="md:flex md:justify-around c-locale-switcher__columns text-primary-gray">
        {locales.map((column, n) => {
          return (
            <div key={n} className="grid grid-cols-2">
              {column.map((region, m) => {
                return (
                  <div
                    className={classNames('mb-12 mx-5 col-span-2', {
                      'mb-12 mx-5': !region.noMargins,
                      'md:mb-12 md:mx-5': region.noMargins,
                      'col-span-2': !region.isOneColumn,
                      'md:col-span-2': region.isOneColumn,
                      'row-start-1': region.isFirstRow,
                    })}
                    key={m}
                  >
                    <h3 className="font-semibold text-p uppercase text-neutral-dark-gray">
                      {region.region}
                    </h3>
                    <div className="grid grid-cols-2 grid-flow-row-dense md:grid-cols-1">
                      {region.countries.map((country, i) => {
                        return (
                          <a
                            key={i}
                            href={createLocaleUrl(country.subdomain, domain)}
                            className={classNames(
                              'text-p block whitespace-nowrap leading-7',
                              {
                                'col-start-1 md:col-start-auto':
                                  !region.isOneColumn &&
                                  i < Math.ceil(region.countries.length / 2),
                                'col-start-2 md:col-start-auto':
                                  !region.isOneColumn &&
                                  i >= Math.ceil(region.countries.length / 2),
                                'col-span-2': region.isOneColumn,
                                'col-span-2 col-start-auto': region.hasSpan2,
                              }
                            )}
                          >
                            {country.name}
                          </a>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}
