import './a-svg.css'

type ASvgProps = {
  className?: string
  svg?: any
  ariaLabel?: string
  ariaHidden?: string
}

export const ASvg = (props: ASvgProps) => {
  const Svg = props.svg
  return (
    <span className={`a-svg ${props.className}`}>
      <Svg aria-label={props.ariaLabel} aria-hidden={props.ariaHidden} />
    </span>
  )
}
