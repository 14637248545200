declare global {
  export interface Window {
    analytics: any
  }
}

// fonts
export * from './fonts/index'

// atoms
export * from './components/atoms/a-button'
export * from './components/atoms/a-checkbox'
export * from './components/atoms/a-hyperlink'
export * from './components/atoms/a-input'
export * from './components/atoms/a-picture'
export * from './components/atoms/a-select'
export * from './components/atoms/a-svg'
export * from './components/atoms/a-textarea'
export * from './components/atoms/a-video'
export * from './components/atoms/a-lightswitch'
export * from './components/atoms/a-information-popup'

// molecules
export * from './components/molecules/m-form-search'
export * from './components/molecules/m-image-wrapper'
export * from './components/molecules/m-modal'
export * from './components/molecules/m-slider-arrows'
export * from './components/molecules/m-video-pip'
export * from './components/molecules/m-video-thumbnail'
export * from './components/molecules/m-pinned-subnav'
export * from './components/molecules/m-video-modal'
export * from './components/molecules/m-testimonial'
export * from './components/molecules/m-article-link'
export * from './components/molecules/m-pagination-dots'
export * from './components/molecules/m-legacy-burger-icl'
export * from './components/molecules/m-comparison-table'

// organisms
export * from './components/organisms/c-expanded-cta-section'
export * from './components/organisms/c-the-header'
export * from './components/organisms/c-the-footer'
export * from './components/organisms/c-masthead'
export * from './components/organisms/c-hero'
export * from './components/organisms/c-seomatic'
export * from './components/organisms/c-about-video'
export * from './components/organisms/c-background-image-copy'
export * from './components/organisms/c-centered-copy'
export * from './components/organisms/c-comparison-table'
export * from './components/organisms/c-counter'
export * from './components/organisms/c-content-with-side-image'
export * from './components/organisms/c-cta-box'
export * from './components/organisms/c-cta-block'
export * from './components/organisms/c-cta-contact'
export * from './components/organisms/c-cta-columns'
export * from './components/organisms/c-faq-slider'
export * from './components/organisms/c-find-a-doctor'
export * from './components/organisms/c-image-copy-blocks'
export * from './components/organisms/c-references-modal'
export * from './components/organisms/c-split-testimonial'
export * from './components/organisms/c-stats'
export * from './components/organisms/c-statistics-section'
export * from './components/organisms/c-steps'
export * from './components/organisms/c-testimonials-slider'
export * from './components/organisms/c-404'
export * from './components/organisms/c-500'
export * from './components/organisms/c-full-width-testimonial'
export * from './components/organisms/c-small-testimonial-grid'
export * from './components/organisms/c-image-copy-slides'
export * from './components/organisms/c-background-image-list'
export * from './components/organisms/c-partial-text-masthead'
export * from './components/organisms/c-expandable-faqs'
export * from './components/organisms/c-formie'
export * from './components/organisms/c-contact-form'
export * from './components/organisms/c-contact-modal'
export * from './components/organisms/c-minor-image-masthead'
export * from './components/organisms/c-copy-block'
export * from './components/organisms/c-wysiwyg'
export * from './components/organisms/c-testimonial-masthead'
export * from './components/organisms/c-simple-copy'
export * from './components/organisms/c-full-width-video'
export * from './components/organisms/c-story-gallery'
export * from './components/organisms/c-image-section-break'
export * from './components/organisms/c-questions-and-answers'
export * from './components/organisms/c-photo-collage'
export * from './components/organisms/c-related-articles'
export * from './components/organisms/c-locale-switcher'
export * from './components/organisms/c-locale-switcher-body'
export * from './components/organisms/c-locale-switcher-screen'
export * from './components/organisms/c-article-listing'
export * from './components/organisms/c-custom-components-blog'
export * from './components/organisms/c-custom-components-lp'
export * from './components/organisms/c-back-to-top'
export * from './components/organisms/c-article-masthead'
export * from './components/organisms/c-cta-box-legacy'
export * from './components/organisms/c-kr-newsletter'
export * from './components/organisms/c-kr-newsletter-modal'
export * from './components/organisms/c-wtw-slides'
export * from './components/organisms/c-legacy-navigation-evo'
export * from './components/organisms/c-legacy-footer-evo'
export * from './components/organisms/c-legacy-navigation-icl'
export * from './components/organisms/c-legacy-footer-icl'
export * from './components/organisms/c-legacy-safety-info'
export * from './components/organisms/c-exit-intent-modal'
export * from './components/organisms/c-procedure-steps'
export * from './components/organisms/c-preview-toast'
