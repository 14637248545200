import { CraftAssetField, CraftEmbeddedAssetField } from "tsconfig/craft-types";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "next-i18next";
import { AButton, APicture } from "ui";
import Hls from "hls.js";
import classNames from "classnames";
import { throttle } from "lodash";
import useStateRef from "discovericl/hooks/use-state-ref";
import "./c-split-testimonial.css";
import { useSiteInfo } from "shared";

export type Testimonial = {
  id?: string;
  title?: string;
  typeHandle?: string;
  uri?: string;
  testimonialOccupation?: string;
  testimonialQuote?: string;
  testimonialImage?: Array<CraftAssetField>;
  testimonialVideo?: Array<CraftEmbeddedAssetField>;
  testimonialVideoOrientation?: "horizontal" | "vertical";
  testimonialBackgroundVideo?: Array<CraftEmbeddedAssetField>;
  testimonialMobileBackgroundVideo?: Array<CraftEmbeddedAssetField>;
};

export type CSplitTestimonialBlock = {
  id?: string;
  typeHandle?: string;
  idName?: string;
  layout?: string;
  testimonial?: Array<Testimonial>;
};

type Props = {
  field: Array<CSplitTestimonialBlock>;
};

export const CSplitTestimonial = ({ field }: Props) => {
  const { t } = useTranslation("common");

  const siteInfo = useSiteInfo();
  const [animate, setAnimate, animateRef] = useStateRef([]);
  const [animateDown, setAnimateDown, animateDownRef] = useStateRef([]);

  const blockRef = useRef<Array<HTMLElement | null>>([]);
  const backgroundVideoRef = useRef<Array<HTMLVideoElement | null>>([]);

  const handleWindowScroll = () => {
    blockRef.current.forEach((element, index) => {
      const blockAnimate: boolean =
        (element
          ? element.getBoundingClientRect().top <= window.innerHeight * 0.5
          : false) &&
        (element
          ? element.getBoundingClientRect().bottom >= window.innerHeight * 0.5
          : false);
      setAnimate(
        animateRef.current.map((item: boolean, itemIndex: number) => {
          return itemIndex === index ? blockAnimate : item;
        })
      );

      const blockAnimateDown: boolean = element
        ? element.getBoundingClientRect().top <= window.innerHeight * 0.5
        : false;
      setAnimateDown(
        animateDownRef.current.map((item: boolean, itemIndex: number) => {
          return itemIndex === index ? blockAnimateDown : item;
        })
      );
    });
  };

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100);

  const content = useMemo(() => {
    return field && field.length ? field : null;
  }, [field]);

  useEffect(() => {
    blockRef.current = blockRef.current.slice(0, content?.length);
  }, [content]);

  useEffect(() => {
    if (content) {
      setAnimate(Array(content.length).fill(false));
      setAnimateDown(Array(content.length).fill(false));
      window.addEventListener("scroll", handleWindowScrollThrottled);
      handleWindowScroll();

      content.forEach((block, blockIndex) => {
        if (
          block.testimonial?.length &&
          block.testimonial[0].testimonialBackgroundVideo?.length
        ) {
          const assetUrl =
            block.testimonial[0].testimonialBackgroundVideo[0].embeddedAsset
              ?.url || "";
          const videoUrl =
            block.testimonial[0].testimonialBackgroundVideo[0].url || "";
          const videoEl = backgroundVideoRef.current[blockIndex]!;

          if (assetUrl && Hls.isSupported()) {
            const hls = new Hls();
            hls.on(Hls.Events.ERROR, console.error);
            hls.loadSource(assetUrl);
            hls.attachMedia(videoEl);
          } else if (
            assetUrl &&
            videoEl.canPlayType("application/vnd.apple.mpegurl")
          ) {
            videoEl.src = assetUrl;
          } else if (videoUrl) {
            videoEl.src = videoUrl;
          }
        }
      });
    }

    return () => {
      if (content) {
        window.removeEventListener("scroll", handleWindowScrollThrottled);
      }
    };
  }, [content]);

  const validateData = (block: CSplitTestimonialBlock) => {
    return (
      block?.testimonial?.length &&
      block.testimonial[0].testimonialImage?.length &&
      block.testimonial[0].testimonialImage[0].url &&
      block.testimonial[0].testimonialQuote &&
      block.testimonial[0].title &&
      block.testimonial[0].testimonialOccupation
    );
  };

  const validatedContent = useMemo(() => {
    return content?.filter((block) => {
      return validateData(block);
    });
  }, [field]);

  return (
    <div>
      {validatedContent?.map((block, blockIndex) => {
        return (
          <section
            id={block.idName}
            key={blockIndex}
            ref={(el) => {
              blockRef.current[blockIndex] = el;
            }}
            className={classNames(
              "flex flex-col m-3 c-split-testimonial md:m-6 lg:bg-neutral-light-gray lg:p-4 lg:flex-row",
              {
                "c-split-testimonial--animate": animate[blockIndex],
                "c-split-testimonial--animate-down": animateDown[blockIndex],
              }
            )}
          >
            <div
              className={classNames(
                "relative flex-shrink-0 c-split-testimonial__image-container",
                `c-split-testimonial__image-container-${
                  (block.testimonial?.length &&
                    block.testimonial[0].testimonialVideoOrientation) ||
                  "horizontal"
                }`,
                {
                  "lg:order-2 lg:mis-8": block.layout === "imageRight",
                  "lg:mie-8": !block.layout || block.layout === "imageLeft",
                }
              )}
            >
              {!!(
                block?.testimonial && block.testimonial[0].testimonialImage
              ) && (
                <APicture
                  className="object-cover w-full h-full"
                  imageDesktop={block.testimonial[0].testimonialImage[0]}
                />
              )}

              {block?.testimonial &&
                block.testimonial[0].testimonialBackgroundVideo &&
                block.testimonial[0].testimonialBackgroundVideo.length && (
                  <video
                    className="absolute top-0 left-0 object-cover w-full h-full"
                    ref={(el) => {
                      backgroundVideoRef.current[blockIndex] = el;
                    }}
                    autoPlay
                    muted
                    playsInline
                    loop
                  />
                )}
            </div>

            <div
              className={classNames(
                "flex items-center justify-center flex-grow px-6 py-8 mt-3 bg-neutral-light-gray lg:py-0 lg:px-0 lg:mt-0",
                { "order-1": block.layout === "imageRight" }
              )}
            >
              <div className="flex flex-col w-full font-normal c-split-testimonial__content text-p lg:text-h5 text-neutral-dark-gray sm:items-center lg:items-start">
                <div className="border-b border-black mb-3 md:mb-12 pb-3 md:pb-5 w-full">
                  <h3 className="font-normal text-h6 md:text-h5">
                    {block?.testimonial ? block?.testimonial[0].title : ""}
                  </h3>
                  <h4 className="font-semibold md:font-normal text-p md:text-p-lg">
                    {block.testimonial
                      ? block.testimonial[0].testimonialOccupation
                      : ""}
                  </h4>
                </div>

                <div className="c-split-testimonial__quote">
                  <span
                    className={`o-quote q-quote--${siteInfo.iso} o-quote--teal`}
                  >
                    {block.testimonial
                      ? block.testimonial[0].testimonialQuote
                      : ""}
                  </span>
                </div>
                {block?.testimonial &&
                  block.testimonial[0].typeHandle === "testimonialPages" && (
                    <AButton
                      type="a"
                      href={
                        block.testimonial[0].uri
                          ? encodeURI(block.testimonial[0].uri)
                          : ""
                      }
                      className="mt-8 c-split-testimonial__button"
                      style="outline"
                      color="gray"
                      size="lg"
                    >
                      <span>{t("read-the-full-story")}</span>
                    </AButton>
                  )}
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

const entryFragment = `
  testimonialOccupation
  testimonialQuote
  testimonialImage {
    url @transform(mode: "crop", width: 530, height: 668, immediately: true)
    title
    hasFocalPoint
    focalPoint
  }
  testimonialVideo {
    url
    title
    embeddedAsset {
      url
      title
    }
  }
  testimonialVideoOrientation
  testimonialBackgroundVideo {
    id
    url
    title
    embeddedAsset {
      title
      url
      providerName
    }
  }
  testimonialMobileBackgroundVideo {
    id
    url
    title
    embeddedAsset {
      title
      url
      providerName
    }
  }
`;

const blockFragment = `
  id
  typeHandle
  idName
  layout
  testimonial {
    id
    title
    uri
    typeHandle
    ... on testimonials_testimonials_Entry {
      ${entryFragment}
    }
    ... on testimonialPages_testimonialPages_Entry {
      ${entryFragment}
    }
  }
`;

export const CSplitTestimonialFragments = {
  field: `
    fragment splitTestimonial on splitTestimonial_MatrixField {
      ... on splitTestimonial_block_BlockType {
        ${blockFragment}
      }
    }
  `,
  field2: `
    fragment splitTestimonial2 on splitTestimonial2_MatrixField {
      ... on splitTestimonial2_block_BlockType {
        ${blockFragment}
      }
    }
  `,
};
