import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import './c-legacy-safety-info.css'

type Props = {
  components: any
}

export const CLegacySafetyInfo = ({ components }: Props) => {
  const { t } = useTranslation('common')

  const entry = useMemo(() => {
    if (components?.footer?.safety?.length) {
      return components?.footer.safety[0]
    } else {
      return {
        heading: t('safety-info-heading') ?? '',
        body: t('safety-info-body') ?? '',
        disclaimer: t('disclaimer') ?? '',
      }
    }
  }, [components])

  return (
    <div className="p-8">
      <div
        className="mt-3 text-xs font-light text-gray-800 c-legacy-safety-info__html-formatting"
        dangerouslySetInnerHTML={{ __html: entry.disclaimer }}
      />
      <h2
        className="text-2xl font-light text-center text-gray-800"
        dangerouslySetInnerHTML={{ __html: entry.heading }}
      />
      <div
        className="mt-3 text-xs font-light text-gray-800 c-legacy-safety-info__html-formatting"
        dangerouslySetInnerHTML={{ __html: entry.body }}
      />
    </div>
  )
}
