import { useEffect } from 'react'
import { MModal, CLocaleSwitcherBody } from 'ui'

type Props = {
  open: boolean
  onClose: Function
  domain: string
}

export const CLocaleSwitcher = ({ open, onClose, domain }: Props) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      closeModal()
    }
  }

  const closeModal = () => {
    if (onClose instanceof Function) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className="c-locale-switcher">
      <MModal open={open} onClose={closeModal}>
        <CLocaleSwitcherBody domain={domain} />
      </MModal>
    </div>
  )
}
