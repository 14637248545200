import { CraftLinkItField } from 'tsconfig/craft-types'
import { useIntercom } from "react-use-intercom"
import './c-cta-contact.css'
import SvgChatIcon from '../../svg/icons/stroke/chat.svg'
import SvgPhoneIcon from '../../svg/icons/stroke/phone.svg'
import { AButton } from '../atoms/a-button'

export interface CCtaContactBlock {
  heading?: string
  subHeading?: string
  ctas?: Array<{
    cta?: CraftLinkItField
  }>
}

interface CCtaContactProps {
  field: Array<CCtaContactBlock>
}
export const CCtaContact = ({ field }: CCtaContactProps) => {
  const { heading, subHeading, ctas } = field?.[0]
  const { show } = useIntercom()

  return (
    <div className="c-cta-contact text-center text-white">
      <div className="o-container o-container--xl">
        {heading && (
          <h2 className="text-4xl md:text-heading mb-[40px] font-semibold">
            {heading}
          </h2>
        )}
        {subHeading && (
          <h3 className="text-2xl max-w-[600px] mx-auto">{subHeading}</h3>
        )}
        {Boolean(ctas?.length) && (
          <div className="mt-[35px] w-full flex gap-[28px] justify-center flex-col md:flex-row max-w-[320px] mx-auto md:max-w-[unset]">
            {ctas?.map((cta, i) => {
              const isChat = Boolean(
                cta.cta?.type === 'custom' && cta.cta?.url === '#'
              )
              const isTel = Boolean(cta.cta?.type === 'tel')
              return (
                <AButton
                  key={`${cta.cta?.url}-${i}`}
                  className="bg-white md:min-w-[300px] font-bold"
                  size="xl"
                  type={isChat ? 'button' : cta.cta?.type}
                  href={!isChat ? cta.cta?.url : undefined}
                  style="outline"
                  color="teal"
                  icon={isChat ? SvgChatIcon : isTel ? SvgPhoneIcon : undefined}
                  iconClass="stroke-current w-[23px] h-[23px]"
                  iconAlign="left"
                  onClick={isChat ? show : undefined}
                >
                  <span>{cta.cta?.text}</span>
                </AButton>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const CCtaContactFragment = `
  fragment ctaContact on ctaContact_MatrixField {
    ... on ctaContact_block_BlockType {
      heading
      subHeading
      ctas {
        ... on ctas_BlockType {
          cta {
            type
            url
            text
            target
          }
        }
      }
    }
  }
`
