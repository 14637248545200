import { useMemo } from 'react'
import { CraftAssetField } from 'tsconfig/craft-types'
import { APicture } from 'ui'
import './c-minor-image-masthead.css'

export type CMinorImageMastheadBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  subheading?: string
  imageDesktop?: Array<CraftAssetField>
  imageMobile?: Array<CraftAssetField>
}

type Props = {
  field: Array<CMinorImageMastheadBlock>
}

export const CMinorImageMasthead = ({ field }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  return (
    <section className="c-minor-image-masthead">
      <div className="c-minor-image-masthead__spacer" />
      <div className="relative">
        <APicture
          className="block w-full object-cover"
          imageDesktop={content?.imageDesktop && content.imageDesktop[0]}
          imageMobile={content?.imageMobile && content.imageMobile[0]}
        />
        {!content?.imageDesktop && <div className="bg-primary-teal h-64" />}

        <div className="absolute inset-0 bg-neutral-black bg-opacity-50 flex items-center">
          <div className="o-container o-container--xl text-center">
            <h1 className="lg:font-light text-h5 text-neutral-white">
              {content?.heading}
            </h1>
            {content?.subheading && (
              <div className="font-semibold mt-4 text-p text-neutral-white">
                {content?.subheading}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export const CMinorImageMastheadFragments = {
  field: `
    fragment minorImageMasthead on minorImageMasthead_block_BlockType {
        id
        typeHandle
        idName
        heading
        subheading
        imageDesktop {
          title
          url
          hasFocalPoint
          focalPoint
        }
        imageMobile {
          title
          url
          hasFocalPoint
          focalPoint
        }
      }
    `,
}
