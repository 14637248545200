import { throttle } from 'lodash'
import Link from 'next/link'
import { ReactNode, useMemo, useRef, useEffect, useState } from 'react'
import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import { AButton, APicture } from 'ui'
import { normalizeHref } from 'discovericl/helpers/normalize-href'
import './c-cta-box.css'

export type CCtaBoxBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  backgroundImage?: Array<CraftAssetField>
  backgroundImageMobile?: Array<CraftAssetField>
  theme?: string
  label?: string
  heading?: string
  buttonText?: string
  hyperlink?: CraftLinkItField
}

type Props = {
  field: Array<CCtaBoxBlock>
}

type ConditionalLinkProps = {
  className: string
  children: ReactNode
  hyperlink?: CraftLinkItField
}

const ConditionalLink = ({
  className,
  children,
  hyperlink,
}: ConditionalLinkProps) => {
  if (hyperlink?.url) {
    const href =
      hyperlink.type === 'entry' ? normalizeHref(hyperlink.url) : hyperlink.url

    return (
      <a className={className} href={href}>
        {children}
      </a>
    )
  } else {
    return <div className={className}>{children}</div>
  }
}

export const CCtaBox = ({ field }: Props) => {
  const ctaRef = useRef<HTMLDivElement>(null)

  const [animate, setAnimate] = useState(false)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const handleWindowScroll = () => {
    const rect = ctaRef.current?.getBoundingClientRect()
    if (rect && rect.top < window.innerHeight - rect.height / 3) {
      setAnimate(true)
    } else {
      setAnimate(false)
    }
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    if (content) {
      window.addEventListener('scroll', handleWindowScrollThrottled)
      handleWindowScroll()
    }

    return () => {
      if (content) {
        window.removeEventListener('scroll', handleWindowScrollThrottled)
      }
    }
  }, [])

  if (!content) {
    return <></>
  }

  return (
    <div
      ref={ctaRef}
      className="c-cta-box font-body overflow-hidden bg-neutral-black"
    >
      <ConditionalLink
        className="c-cta-box__link relative flex items-center justify-center"
        hyperlink={content.hyperlink}
      >
        <APicture
          className={`
            absolute top-0 left-0 w-full h-full object-cover block transition-all transform duration-300 ease-out
            ${animate ? 'opacity-100 scale-100' : 'opacity-50 scale-120'}
          `}
          imageDesktop={content.backgroundImage && content.backgroundImage[0]}
          imageMobile={
            content.backgroundImageMobile && content.backgroundImageMobile[0]
          }
        />
        <div className="absolute top-0 left-0 w-full h-full">
          <div
            className={`
              absolute bg-neutral-white transform transition-transform duration-300 ease-out top-0 left-0 w-full h-3 md:h-6 origin-top scale-y-50
              ${animate ? 'scale-y-100' : ''}
            `}
          />
          <div
            className={`
              absolute bg-neutral-white transform transition-transform duration-300 ease-out bottom-0 left-0 w-full h-3 md:h-6 origin-bottom scale-y-50
              ${animate ? 'scale-y-100' : ''}
            `}
          />
          <div
            className={`
              absolute bg-neutral-white transform transition-transform duration-300 ease-out top-0 left-0 w-3 md:w-6 h-full origin-left scale-x-50
              ${animate ? 'scale-x-100' : ''}
            `}
          />
          <div
            className={`
              absolute bg-neutral-white transform transition-transform duration-300 ease-out top-0 right-0 w-3 md:w-6 h-full origin-right scale-x-50
              ${animate ? 'scale-x-100' : ''}
            `}
          />
        </div>
        <div
          className={`
            c-cta-box__content flex flex-col items-center z-10 transition-all duration-300 transform ease-out mx-5
            ${
              animate
                ? 'opacity-100 translate-y-0 delay-300'
                : 'opacity-0 translate-y-5'
            }
            ${
              content.theme === 'dark-gray'
                ? 'text-neutral-dark-gray'
                : 'text-neutral-white'
            }
          `}
        >
          {content.label && (
            <h3 className="text-button font-bold uppercase text-center">
              {content.label}
            </h3>
          )}
          {content.heading && (
            <h2 className="text-h6 md:text-h4 font-light text-center">
              {content.heading}
            </h2>
          )}
          {content.buttonText && (
            <AButton
              className="mt-4"
              style="outline"
              color={content.theme !== 'dark-gray' ? 'white' : 'gray'}
            >
              <span>{content.buttonText}</span>
            </AButton>
          )}
        </div>
      </ConditionalLink>
    </div>
  )
}

export const CCtaBoxFragments = {
  field: `
    fragment ctaBox on ctaBox_MatrixField {
      ... on ctaBox_block_BlockType {
        id
        typeHandle
        idName
        backgroundImage {
          url @transform(mode: "crop", width: 2000, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        backgroundImageMobile {
          url @transform(mode: "crop", width: 640, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        theme
        label
        heading
        buttonText
        hyperlink {
          type
          url
          text
          target
        }
      }
    }
  `,
  customComponentsBlogField: `
    fragment customComponentsBlog_ctaBox on customComponentsBlog_MatrixField {
      ... on customComponentsBlog_ctaBox_BlockType {
        id
        typeHandle
        idName
        backgroundImage {
          url @transform(mode: "crop", width: 2000, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        backgroundImageMobile {
          url @transform(mode: "crop", width: 640, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        theme
        label
        heading
        buttonText
        hyperlink {
          type
          url
          text
          target
        }
      }
    }
  `,
}
