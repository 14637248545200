import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { MouseEvent, useMemo } from "react";
import { useSiteInfo } from "shared";

type Props = {
  components: any;
};

export const CLegacyFooterEvo = ({ components }: Props) => {
  const siteInfo = useSiteInfo();
  const { t } = useTranslation("common");

  const copyLinks = useMemo(() => {
    return components.footer?.links?.map((v: any) => ({
      ...v,
      inMenu: components.footer?.menu?.some((m: any) => m.url === v.url),
    }));
  }, [components]);

  const handleShowRegionChange = (e: MouseEvent) => {
    e.preventDefault();

    const event = new CustomEvent("showEvoRegionModal");
    window.dispatchEvent(event);
  };

  return (
    <div className="lg:h-[242px] bg-gray-900 font-body">
      <div className="px-6 pt-10 pb-10 lg:pb-0 flex flex-col">
        <div className="flex w-full justify-between items-center order-2 lg:order-1">
          <div className="hidden lg:flex">
            {components.footer?.menu?.map((item: any, n: number) => {
              return (
                <a
                  key={n}
                  className="tracking-[0.7px] font-bold text-xs text-pink-500 uppercase mr-10 last:mr-0 hover:text-pink-400 text-center transition-colors duration-100 ease-out"
                  href={
                    item.url?.includes("http")
                      ? item.url
                      : `${siteInfo?.discoverUrl}${item.url?.replace(
                          /^\//,
                          ""
                        )}`
                  }
                  target="item.target ? item.target : ''"
                >
                  {item.text}
                </a>
              );
            })}
          </div>
        </div>
        <div className="flex justify-between items-center lg:mt-12 order-1 lg:order-2">
          <div className="font-semibold text-xs text-gray-400 uppercase hidden lg:block">
            {components.footer?.copy as string}
            {copyLinks?.map((link: any, n: number) => {
              return (
                <a
                  key={n}
                  href={
                    link.url?.includes("http")
                      ? link.url
                      : `${siteInfo?.discoverUrl}${link.url?.replace(
                          /^\//,
                          ""
                        )}`
                  }
                  className={classNames("ml-2 hover:text-pink-100", {
                    "md:hidden": link.inMenu,
                  })}
                >
                  {link.text}
                </a>
              );
            })}
            <a
              className="ml-2 hover:text-pink-100"
              href="#"
              onClick={handleShowRegionChange}
            >
              {t("international-sites")}
            </a>
          </div>
        </div>
        <div className="font-semibold text-xs text-gray-400 uppercase order-3 text-center w-full mt-4 lg:hidden">
          {components.footer?.copy as string}
          {copyLinks?.map((link: any, n: number) => {
            return (
              <a
                key={n}
                href={
                  link.url?.includes("http")
                    ? link.url
                    : `${siteInfo?.discoverUrl}${link.url?.replace(/^\//, "")}`
                }
                className={classNames("ml-2 hover:text-pink-100", {
                  "md:hidden": link.inMenu,
                })}
              >
                {link.text}
              </a>
            );
          })}
          <a
            className="ml-2 hover:text-pink-100"
            href="#"
            onClick={handleShowRegionChange}
          >
            {t("international-sites")}
          </a>
        </div>
      </div>
    </div>
  );
};
