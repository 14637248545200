import classNames from 'classnames'
import { ChangeEvent, TextareaHTMLAttributes, useState } from 'react'
import './a-textarea.css'

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  invalid?: boolean
  onChangeHandler?: (value: string) => void
}

export const ATextarea = ({
  className,
  disabled,
  invalid,
  name,
  placeholder,
  required,
  rows,
  value,
  onChangeHandler,
}: Props) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onBlur = () => {
    setDirty(true)
  }

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDirty(true)
    onChangeHandler && onChangeHandler(event.target.value)
  }

  return (
    <textarea
      className={classNames('a-textarea', { dirty, invalid }, className)}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      required={required}
      rows={rows}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  )
}
