import classNames from 'classnames'
import { useState } from 'react'

export type ALightSwitchOption = {
  label: string
  value: string
}

type Props = {
  options: Array<ALightSwitchOption>
  onChange?: (option: ALightSwitchOption) => void
  className?: string
}

export const ALightSwitch = ({ options, onChange, className }: Props) => {
  const [value, setValue] = useState<string>(
    options.length ? options[0].value : ''
  )

  const handleOptionClick = (option: ALightSwitchOption) => {
    setValue(option.value)

    if (onChange instanceof Function) {
      onChange(option)
    }
  }

  return (
    <div className={`border border-gray-200 p-2 rounded-full ${className}`}>
      {options.map((option: ALightSwitchOption) => {
        return (
          <button
            key={option.value}
            className={classNames(
              'font-bold font-sm uppercase py-2 px-4 rounded-full',
              {
                'text-neutral-white bg-gray-900': value === option.value,
              }
            )}
            type="button"
            title={option.label}
            onClick={() => handleOptionClick(option)}
          >
            {option.label}
          </button>
        )
      })}
    </div>
  )
}
