import { CraftLinkItField } from 'tsconfig/craft-types'
import { AButton } from '../atoms/a-button'

export interface CCtaBlockBlock {
  heading?: string
  button?: CraftLinkItField
}

interface CCtaBlockProps {
  field: CCtaBlockBlock[]
}

export const CCtaBlock = ({ field }: CCtaBlockProps) => {
  const { heading, button } = field?.[0] ?? []
  return (
    <div className="px-5 py-5 c-cta-block flex justify-center items-center flex-col md:flex-row gap-[18px] md:gap-[36px] w-full">
      {heading && (
        <h4 className="text-xl sm:text-2xl text-primary-dark-teal font-semibold">
          {heading}
        </h4>
      )}
      {button?.url && (
        <AButton
          className="w-[85%] md:w-[300px]"
          type={button.type === 'url' ? 'a' : 'link'}
          href={button?.url}
          rel={button.type === 'url' ? 'noreferrer' : ''}
          target={button.target}
          size="lg"
        >
          <span>{button.text}</span>
        </AButton>
      )}
    </div>
  )
}

export const CCtaBlockFragment = `
  fragment ctaBlock on ctaBlock_MatrixField {
    ... on ctaBlock_block_BlockType {
      heading
      button {
        type
        url
        text
        target
      }
    }
  }
`
