import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { ASvg } from 'ui'
import { useTranslation } from 'next-i18next'
import SvgIconClose from 'ui/svg/icons/stroke/close.svg'

export const CKrNewsletterModal = () => {
  const modalRef = useRef<HTMLElement>(null)
  const { t } = useTranslation('common')

  const [isOpen, setIsOpen] = useState(false)

  const hide = () => {
    setIsOpen(false)
    clearAllBodyScrollLocks()
  }

  useEffect(() => {
    document.addEventListener('showKrReferencesModal', () => {
      setIsOpen(true)
    })

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        hide()
      }
    })
  }, [])

  const handleBodyClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (isOpen && modalRef.current) {
      disableBodyScroll(modalRef.current)
    }
  }, [isOpen])

  return (
    <section
      ref={modalRef}
      className={classNames(
        'transition-opacity duration-300 c-references-modal dupa',
        [isOpen ? '' : 'opacity-0 pointer-events-none']
      )}
    >
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-75 bg-neutral-black"
        onClick={() => hide()}
      >
        <div
          className="max-w-full md:max-w-4xl max-h-screen px-6 overflow-hidden bg-neutral-white md:px-28 py-14 md:py-28 flex flex-col"
          onClick={handleBodyClick}
        >
          <div className="relative">
            <h4 className="mb-6 md:mb-16 text-neutral-black text-h6 md:text-h2 md:text-center">
              {t('kr-newsletter.modal-heading')}
            </h4>
            <button
              className="absolute top-0 mt-2 inline-end-0 md:mt-6"
              onClick={() => hide()}
            >
              <ASvg
                className="stroke-current text-primary-teal w-8 h-8"
                svg={SvgIconClose}
              />
            </button>
          </div>
          <div className="text-primary-gray text-center text-p-sm md:text-p h-full">
            <p>{t('kr-newsletter.modal-content-1')}</p>
            <p>{t('kr-newsletter.modal-content-2')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
