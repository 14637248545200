import Glide from "@glidejs/glide";
import classNames from "classnames";
import { throttle } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { CraftAssetField } from "tsconfig/craft-types";
import { APicture, ASvg } from "ui";
import SvgArrowLeftIcon from "ui/svg/icons/stroke/arrow-left.svg";
import SvgArrowRightIcon from "ui/svg/icons/stroke/arrow-right.svg";
import "./c-story-gallery.css";
import { useSiteInfo } from "shared";

export type CStoryGalleryBlock = {
  id?: string;
  typeHandle?: string;
  idName?: string;
  heading?: string;
  copy?: string;
  image?: Array<CraftAssetField>;
  mobileImage?: Array<CraftAssetField>;
};

type Props = {
  field: Array<CStoryGalleryBlock>;
};

export const CStoryGallery = ({ field }: Props) => {
  const siteInfo = useSiteInfo();

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [copyContainerHeight, setCopyContainerHeight] = useState<number>(0);
  const [animate, setAnimate] = useState<boolean>(false);
  const [animateDown, setAnimateDown] = useState<boolean>(false);

  const sectionRef = useRef<HTMLDivElement>(null);
  const copiesRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const glideRef = useRef<Glide | null>(null);

  const calcCopyContainerHeight = () => {
    if (copiesRef.current) {
      setCopyContainerHeight(
        copiesRef.current.children[activeSlide].scrollHeight
      );
    }
  };

  const goToSlide = (i: number) => {
    if (glideRef.current) {
      setActiveSlide(i);
      glideRef.current.go(`=${i}`);
      calcCopyContainerHeight();
    }
  };

  const prevSlide = () => {
    if (glideRef.current && activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
      glideRef.current.go(`=${activeSlide - 1}`);
      calcCopyContainerHeight();
    }
  };

  const nextSlide = () => {
    if (glideRef.current && activeSlide < slides.length - 1) {
      setActiveSlide(activeSlide + 1);
      glideRef.current.go(`=${activeSlide + 1}`);
      calcCopyContainerHeight();
    }
  };

  const swipeEvents = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
  });

  const handleWindowScroll = () => {
    if (sectionRef.current) {
      setAnimate(
        sectionRef.current.getBoundingClientRect().top <=
          window.innerHeight * 0.5 &&
          sectionRef.current.getBoundingClientRect().bottom >=
            window.innerHeight * 0.5
      );
      setAnimateDown(
        sectionRef.current.getBoundingClientRect().top <=
          window.innerHeight * 0.5
      );
    }
  };

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScrollThrottled);

    return () => {
      window.removeEventListener("scroll", handleWindowScrollThrottled);
    };
  }, []);

  const main = useMemo(() => {
    if (field && field.length) {
      const filter = field.filter((item) => {
        return item.typeHandle === "main";
      });

      if (filter.length) {
        return filter[0];
      } else {
        return {
          idName: null,
          heading: null,
        };
      }
    } else {
      return {
        idName: null,
        heading: null,
      };
    }
  }, [field]);

  const slides = useMemo(() => {
    if (field && field.length) {
      return field.filter((item) => {
        return item.typeHandle === "slide";
      });
    } else {
      return [];
    }
  }, [field]);

  useEffect(() => {
    if (slides.length && sliderRef.current) {
      calcCopyContainerHeight();
      const slider = new Glide(sliderRef.current ?? "", {
        direction: siteInfo.dir as "ltr" | "rtl" | undefined,
        gap: 0,
        rewind: false,
      });

      slider.mount();
      slider.on("run", () => {
        setActiveSlide(slider.index);
      });

      glideRef.current = slider;
    }

    return () => {
      if (slides.length && glideRef?.current) {
        glideRef.current.destroy();
      }
    };
  }, [slides]);

  if (!main || !slides.length) {
    return <></>;
  }

  return (
    <section
      ref={sectionRef}
      className={classNames(
        "c-story-gallery m-3 md:m-6 flex flex-col lg:flex-row",
        {
          "c-story-gallery--animate": animate,
          "c-story-gallery--animate-down": animateDown,
        }
      )}
    >
      <div
        className="c-story-gallery__content bg-primary-teal lg:mie-3 p-6 lg:pt-24 lg:pis-18 lg:pie-23 lg:pb-17 flex flex-col justify-between order-2 lg:order-1"
        {...swipeEvents}
      >
        <h2
          className="c-story-gallery__heading text-neutral-white text-h6 lg:text-h5 whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: main.heading ?? "" }}
        />
        <div className="c-story-gallery__copies-container">
          <div
            ref={copiesRef}
            className="c-story-gallery__copies mt-4 lg:mt-0 relative overflow-hidden lg:overflow-visible"
            style={{
              height: `${copyContainerHeight}px`,
            }}
          >
            {slides.map((slide, i) => {
              return (
                <div
                  key={slide.id}
                  className={classNames(
                    "c-story-gallery__copy text-neutral-white absolute w-full left-0 top-0 lg:top-auto lg:bottom-0",
                    {
                      "c-story-gallery__copy--active": activeSlide === i,
                    }
                  )}
                >
                  {slide.heading && (
                    <h3 className="text-h7 font-bold lg:font-normal lg:text-h6">
                      {slide.heading}
                    </h3>
                  )}
                  {slide.copy && (
                    <div className="text-p font-semibold mt-2">
                      {slide.copy}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {slides.length > 1 && (
            <div className="c-story-gallery__controls mt-12 flex h-7">
              <button className="w-7 h-7" onClick={prevSlide}>
                <ASvg
                  className="c-story-gallery__controls-arrow-icon stroke-current text-neutral-white w-7 h-7"
                  svg={SvgArrowLeftIcon}
                />
              </button>
              {Array.from(Array(slides.length)).map((item, i) => {
                return (
                  <button
                    key={i}
                    className="w-7 h-7 flex items-center justify-center"
                    onClick={() => goToSlide(i)}
                  >
                    <div
                      className={classNames(
                        "w-2 h-2 rounded-full bg-neutral-white transition-opacity duration-200 ease-out",
                        {
                          "opacity-40": activeSlide !== i,
                        }
                      )}
                    />
                  </button>
                );
              })}
              <button className="w-7 h-7" onClick={nextSlide}>
                <ASvg
                  className="c-story-gallery__controls-arrow-icon stroke-current text-neutral-white w-7 h-7"
                  svg={SvgArrowRightIcon}
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        ref={sliderRef}
        className="c-story-gallery__slider lg:mis-3 order-1 lg:order-2 relative bg-primary-teal"
      >
        <div
          className="c-story-gallery__slides glide__track w-full h-full"
          data-glide-el="track"
        >
          <div className="glide__slides h-full">
            {slides.map((slide) => {
              return (
                <div key={slide.id} className="w-full h-full">
                  <APicture
                    className="glide__slide object-cover"
                    imageDesktop={slide.image && slide.image[0]}
                    imageMobile={slide.mobileImage && slide.mobileImage[0]}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export const CStoryGalleryFragments = {
  field: `
    fragment storyGallery on storyGallery_MatrixField {
      ... on storyGallery_main_BlockType {
        id
        typeHandle
        idName
        heading
      }
      ... on storyGallery_slide_BlockType {
        id
        typeHandle
        heading
        copy
        image {
          url @transform(mode: "crop", width: 1000, height: 1000, immediately: true)
          title
          hasFocalPoint
          focalPoint
        },
        mobileImage: image {
          url @transform(mode: "crop", width: 640, height: 640, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
      }
    }
  `,
};
