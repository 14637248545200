import { CComparisonTableBlock } from '../organisms/c-comparison-table'
import { ASvg } from '../atoms/a-svg'
import SvgCloseIcon from '../../svg/icons/stroke/close.svg'
import SvgCheckIcon from '../../svg/icons/fill/check-thin.svg'
import classNames from 'classnames'
import './m-comparison-table.css'
import { Fragment } from 'react'
import { AInformationPopup } from 'ui'

interface MComparisonTableProps {
  firstHeader: CComparisonTableBlock | undefined
  content: CComparisonTableBlock[]
  mainBlock: any
}

export const MComparisonTable = ({
  firstHeader,
  content,
  mainBlock,
}: MComparisonTableProps) => {
  return (
    <div className="max-w-[800px] mx-auto text-gray-850">
      {firstHeader?.tableHeader && (
        <div className="grid grid-cols-[128px_1fr_1fr_1fr] sm:grid-cols-[2.5fr_1fr_1fr_1fr] w-full c-comparison-table__row__us text-neutral-dark-gray bg-gray-50 border border-gray-200 rounded-t-[10px] py-[10px] px-[12px] md:px-[24px]">
          <div className="empty" />
          {!mainBlock?.disableColumns?.includes('1') && (
            <div className="c-comparison-table__row-accent text-center text-lg md:text-2xl font-semibold text-primary-teal">
              <h4>{firstHeader.tableHeader[0].columnHeading1}</h4>
            </div>
          )}
          {!mainBlock?.disableColumns?.includes('2') && (
            <div className="text-center text-sm sm:text-md md:text-xl font-semibold text-primary-dark-teal">
              <h4>{firstHeader.tableHeader[0].columnHeading2}</h4>
            </div>
          )}
          {!mainBlock?.disableColumns?.includes('3') && (
            <div className="text-center text-sm sm:text-md md:text-xl font-semibold text-primary-dark-teal">
              <h4>{firstHeader.tableHeader[0].columnHeading3}</h4>
            </div>
          )}
        </div>
      )}
      {content.map((row, index) => {
        const isFooterNext = Boolean(
          content[index + 1] && content[index + 1].typeHandle === 'tableFooter'
        )
        return (
          <Fragment key={`second${index}`}>
            {row.typeHandle === 'tableHeader' && row.tableHeader && (
              <div
                className={classNames(
                  'grid grid-cols-[128px_1fr_1fr_1fr] sm:grid-cols-[2.5fr_1fr_1fr_1fr] w-full c-comparison-table__row__us pt-[11px] px-[12px] md:px-[24px] border border-b-0 mt-[4px]',
                  {
                    'border-t-0 mt-[0] pt-[9px]': index === 1,
                  }
                )}
              >
                <h3 className="w-full text-sm sm:text-lg md:text-xl font-semibold">
                  {row.tableHeader[0].mainHeading}
                </h3>
                {!mainBlock?.disableColumns?.includes('1') && <div />}
                {!mainBlock?.disableColumns?.includes('2') && <div />}
                {!mainBlock?.disableColumns?.includes('3') && <div />}
              </div>
            )}
            {row.typeHandle === 'tableBody' && row.tableBody && (
              <div
                className={classNames(
                  'grid grid-cols-[128px_1fr_1fr_1fr] sm:grid-cols-[2.5fr_1fr_1fr_1fr] items-center w-full c-comparison-table__row__us px-[12px] md:px-[24px] border border-y-0',
                  {
                    'border-b border-neutral-gray sm:border-b-0':
                      content[index + 1] &&
                      content[index + 1].typeHandle !== 'tableBody',
                    'sm:border-b-[1px] sm:border-neutral-gray':
                      content[index + 1] &&
                      content[index + 1].typeHandle !== 'tableBody' &&
                      content[index + 1].typeHandle !== 'tableFooter',
                    'rounded-b-[10px] border-b border-neutral-gray':
                      index === content.length - 1,
                  }
                )}
              >
                <p className="w-full c-comparison-table__row__us-heading text-xs sm:text-sm md:text-lg pr-[4px]">
                  {row.tableBody[0].rowHeading}
                  {isFooterNext && (
                    <>
                      <div className="md:hidden inline-block ml-[10px]">
                        <AInformationPopup
                          content={
                            content[index + 1].tableFooter?.[0]?.mainNote ?? ''
                          }
                        />
                      </div>
                      <p className="hidden md:block px-[16px] w-full text-[11px] text-center mt-1 text-gray-850">
                        {content[index + 1].tableFooter?.[0]?.mainNote}
                      </p>
                    </>
                  )}
                </p>
                {!mainBlock?.disableColumns?.includes('1') && (
                  <div className="c-comparison-table__row__us-svg-cont">
                    {!row.tableBody[0].data1 && (
                      <ASvg
                        aria-hidden="true"
                        className="w-8 h-8 md:w-10 md:h-10 stroke-current text-gray-200"
                        svg={SvgCloseIcon}
                      />
                    )}
                    {!!row.tableBody[0].data1 && (
                      <ASvg
                        aria-hidden="true"
                        className="w-8 h-8 md:w-10 md:h-10 fill-current text-primary-teal"
                        svg={SvgCheckIcon}
                      />
                    )}
                    {isFooterNext &&
                      content[index + 1].tableFooter?.[0]?.columnNote1 && (
                        <>
                          <p className="px-[16px] w-full text-[11px] text-center">
                            {content[index + 1].tableFooter?.[0]?.mainNote}
                          </p>
                        </>
                      )}
                  </div>
                )}
                {!mainBlock?.disableColumns?.includes('2') && (
                  <div className="c-comparison-table__row__us-svg-cont">
                    {!row.tableBody[0].data2 && (
                      <ASvg
                        aria-hidden="true"
                        className="w-8 h-8 md:w-10 md:h-10 stroke-current text-gray-200"
                        svg={SvgCloseIcon}
                      />
                    )}
                    {!!row.tableBody[0].data2 && (
                      <ASvg
                        aria-hidden="true"
                        className="w-8 h-8 md:w-10 md:h-10 fill-current text-primary-teal"
                        svg={SvgCheckIcon}
                      />
                    )}
                    {isFooterNext &&
                      content[index + 1].tableFooter?.[0]?.columnNote2 && (
                        <>
                          <p className="px-[16px] w-full text-[11px] text-center">
                            {content[index + 1].tableFooter?.[0]?.mainNote}
                          </p>
                        </>
                      )}
                  </div>
                )}
                {!mainBlock?.disableColumns?.includes('3') && (
                  <div className="c-comparison-table__row__us-svg-cont">
                    {!row.tableBody[0].data3 && (
                      <ASvg
                        aria-hidden="true"
                        className="w-8 h-8 md:w-10 md:h-10 stroke-current text-gray-200"
                        svg={SvgCloseIcon}
                      />
                    )}
                    {!!row.tableBody[0].data3 && (
                      <ASvg
                        aria-hidden="true"
                        className="w-8 h-8 md:w-10 md:h-10 fill-current text-primary-teal"
                        svg={SvgCheckIcon}
                      />
                    )}
                    {isFooterNext &&
                      content[index + 1].tableFooter?.[0]?.columnNote3 && (
                        <>
                          <p className="px-[16px] w-full text-[11px] text-center">
                            {content[index + 1].tableFooter?.[0]?.mainNote}
                          </p>
                        </>
                      )}
                  </div>
                )}
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
