interface Stats {
  heading?: string
  subHeading?: string
}

export interface CStatisticsSectionBlock {
  heading?: string
  stats?: Stats[]
}

interface CStatisticsSectionProps {
  field: Array<CStatisticsSectionBlock>
}
export const CStatisticsSection = ({ field }: CStatisticsSectionProps) => {
  const { heading, stats } = field?.[0] ?? {}

  return (
    <div className="o-container o-container--xl px-10 md:px-20 flex gap-[45px] lg:gap-[75px] justify-center flex-col md:flex-row my-[40px] md:my-[100px] py-[55px] md:py-0 bg-neutral-teal md:bg-white text-center md:text-left">
      {heading && (
        <h3 className="text-heading md:text-heading font-semibold text-black md:max-w-[450px] px-[10px] md:px-0">
          {heading}
        </h3>
      )}
      {Boolean(stats?.length) && (
        <div className="flex gap-[45px] lg:gap-[55px] flex-col md:flex-row">
          {stats?.map((stat, i) => {
            return (
              <h3 key={i}>
                {stat.heading && (
                  <span className="block uppercase text-5xl md:text-4xl font-semibold text-secondary-orange-text">
                    {stat.heading}
                  </span>
                )}
                {stat.subHeading && (
                  <span className="block uppercase text-2xl md:text-xl font-semibold text-primary-dark-teal">
                    {stat.subHeading}
                  </span>
                )}
              </h3>
            )
          })}
        </div>
      )}
    </div>
  )
}

export const CStatisticsSectionFragment = `
  fragment statisticsSection on statisticsSection_MatrixField {
    ... on statisticsSection_block_BlockType {
      heading
      stats {
        ... on stats_BlockType {
          heading
          subHeading
        }
      }
    }
  }
`
