import classNames from 'classnames'
import { ChangeEvent, InputHTMLAttributes } from 'react'
import css from 'styled-jsx/css'
import * as CONST from 'ui/constants/index'
import { useSiteInfo } from 'shared'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  version?: 'half' | 'full' | 'standalone' | 'rounded'
  onChangeHandler?: (value: string) => void
}

export const AFormInput = ({
  name,
  placeholder,
  required,
  type,
  value,
  version,
  onChangeHandler,
  min,
  max,
}: Props) => {
  const siteInfo = useSiteInfo()

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeHandler && onChangeHandler(event.target.value)
  }


  return (
    <>
      <div
        className={classNames('a-form-input border w-full flex', {
          version,
          'mx-3 mt-6': version !== 'standalone',
          'border-gray-500': !required,
          'border-pink-500':
            required && siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
          'border-primary-teal':
            required && siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
          'bg-gray-100 rounded-lg': siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
          'bg-white': siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
          'rounded-full border-gray-800': version === 'rounded',
        })}
      >
        <input
          aria-label={placeholder}
          className={classNames(
            'font-body font-semibold leading-4 text-gray-800 text-base w-full p-4 bg-transparent flex-grow outline-none',
            {
              'placeholder:text-gray-800': siteInfo.isUS,
            }
          )}
          name={name}
          placeholder={`${required ? '* ' : ''}${placeholder}`}
          required={required}
          type={type}
          value={value}
          onChange={onChange}
   
        />
      </div>
      <style jsx>{styles}</style>
    </>
  )
}

const styles = css`
  a-form-input.half {
    width: calc(100% - 1.5rem);
  }

  @media (min-width: 640px) {
    a-form-input.half {
      width: calc(50% - 1.5rem);
    }
  }
`
