import { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { ASvg } from 'ui'
import SvgArrowLeft from 'ui/svg/icons/stroke/arrow-left.svg'
import './m-pinned-subnav.css'

type Props = {
  list: any
}

type ListItem = {
  isActive?: boolean
  button: {
    url?: string
    text?: string
  }
  label: string
}

export const MPinnedSubnav = ({ list }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const componentRef = useRef<HTMLDivElement>(null)

  const content = useMemo(() => {
    if (list && list.length) {
      return {
        list,
        activeItem: list.find((item: ListItem) => item.isActive),
      }
    } else {
      return null
    }
  }, [list])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const close = () => {
    setIsOpen(false)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      close()
    }
  }

  const handleFocusOut = (event: FocusEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.relatedTarget as Node)
    ) {
      close()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('focusout', handleFocusOut)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('focusout', handleFocusOut)
    }
  }, [])

  return (
    <nav
      className="sticky bottom-0 left-0 z-30 w-full m-pinned-subnav"
      aria-label="Pinned Subnav"
      ref={componentRef}
    >
      <div className="relative">
        <button
          className="m-pinned-subnav__toggle flex items-center w-full px-5 py-3 text-start bg-neutral-light-gray lg:hidden"
          onClick={toggle}
        >
          {content?.activeItem && (
            <div className="mie-2">
              {content.activeItem.label && (
                <span className="text-neutral-dark-gray mr-2">
                  {content.activeItem.label}
                </span>
              )}
              {content.activeItem.button.text && (
                <span className="text-primary-teal">
                  {content.activeItem.button.text}
                </span>
              )}
            </div>
          )}
          <ASvg
            className="m-pinned-subnav__toggle-icon flex-shrink-0 mis-auto stroke-current text-neutral-dark-gray w-8 h-8"
            svg={SvgArrowLeft}
            ariaHidden="true"
          />
        </button>
        <ul
          className={classNames(
            'absolute bottom-0 left-0 right-0 overflow-auto transition-all duration-300 bg-neutral-light-gray lg:bg-neutral-white lg:border-t border-neutral-gray lg:relative m-pinned-subnav__list lg:opacity-100 lg:visible lg:flex lg:justify-center lg:text-center lg:space-i-12 lg:py-6',
            {
              'opacity-0 invisible': !isOpen,
            }
          )}
          onClick={close}
        >
          {content?.list.map((item: ListItem, index: number) => {
            return (
              <li
                key={index}
                className={classNames(
                  'm-pinned-subnav__item border-neutral-gray lg:border-0',
                  {
                    'border-t': index,
                  }
                )}
              >
                <a
                  className={classNames(
                    'm-pinned-subnav__button flex items-center justify-between w-full px-5 py-3 transition-colors duration-300 lg:bg-transparent lg:p-0 text-neutral-dark-gray lg:hover:text-neutral-black',
                    {
                      'bg-primary-bright-teal': item.isActive,
                    }
                  )}
                  href={item.button.url}
                >
                  <div>
                    {item.label && (
                      <span className="lg:hidden mr-2">{item.label}</span>
                    )}
                    {item.button.text && (
                      <span
                        className={classNames({
                          'text-neutral-white lg:text-primary-teal':
                            item.isActive,
                          'text-primary-teal lg:text-current': !item.isActive,
                        })}
                      >
                        {item.button.text}
                      </span>
                    )}
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  )
}
