import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import SvgArrowLeftIcon from 'ui/svg/icons/stroke/arrow-left.svg'
import SvgArrowRightIcon from 'ui/svg/icons/stroke/arrow-right.svg'
import SvgArrowLeftFillIcon from 'ui/svg/icons/fill/arrow-left-fill.svg'
import SvgArrowRightFillIcon from 'ui/svg/icons/fill/arrow-right-fill.svg'
import { ASvg } from '../atoms/a-svg'
import { throttle } from 'lodash'
import './m-pagination-dots.css'

type Props = {
  className?: string
  totalPages: number
  currentPage: number
  theme?: string
  onChangePage?: (index: number) => void
  dotsOnly?: boolean
  showDots?:boolean
}

const defaultProps = {
  theme: null,
}

const MPaginationDots = ({
  totalPages,
  currentPage,
  theme,
  onChangePage,
  className,
  dotsOnly,
  showDots = true,
}: Props) => {
  const [isMobile, setIsMobile] = useState(false)
  const mobileBreakpoint = 1024

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < mobileBreakpoint)
  }

  const handleWindowResizeThrottled = throttle(handleWindowResize, 100)

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResizeThrottled)

    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, [])
  const { t } = useTranslation('common')

  const changePage = (index: number) => {
    if (index < 1 || index > (totalPages ?? 0)) {
      return
    }
    if (onChangePage instanceof Function) {
      onChangePage(index)
    }
  }

  return (
    <>
      {totalPages && totalPages > 1 && (
        <nav
          className={classNames(
            className,
            'flex items-center m-pagination-dots relative',
            {
              'm-pagination-dots--theme_secondary-orange':
                theme === 'secondary-orange',
            }
          )}
          aria-label={t('pagination-dots') ?? ''}
        >
          {!dotsOnly && (
            <button
              className="m-pagination-dots__arrow arrow-left"
              onClick={() => changePage(currentPage - 1)}
            >
              <ASvg
                className={classNames(
                  className,
                  'm-pagination-dots__arrow-icon stroke-current w-8 h-8',
                  {
                    'm-pagination-arrow-circle':
                      !showDots,
                  }
                )}
                svg={!showDots ? SvgArrowLeftFillIcon : SvgArrowLeftIcon}
                ariaLabel={t('arrow-left') ?? ''}
              />
            </button>
          )}
          {(showDots || isMobile) && (
            <div className="flex m-pagination-dots__dots">
              {Array.from(Array(totalPages)).map((item, index) => {
                return (
                  <button
                    key={index + 1}
                    className={classNames(
                      'mx-2 duration-300 rounded-full m-pagination-dots__dot transition-color',
                      {
                        'is-active': currentPage === index + 1,
                        'w-[14px] h-[14px] ': dotsOnly,
                        'w-2 h-2 ': !dotsOnly,
                        'dotsBelow': !showDots,
                      }
                    )}
                    aria-label={`Dot ${index + 1}`}
                    onClick={() => changePage(index + 1)}
                  />
                )
              })}
            </div>
          )}
          
          {!dotsOnly && (
            <button
              className="m-pagination-dots__arrow arrow-right"
              onClick={() => changePage(currentPage + 1)}
            >
              <ASvg
                className={classNames(
                  className,
                  'relative m-pagination-dots__arrow-icon stroke-current w-8 h-8',
                  {
                    'm-pagination-arrow-circle':
                      !showDots,
                  }
                )}
                svg={!showDots ? SvgArrowRightFillIcon : SvgArrowRightIcon}
                ariaLabel={t('arrow-right') ?? ''}
              />
            </button>
          )}
        </nav>
      )}
    </>
  )
}

MPaginationDots.defaultProps = defaultProps

export { MPaginationDots }
