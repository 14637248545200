import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { MouseEventHandler, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useSiteInfo } from 'shared'
import { ASvg } from 'ui/components/atoms/a-svg'
import SvgVisianIclLogo from 'ui/svg/logos/visian-icl/teal-black.svg'
import SvgStaarSurgicalLogo from 'ui/svg/logos/staar-surgical/teal-black.svg'
import SvgEvoVisianIclLogo from 'ui/svg/logos/evo-visian-icl/evo-icl-linear-black.svg'
import SvgCloseIcon from 'ui/svg/icons/stroke/close.svg'
import SvgArrowLeftIcon from 'ui/svg/icons/stroke/arrow-left.svg'
import SvgArrowRightIcon from 'ui/svg/icons/stroke/arrow-right.svg'
import SvgFacebookIcon from 'ui/svg/icons/social/Facebook.svg'
import SvgInstagramIcon from 'ui/svg/icons/social/Instagram.svg'
import SvgYoutubeIcon from 'ui/svg/icons/social/Youtube.svg'
import SvgVimeoIcon from 'ui/svg/icons/social/Vimeo.svg'
import './c-the-menu.css'
import * as CONST from 'ui/constants/index'

type Props = {
  isOpen?: boolean
  brand?: string
  iclBaseUrl?: string
  doctorsUrl?: string
  quizUrl?: string
  discoveryUrl?: string
  menuRef?: any
  onClose?: Function
  onOpenLocaleSwitcher?: Function
  fullDomain?: string
}

const defaultProps = {
  isOpen: false,
  brand: '',
  iclBaseUrl: '',
  doctorsUrl: '',
  quizUrl: '',
  fullDomain: '',
}

type CustomLinkProps = {
  className?: string
  href?: string
  onClick?: MouseEventHandler
  children?: any
  target?: string
}

const CustomLink = ({
  className,
  href,
  onClick,
  children,
  target,
}: CustomLinkProps) => {
  if (/^http/.test(href ?? '')) {
    return (
      <a className={className} href={href} onClick={onClick} target={target}>
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={className}
        href={href ?? ''}
        onClick={onClick}
        prefetch={false}
        target={target}
      >
        {children}
      </Link>
    )
  }
}

const CTheMenu = (props: Props) => {
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null)

  const closeMenu = () => {
    if (props.onClose instanceof Function) {
      props.onClose()
    }
    setTimeout(() => {
      setActiveSubmenu(null)
    }, 400)
  }

  const openSubmenu = (menu: string) => {
    setActiveSubmenu(menu)
  }

  const closeSubmenu = () => {
    setActiveSubmenu(null)
  }

  const handleQuizButtonClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'quizIntention',
        event_category: 'Quiz Intention',
        event_action: 'Header component',
        event_label: window.location.href,
      },
    })
    closeMenu()
  }

  const handleDoctorsButtonClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'searchIntention',
        event_category: 'Doctor or Location Intention',
        event_action: 'Header component',
        event_label: window.location.href,
      },
    })
    closeMenu()
  }

  const handleLocaleButtonClick = () => {
    if (props.onOpenLocaleSwitcher instanceof Function) {
      props.onOpenLocaleSwitcher()
    }
    closeMenu()
  }

  return (
    <nav
      ref={props.menuRef}
      className={classNames(
        'fixed top-0 z-50 flex flex-col w-full h-full overflow-auto transition-all duration-500 inset-inline-0 c-the-menu bg-neutral-dark-gray text-neutral-white',
        {
          'invisible opacity-0': !props.isOpen,
        }
      )}
      aria-label={`${t('menu')} ${t('navigation')}`}
    >
      <div className="c-the-menu__main sticky top-0 z-50 flex items-center justify-between flex-shrink-0 w-full px-5 bg-neutral-dark-gray">
        <CustomLink
          className="inline-block"
          href={`${props.fullDomain}/`}
          onClick={closeMenu}
        >
          {props.brand === CONST.BRANDED.VISIAN_ICL && (
            <ASvg
              className="fill-current c-the-menu__logo-graphic"
              svg={SvgVisianIclLogo}
              aria-label={`Visian ICL ${t('logo')}`}
            />
          )}
          {props.brand === CONST.BRANDED.STAAR_SURGICAL && (
            <ASvg
              className="fill-current c-the-menu__logo-graphic"
              svg={SvgStaarSurgicalLogo}
              aria-label={`Staar Surgical ${t('logo')}`}
            />
          )}
          {props.brand === CONST.BRANDED.EVO_VISIAN_ICL && (
            <ASvg
              className="fill-current c-the-menu__logo-graphic"
              svg={SvgEvoVisianIclLogo}
              aria-label={`EVO Visian ${t('logo')}`}
            />
          )}
        </CustomLink>
        <button
          className="rounded-full mis-8 border-2 p-[6px] border-neutral-white text-neutral-white hover:bg-neutral-white hover:text-neutral-dark-gray"
          onClick={closeMenu}
        >
          <ASvg
            className="stroke-current w-6 h-6"
            svg={SvgCloseIcon}
            aria-label={t('close')}
          />
        </button>
      </div>
      <div className="c-the-menu__content flex flex-col flex-grow">
        <ul className="relative mt-4 overflow-hidden c-the-menu__list">
          <ul
            className={classNames('c-the-menu__sub-list', {
              'c-the-menu__sub-list--active': activeSubmenu === 'benefits',
            })}
          >
            <li className="c-the-menu__item border-t border-neutral-primary-gray">
              <button
                className="flex items-center justify-center w-full p-6 font-semibold uppercase text-button text-neutral-light-gray"
                onClick={closeSubmenu}
              >
                <ASvg
                  className="h-6 text-white stroke-current c-the-menu__arrow-icon"
                  svg={SvgArrowLeftIcon}
                />
                <span>{t('back')}</span>
              </button>
            </li>
            {t('paths.nightvision') && !siteInfo.isIN && (
              <li className="border-t border-neutral-primary-gray c-the-menu__item">
                <CustomLink
                  className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                  href={`${props.discoveryUrl}${t('paths.nightvision')}`}
                  onClick={closeMenu}
                >
                  <span>{t('titles.night-vision')}</span>
                </CustomLink>
              </li>
            )}
            {t('paths.dry-eye') && (
              <li className="border-t border-neutral-primary-gray c-the-menu__item">
                <CustomLink
                  className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                  href={`${props.discoveryUrl}${t('paths.dry-eye')}`}
                  onClick={closeMenu}
                >
                  <span>{t('titles.no-dry-eye')}</span>
                </CustomLink>
              </li>
            )}
            {t('paths.harmony') && (
              <li className="border-t border-b border-neutral-primary-gray c-the-menu__item">
                <CustomLink
                  className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                  href={`${props.discoveryUrl}${t('paths.harmony')}`}
                  onClick={closeMenu}
                >
                  <span>{t('titles.harmony-w-natural-eye')}</span>
                </CustomLink>
              </li>
            )}
          </ul>
          {props.quizUrl && !siteInfo.isES && (
            <li className="border-t border-neutral-primary-gray c-the-menu__item">
              <CustomLink
                className="block p-6 font-semibold text-center uppercase bg-white text-button text-neutral-dark-gray"
                href={props.quizUrl}
                target={props.quizUrl ? '_blank' : '_self'}
                onClick={handleQuizButtonClick}
              >
                <span>{t('titles.take-our-quiz')}</span>
              </CustomLink>
            </li>
          )}
          {props.doctorsUrl && (
            <li className="c-the-menu__item">
              <CustomLink
                className="block p-6 font-bold text-center uppercase text-button text-neutral-white bg-primary-teal"
                href={props.doctorsUrl ?? ''}
                target={props.doctorsUrl !== '/' ? '_blank' : '_self'}
                onClick={handleDoctorsButtonClick}
              >
                <span>{t('titles.find-a-doctor')}</span>
              </CustomLink>
            </li>
          )}
          {!siteInfo.isES && (
            <>
              {t('paths.lens') && (
                <li className="border-t border-neutral-primary-gray c-the-menu__item">
                  <CustomLink
                    className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                    href={`${props.discoveryUrl}${t('paths.lens')}`}
                    onClick={closeMenu}
                  >
                    <span>{t('titles.the-lens')}</span>
                  </CustomLink>
                </li>
              )}
              {(t('paths.nightvision') ||
                t('paths.dry-eye') ||
                t('paths.harmony')) && (
                <li className="border-t border-neutral-primary-gray c-the-menu__item">
                  <button
                    className="flex items-center justify-center w-full p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                    onClick={() => openSubmenu('benefits')}
                  >
                    <span>{t('benefits')}</span>
                    <ASvg
                      className="h-6 text-white stroke-current c-the-menu__arrow-icon"
                      svg={SvgArrowRightIcon}
                    />
                  </button>
                </li>
              )}
              {t('paths.procedure') && (
                <li className="border-t border-neutral-primary-gray c-the-menu__item">
                  <CustomLink
                    className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                    href={`${props.discoveryUrl}${t('paths.procedure')}`}
                    onClick={closeMenu}
                  >
                    <span>{t('titles.procedure')}</span>
                  </CustomLink>
                </li>
              )}
              {t('paths.about-visian') && (
                <li className="border-t border-neutral-primary-gray c-the-menu__item">
                  <CustomLink
                    className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                    href={`${props.discoveryUrl}${t('paths.about-visian')}`}
                    onClick={closeMenu}
                  >
                    <span>{t('titles.about-visian')}</span>
                  </CustomLink>
                </li>
              )}
              {t('paths.why-visian') && (
                <li className="border-t border-neutral-primary-gray c-the-menu__item">
                  <CustomLink
                    className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                    href={`${props.discoveryUrl}${t('paths.why-visian')}`}
                    onClick={closeMenu}
                  >
                    <span>{t('titles.why-visian')}</span>
                  </CustomLink>
                </li>
              )}
              {t('paths.life-with-visian') && !siteInfo.isIN && (
                <li className="border-t border-neutral-primary-gray c-the-menu__item">
                  <CustomLink
                    className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                    href={`${props.discoveryUrl}${t('paths.life-with-visian')}`}
                    onClick={closeMenu}
                  >
                    <span>{t('titles.life-with-visian')}</span>
                  </CustomLink>
                </li>
              )}
              {t('paths.faq') && !siteInfo.isIN && (
                <li className="border-t border-neutral-primary-gray c-the-menu__item">
                  <CustomLink
                    className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                    href={`${props.discoveryUrl}${t('paths.faq')}`}
                    onClick={closeMenu}
                  >
                    <span>{t('titles.faq')}</span>
                  </CustomLink>
                </li>
              )}
            </>
          )}
          {t('paths.blog') && (
            <li className="border-t border-neutral-primary-gray c-the-menu__item">
              <CustomLink
                className="block p-6 font-semibold text-center uppercase text-button text-neutral-light-gray"
                href={`${props.discoveryUrl}${t('paths.blog')}`}
                onClick={closeMenu}
              >
                <span>{t('titles.visian-icl-blog')}</span>
              </CustomLink>
            </li>
          )}
        </ul>
        <ul className="c-the-menu__list c-the-menu__list--secondary">
          {t('paths.contact') && (
            <li className="c-the-menu__item">
              <CustomLink
                className="block p-4 font-bold text-center uppercase text-button text-neutral-white"
                href={`${props.discoveryUrl}${t('paths.contact')}`}
                onClick={closeMenu}
              >
                <span>{t('titles.contact')}</span>
              </CustomLink>
            </li>
          )}
          {t('paths.safety-information') && !siteInfo.isES && (
            <li className="c-the-menu__item">
              <CustomLink
                className="block p-4 font-bold text-center uppercase text-button text-neutral-white"
                href={`${props.discoveryUrl}${t('paths.safety-information')}`}
                onClick={closeMenu}
              >
                <span>{t('titles.safety-information')}</span>
              </CustomLink>
            </li>
          )}
          <li className="c-the-menu__item">
            <button
              className="block p-4 font-bold text-center uppercase cursor-pointer text-button text-neutral-white w-full"
              onClick={handleLocaleButtonClick}
            >
              <span>{t('international-sites')}</span>
            </button>
          </li>
        </ul>
        {!siteInfo.isES && (
          <ul className="flex items-center justify-center px-4 pt-4 pb-8 space-i-12 c-menu__socials-list">
            {t('urls.facebook') && (
              <li>
                <a
                  className="block text-primary-gray"
                  href={t('urls.facebook') ?? ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgFacebookIcon}
                    aria-label={`Facebook ${t('icon')}`}
                  />
                </a>
              </li>
            )}
            {t('urls.instagram') && (
              <li>
                <a
                  className="block text-primary-gray"
                  href={t('urls.instagram') ?? ''}
                  target="_blank"
                  onClick={closeMenu}
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgInstagramIcon}
                    aria-label={`Instagram ${t('icon')}`}
                  />
                </a>
              </li>
            )}
            {t('urls.youtube') && (
              <li>
                <a
                  className="block text-primary-gray"
                  href={t('urls.youtube') ?? ''}
                  target="_blank"
                  onClick={closeMenu}
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgYoutubeIcon}
                    aria-label={`Youtube ${t('icon')}`}
                  />
                </a>
              </li>
            )}
            {t('urls.vimeo') && (
              <li>
                <a
                  className="block text-primary-gray"
                  href={t('urls.vimeo') ?? ''}
                  target="_blank"
                  onClick={closeMenu}
                  rel="noreferrer"
                >
                  <ASvg
                    className="w-6 h-6 fill-current"
                    svg={SvgVimeoIcon}
                    aria-label={`Vimeo ${t('icon')}`}
                  />
                </a>
              </li>
            )}
          </ul>
        )}
      </div>
    </nav>
  )
}

CTheMenu.defaultProps = defaultProps

export {CTheMenu}
