import classNames from 'classnames'
import Link from 'next/link'
import { MouseEvent, MouseEventHandler, RefObject } from 'react'
import TagManager from 'react-gtm-module'
import './a-button.css'
import { useSiteInfo } from 'shared'
import { ASvg } from './a-svg'

type AButtonProps = {
  type?: string
  children?: JSX.Element
  href?: string
  className?: string
  target?: string
  rel?: string
  icon?: JSX.Element
  iconClass?: string
  iconAlign?: string // right, left
  size?: string // md, lg, xl
  style?: string // solid, outline
  color?: string // teal, white, gray, black, pink
  title?: string
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  disabled?: boolean
  isProcedure?: boolean
  ref?: RefObject<HTMLButtonElement>
}

const defaultProps = {
  type: 'button',
  size: 'md',
  style: 'solid',
  color: 'teal',
  iconAlign: 'right',
}

type ComponentProps = {
  className?: string
  onClick?: (e: MouseEvent) => void
  ref?: RefObject<HTMLButtonElement>
}

const AButton = (props: AButtonProps) => {
  const { doctorUrl, quizUrl, isUS } = useSiteInfo()

  const handleClick = (
    e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    if (
      props.href &&
      (props.href.includes(doctorUrl || '') ||
        props.href.includes('doctors.discovericl.com'))
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'searchIntention',
          event_category: 'Doctor or Location Intention',
          event_action: 'AButton',
          event_label: window.location.href,
        },
      })
    } else if (
      props.href &&
      (props.href.includes(quizUrl || '') ||
        props.href.includes('quiz.discovericl.com'))
    ) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'quizIntention',
          event_category: 'Quiz Intention',
          event_action: 'AButton',
          event_label: window.location.href,
        },
      })
    }

    props.onClick && props.onClick(e)
  }

  const Icon = () => {
    if (props.icon) {
      return (
        <ASvg
          className={`${props.iconClass} ${
            props.iconAlign === 'left'
              ? 'order-1 mr-2'
              : 'ml-2 w-[18px] h-[18px] md:w-[24px] md:h-[24px]'
          }`}
          svg={props.icon}
        />
      )
    }

    return <></>
  }

  const Component = (componentProps: ComponentProps): JSX.Element => {
    if (props.type === 'submit') {
      return (
        <button className={componentProps.className} type="submit">
          {props.children}
        </button>
      )
    } else if (props.type === 'button') {
      return (
        <button
          className={componentProps.className}
          onClick={handleClick}
          title={props.title}
        >
          <span className={`${props.iconAlign === 'left' ? 'order-2' : ''}`}>
            {props.children}
          </span>
          <Icon />
        </button>
      )
    } else if (
      props.type === 'a' ||
      props.type === 'tel' ||
      props.type === 'url'
    ) {
      return (
        <a
          className={componentProps.className}
          href={props.href}
          target={props.target}
          rel={props.rel}
          onClick={handleClick}
        >
          <span className={`${props.iconAlign === 'left' ? 'order-2' : ''}`}>
            {props.children}
          </span>
          <Icon />
        </a>
      )
    } else if (props.type === 'link' || props.type === 'custom') {
      let url = ''
      try {
        const urlObject = new URL(props.href ?? '')
        url = urlObject.pathname
      } catch {
        url = props.href ?? '#'
      }

      return (
        <Link
          className={componentProps.className}
          href={url}
          target={props.target}
          rel={props.target}
          onClick={handleClick}
          prefetch={false}
        >
          <span className={`${props.iconAlign === 'left' ? 'order-2' : ''}`}>
            {props.children}
          </span>
          <Icon />
        </Link>
      )
    }

    return <></>
  }

  if (props.isProcedure) {
    return (
      <Component
        className={classNames(
          `a-button a-button--size-${props.size}`,
          'inline-flex items-center font-semibold justify-center text-center uppercase transition-all duration-300 align-middle border-2',
          props.className
        )}
      />
    )
  }

  return (
    <Component
      ref={props.ref}
      className={classNames(
        `a-button a-button--size-${props.size}`,
        'inline-flex items-center justify-center font-semibold text-center uppercase transition-all duration-300 align-middle border-2',
        props.className,
        {
          'text-button':
            (isUS && props.size !== 'lg' && props.size !== 'xl') || !isUS,
          'text-lg': isUS && props.size === 'lg',
          'text-xl': isUS && props.size === 'xl',
          'border-transparent bg-primary-teal text-neutral-white hover:bg-primary-bright-teal':
            props.style === 'solid' && props.color === 'teal',
          'border-transparent bg-neutral-white text-neutral-dark-gray hover:bg-primary-teal hover:text-neutral-white':
            props.style === 'solid' && props.color === 'white' && !isUS,
          'border-transparent bg-neutral-white text-primary-dark-teal hover:bg-primary-teal hover:text-neutral-white':
            props.style === 'solid' && props.color === 'white' && isUS,
          'border-transparent bg-neutral-black text-neutral-white':
            props.style === 'solid' && props.color === 'black',
          'border-transparent bg-pink-500 text-neutral-white hover:bg-pink-600':
            props.style === 'solid' && props.color === 'pink',
          'border-transparent bg-neutral-teal text-primary-dark-teal hover:bg-primary-teal hover:text-neutral-white':
            props.style === 'solid' && props.color === 'neutral-teal',
          'border-transparent bg-neutral-white text-neutral-dark-gray hover:bg-transparent hover:text-neutral-white hover:border-neutral-white':
            props.style === 'solidToOutline' && props.color === 'white',
          'border-neutral-dark-gray text-neutral-dark-gray hover:bg-neutral-dark-gray hover:text-neutral-white':
            props.style === 'outline' && props.color === 'gray',
          'border-neutral-white text-neutral-white hover:bg-neutral-white hover:text-neutral-dark-gray':
            props.style === 'outline' && props.color === 'white',
          'border-primary-teal text-primary-teal hover:bg-primary-teal hover:text-neutral-white':
            props.style === 'outline' && props.color === 'teal',
          'border-neutral-black text-neutral-black hover:bg-neutral-black hover:text-neutral-white':
            props.style === 'outline' && props.color === 'black',
          'a-button--has-icon': props.icon,
          'opacity-50 pointer-events-none': props.disabled,
        }
      )}
    />
  )
}

AButton.defaultProps = defaultProps

export { AButton }
