import { throttle } from 'lodash'
import { useMemo, useRef, useEffect } from 'react'
import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import useStateRef from 'discovericl/hooks/use-state-ref'
import { AButton, APicture } from 'ui'
import './c-image-copy-blocks.css'

export type CImageCopyBlocksBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  theme?: string
  imageAlignInline?: string
  image?: Array<CraftAssetField>
  video?: Array<CraftAssetField>
  heading?: string
  copy?: string
  button?: CraftLinkItField
}

type Props = {
  field: Array<CImageCopyBlocksBlock>
}

export const CImageCopyBlocks = ({ field }: Props) => {
  const [blocksActive, setBlocksActive, blocksActiveRef] = useStateRef([])
  const [blocksActiveDown, setBlocksActiveDown, blocksActiveDownRef] =
    useStateRef([])

  const blockRef = useRef<Array<HTMLElement | null>>([])

  const content = useMemo(() => {
    return field && field.length ? field : []
  }, [field])

  const handleWindowScroll = () => {
    blockRef.current.forEach((element, index) => {
      const blockIsActive: boolean =
        (element
          ? element.getBoundingClientRect().top <= window.innerHeight * 0.5
          : false) &&
        (element
          ? element.getBoundingClientRect().bottom >= window.innerHeight * 0.5
          : false)

      setBlocksActive(
        blocksActiveRef.current.map((item: boolean, itemIndex: number) => {
          return itemIndex === index ? blockIsActive : item
        })
      )

      blocksActiveDownRef[index] = blockIsActive
      setBlocksActiveDown(
        blocksActiveDownRef.current.map((item: boolean, itemIndex: number) => {
          return itemIndex === index ? blockIsActive : item
        })
      )
    })
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    blockRef.current = blockRef.current.slice(0, content.length)
  }, [content])

  useEffect(() => {
    if (content) {
      setBlocksActive(Array(content.length).fill(false))
      setBlocksActiveDown(Array(content.length).fill(false))
      window.addEventListener('scroll', handleWindowScrollThrottled)
      handleWindowScroll()
    }

    return () => {
      if (content) {
        window.removeEventListener('scroll', handleWindowScrollThrottled)
      }
    }
  }, [])

  return (
    <div className="c-image-copy-blocks">
      {content?.map((block, blockIndex) => {
        return (
          <section
            id={block.idName}
            key={block.id}
            ref={(el) => {
              blockRef.current[blockIndex] = el
            }}
            className={`
              m-3 md:m-6 lg:mx-3 c-image-copy-blocks__block lg:flex
              ${
                block.theme === 'dark'
                  ? 'bg-neutral-dark-gray text-neutral-white'
                  : ''
              }
              ${
                block.theme === 'light'
                  ? 'bg-neutral-white text-neutral-dark-gray'
                  : ''
              }
              ${block.imageAlignInline === 'end' ? 'lg:flex-row-reverse' : ''}
              ${blocksActive[blockIndex] ? 'is-active' : ''}
              ${blocksActiveDown[blockIndex] ? 'is-active-down' : ''}
            `}
          >
            <div
              className={`
                relative w-full c-image-copy-blocks__tile c-image-copy-blocks__tile--media lg:w-1/2 lg:mx-3
                ${
                  block.theme === 'white'
                    ? 'bg-neutral-white text-neutral-dark-gray'
                    : ''
                }
                ${
                  block.theme === 'brightTeal'
                    ? 'bg-primary-bright-teal text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'teal'
                    ? 'bg-primary-teal text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'orange'
                    ? 'bg-secondary-orange text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'red'
                    ? 'bg-secondary-red text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'darkGray'
                    ? 'bg-neutral-dark-gray text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'lightGray'
                    ? 'bg-neutral-light-gray text-neutral-dark-gray'
                    : ''
                }
              `}
            >
              {!!block?.video?.length && (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={block.video[0].url}
                  aria-label={block.video[0].title}
                  className="absolute top-0 left-0 object-cover w-full h-full c-image-copy-blocks__background-video"
                />
              )}
              {Boolean(!block?.video?.length && block?.image?.length) && (
                <APicture
                  className="absolute top-0 left-0 object-cover w-full h-full c-image-copy-blocks__background-image"
                  imageDesktop={block.image?.[0]}
                />
              )}
            </div>
            <div
              className={`
                relative flex flex-col items-center justify-center w-full px-6 mt-3 c-image-copy-blocks__tile--body md:mt-6 lg:mt-0 lg:w-1/2 lg:mx-3 py-14
                ${
                  block.theme === 'white'
                    ? 'bg-neutral-white text-neutral-dark-gray'
                    : ''
                }
                ${
                  block.theme === 'brightTeal'
                    ? 'bg-primary-bright-teal text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'teal'
                    ? 'bg-primary-teal text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'orange'
                    ? 'bg-secondary-orange text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'red'
                    ? 'bg-secondary-red text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'darkGray'
                    ? 'bg-neutral-dark-gray text-neutral-white'
                    : ''
                }
                ${
                  block.theme === 'lightGray'
                    ? 'bg-neutral-light-gray text-neutral-dark-gray'
                    : ''
                }
              `}
            >
              <div
                className={`
                  w-full transition-opacity duration-500 c-image-copy-blocks__body
                  ${!blocksActive[blockIndex] ? 'opacity-0' : ''}
                `}
              >
                {block.heading && (
                  <h2
                    className="font-normal text-h6 whitespace-pre-line"
                    dangerouslySetInnerHTML={{ __html: block.heading }}
                  />
                )}
                {block.copy && (
                  <div
                    className="mt-4 font-semibold text-p s-wysiwyg"
                    dangerouslySetInnerHTML={{ __html: block.copy }}
                  />
                )}
                {block.button?.url && (
                  <div
                    className={`
                      mt-8 transition-transform duration-500 delay-200 transform"
                      ${!blocksActive[blockIndex] ? 'translate-y-8' : ''}
                    `}
                  >
                    <AButton
                      style="outline"
                      color={
                        ['brightTeal', 'orange', 'red', 'darkGray'].includes(
                          block.theme || ''
                        )
                          ? 'white'
                          : 'gray'
                      }
                      type={block.button.type === 'url' ? 'a' : 'link'}
                      href={block.button.url}
                      rel={block.button.type === 'url' ? 'noreferrer' : ''}
                      target={block.button.target}
                    >
                      <span>{block.button.text}</span>
                    </AButton>
                  </div>
                )}
              </div>
            </div>
          </section>
        )
      })}
    </div>
  )
}

const blockFragment = `
  id
  typeHandle
  idName
  theme
  imageAlignInline
  image {
    url
    title
    hasFocalPoint
    focalPoint
  }
  video {
    url
    title
  }
  heading
  copy
  button {
    type
    url
    text
    target
  }
`

export const CImageCopyBlocksFragments = {
  field: `
    fragment imageCopyBlocks on imageCopyBlocks_MatrixField {
      ... on imageCopyBlocks_block_BlockType {
        ${blockFragment}
      }
    }
  `,
  field2: `
    fragment imageCopyBlocks2 on imageCopyBlocks2_MatrixField {
      ... on imageCopyBlocks2_block_BlockType {
        ${blockFragment}
      }
    }
  `,
  field3: `
    fragment imageCopyBlocks3 on imageCopyBlocks3_MatrixField {
      ... on imageCopyBlocks3_block_BlockType {
        ${blockFragment}
      }
    }
  `,
  uiField: `
    fragment uiImageCopyBlocks on uiImageCopyBlocks_MatrixField {
      ... on uiImageCopyBlocks_block_BlockType {
        ${blockFragment}
      }
    }
  `,
  uiField2: `
    fragment uiImageCopyBlocks2 on uiImageCopyBlocks2_MatrixField {
      ... on uiImageCopyBlocks2_block_BlockType {
        ${blockFragment}
      }
    }
  `,
}
