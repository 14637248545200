export const normalizeHref = (href: string) => {
  let url = ''
  try {
    const urlObject = new URL(href ?? '')
    url = urlObject.pathname
  } catch {
    url = href ?? ''
  }
  return url
}
