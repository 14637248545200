import classNames from "classnames";
import { ChangeEvent, InputHTMLAttributes, useState } from "react";
import { useSiteInfo } from "shared";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  invalid?: boolean;
  label?: string;
  onChangeHandler?: (value: string) => void;
};

export const ACheckbox = ({
  checked,
  className,
  disabled,
  invalid,
  label,
  name,
  onChangeHandler,
  required,
}: Props) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const siteInfo = useSiteInfo();

  const onBlur = () => {
    setDirty(true);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    onChangeHandler && onChangeHandler(event.target.value);
  };

  return (
    <label>
      <input
        checked={checked}
        className={classNames("a-checkbox", { dirty, invalid }, className)}
        disabled={disabled}
        name={name}
        type="checkbox"
        onBlur={onBlur}
        onChange={onChange}
        required={required}
      />
      &nbsp;
      <span
        className={classNames("text-button [&>a]:underline", {
          "text-neutral-dark-gray": !invalid,
          "text-secondary-red": invalid,
          "leading-none tracking-normal": siteInfo.isUS,
        })}
        dangerouslySetInnerHTML={{ __html: label ?? "" }}
      />
    </label>
  );
};
