import { LegacyComponents } from 'doctors/settings-types'
import { useTranslation } from 'next-i18next'
import { ChangeEvent, useMemo } from 'react'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'
import { ASvg } from '../atoms/a-svg'
import { MModal } from '../molecules/m-modal'
import './c-legacy-region-modal-icl.css'

type Props = {
  active: boolean
  components: LegacyComponents
  onClose: () => void
}

export const CLegacyRegionModalIcl = ({
  components,
  active,
  onClose,
}: Props) => {
  const { t } = useTranslation('common')

  const normalizedLocales = useMemo(() => {
    const result = Object.entries(components.regions ?? {}).map(
      ([key, value]) => {
        return {
          label: key,
          subitems: Object.entries(value).map(([, itemValue]) => itemValue),
        }
      }
    )

    return [result.slice(0, 1), result.slice(1, 4), result.slice(4)]
  }, [components])

  const normalizedLocalesDropdown = useMemo(() => {
    const result = []
    for (const value of Object.values(components.regions ?? {})) {
      for (const lang of Object.values(value)) {
        result.push({
          value: lang.url,
          option: lang.text,
        })
      }
    }
    return result
  }, [components])

  const handleClose = () => {
    if (onClose instanceof Function) {
      onClose()
    }
  }

  const handleRegionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    location.href = event.target.value
  }

  return (
    <MModal open={active} onClose={handleClose}>
      <section className="c-modal-region-icl">
        <h2 className="text-[#2e3030] text-3xl md:text-4xl font-body font-light text-center mb-8 md:mb-12">
          {t('select-your-region')}
        </h2>
        <div className="md:hidden">
          <div className="p-4 flex justify-between items-center border border-neutral-dark-gray relative">
            <span className="text-gray-800 font-bold">
              {t('select-a-region')}
            </span>
            <ASvg
              className="w-8 h-8 stroke-current text-primary-teal"
              svg={SvgArrowDownIcon}
            />
            <select
              className="absolute top-0 left-0 w-full h-full opacity-0"
              onChange={handleRegionChange}
            >
              <option disabled>{t('select-a-region')}</option>
              {normalizedLocalesDropdown.map((item, n) => {
                return (
                  <option value={item.value} key={n}>
                    {item.option}
                  </option>
                )
              })}
            </select>
          </div>
          {/* <Dropdown
            class="c-modal-region-icl__dropdown"
            :label="$t('region')"
            :full="true"
            :placeholder="$t('select-a-region')"
            :options="normalizedLocalesDropdown"
            @change="regionChange"
          /> */}
        </div>
        <div className="hidden md:flex">
          {normalizedLocales.map((group, m) => {
            return (
              <div key={m} className="w-1/3">
                {group.map((block: any, n: number) => {
                  return (
                    <div key={n} className="px-5 mb-12">
                      <p className="c-legacy-region-modal-icl__subheading text-[#909495] text-[13px] tracking-[0.78px] leading-[22px] font-body uppercase">
                        {block.label}
                      </p>
                      <ul>
                        {block.subitems?.map(
                          (lang: { url: string; text: string }, o: number) => {
                            return (
                              <li key={o}>
                                <a
                                  className="text-[#909495] hover:text-[#098b9a] text-[12px] tracking-[0.72px] leading-[34px] font-bold uppercase"
                                  href={lang.url}
                                >
                                  {lang.text}
                                </a>
                              </li>
                            )
                          }
                        )}
                      </ul>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </section>
    </MModal>
  )
}
