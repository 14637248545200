import { MouseEvent, useMemo, useContext } from 'react'
import { useTranslation } from 'next-i18next'
import './c-centered-copy.css'
import { HeaderContext } from '../../context/header'

export type CCenteredCopyBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  copy?: string
  notes?: string
  showReferencesButton?: boolean
}

type Props = {
  field: Array<CCenteredCopyBlock>
}

export const CCenteredCopy = ({ field }: Props) => {
  const headerContext = useContext(HeaderContext)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const { t } = useTranslation('common')

  const showReferencesModal = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    headerContext?.setShowReferencesModal(true)
  }

  if (!content?.heading && !content?.copy && !content?.notes) {
    return <></>
  }

  return (
    <section id={content.idName} className="c-centered-copy">
      <div className="w-full px-5 mx-auto c-centered-copy__container py-14">
        {content.heading && (
          <h2
            className="font-semibold c-centered-copy__heading text-h7 text-neutral-dark-gray text-center whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: content.heading }}
          />
        )}
        {content.copy && (
          <div
            className="mt-4 font-semibold c-centered-copy__copy text-primary-gray text-p-sm s-wysiwyg"
            dangerouslySetInnerHTML={{ __html: content.copy }}
          />
        )}
        {content.notes && (
          <div
            className="mt-4 font-semibold c-centered-copy__notes text-primary-gray text-p-sm s-wysiwyg"
            dangerouslySetInnerHTML={{ __html: content.notes }}
          />
        )}
        {content.showReferencesButton && (
          <p className="mt-4 font-semibold text-primary-teal text-p-sm s-wysiwyg">
            <a href="#" onClick={showReferencesModal}>
              {t('references')}
            </a>
          </p>
        )}
      </div>
    </section>
  )
}

export const CCenteredCopyFragments = {
  field: `
    fragment centeredCopy on centeredCopy_MatrixField {
      ... on centeredCopy_block_BlockType {
        id
        typeHandle
        idName
        heading
        copy
        notes
        showReferencesButton
      }
    }
  `,
}
