import classNames from 'classnames'
import { ChangeEvent, SelectHTMLAttributes, useState } from 'react'
import './a-select.css'

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  invalid?: boolean
  options: Array<{
    disabled?: boolean
    label: string
    value: string
  }>
  onChangeHandler?: (value: string) => void
}

export const ASelect = ({
  className,
  disabled,
  invalid,
  name,
  options,
  required,
  value,
  onChangeHandler,
}: Props) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onBlur = () => {
    setDirty(true)
  }

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setDirty(true)
    onChangeHandler && onChangeHandler(event.target.value)
  }

  return (
    <select
      className={classNames('a-select', { dirty, invalid }, className)}
      disabled={disabled}
      name={name}
      required={required}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      {options.map((option) => (
        <option
          disabled={option.disabled}
          key={option.value}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  )
}
