import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import SvgIconCheck from 'ui/svg/icons/fill/check.svg'
import { AButton, APicture, ASvg } from 'ui'
import './c-background-image-list.css'
import classNames from 'classnames'
import { throttle } from 'lodash'

export type CBackgroundImageListBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  subheading?: string
  list: Array<{
    item?: string
  }>
  desktopImage?: Array<CraftAssetField>
  mobileImage?: Array<CraftAssetField>
  copy?: string
  button?: CraftLinkItField
}

type Props = {
  field: Array<CBackgroundImageListBlock>
}

export const CBackgroundImageList = ({ field }: Props) => {
  const [active, setActive] = useState(true)
  const [windowWidth, setWindowWidth] = useState<number>(0)

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleWindowResizeThrottled = throttle(handleWindowResize, 100)

  const containerRef = useRef<HTMLDivElement>(null)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const handleScroll = () =>
    setActive(
      (containerRef.current?.getBoundingClientRect().top || 0) <=
        window.innerHeight * 0.5
    )

  useEffect(() => {
    const thottledScroll = throttle(handleScroll, 100)
    if (content) {
      window.addEventListener('scroll', thottledScroll)
      handleScroll()
    }

    return () => {
      if (content) {
        window.removeEventListener('scroll', thottledScroll)
      }
    }
  }, [content])

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResizeThrottled)

    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, [])

  if (!(content?.heading || content?.subheading || content?.list?.length)) {
    return <></>
  }

  return (
    <div id={content?.idName} className="c-background-image-list">
      <section ref={containerRef}>
        <div className="c-background-image-list__container m-3 lg:m-6 lg:bg-neutral-light-gray relative">
          <div className="c-background-image-list__image-ratio relative lg:absolute lg:top-0 lg:inline-start-0 lg:w-full lg:h-full">
            <APicture
              className="absolute top-0 inline-start-0 w-full h-full object-cover md:relative md:top-auto md:inline-start-0 md:h-auto lg:h-full"
              imageDesktop={content?.desktopImage && content.desktopImage[0]}
              imageMobile={content?.mobileImage && content.mobileImage[0]}
            />
          </div>

          <div className="c-background-image-list__inner-container px-6 mx-auto h-full flex items-center relative z-10 mt-8 lg:mt-0">
            <div
              className={classNames(
                'flex flex-col font-base text-neutral-dark-gray lg:text-neutral-white transition-opacity duration-500 ease-out',
                {
                  'opacity-100': active,
                  'opacity-0': !active,
                }
              )}
            >
              <h2 className="c-background-image-list__heading text-h6 leading-10 w-full">
                {content?.heading}
              </h2>
              <h3 className="c-background-image-list__subheading text-h7 font-bold leading-9 mt-4 w-full">
                {content?.subheading}
              </h3>
              <ul
                className={classNames(
                  'mt-4 lg:mt-6 lg:w-1/2 transform transition-transform duration-500 ease-out',
                  {
                    'translate-y-0': active,
                    'translate-y-8': !active,
                  }
                )}
              >
                {content?.list.map((item, n) => {
                  return (
                    <li key={n} className="c-background-image-list__item">
                      <div className="c-background-image-list__dot">
                        <ASvg
                          className="stroke-current fill-current"
                          svg={SvgIconCheck}
                        />
                      </div>
                      <span>{item.item}</span>
                    </li>
                  )
                })}
              </ul>
              {content.button?.url && (
                <div className="mt-9 transition-transform duration-500 delay-200 transform">
                  <AButton
                    color={windowWidth < 1024 ? 'teal' : 'white'}
                    style={windowWidth < 1024 ? 'outline' : 'solid'}
                    type={content.button?.type === 'custom' ? 'a' : 'link'}
                    href={content.button?.url}
                    rel={content.button?.type === 'custom' ? 'noreferrer' : ''}
                    target={content.button?.target}
                  >
                    <span>{content.button.text}</span>
                  </AButton>
                </div>
              )}
              {content.copy && (
                <div className="c-background-image-list__copy-box">
                  <div
                    className="mt-5 italic lg:text-neutral-white c-background-image-list__copy"
                    dangerouslySetInnerHTML={{ __html: content.copy }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const blockFields = `
  id
  typeHandle
  idName
  heading
  subheading
  list {
    item
  }
  desktopImage {
    url @transform(mode: "crop", width: 2000, immediately: true)
    title
    hasFocalPoint
    focalPoint
  }
  mobileImage {
    url @transform(mode: "crop", width: 640, immediately: true)
    title
    hasFocalPoint
    focalPoint
  }
  copy
  button {
    type
    url
    text
    target
  }
`

export const CBackgroundImageListFragments = {
  field: `
    fragment backgroundImageList on backgroundImageList_MatrixField {
      ... on backgroundImageList_block_BlockType {
        ${blockFields}
      }
    }
  `,
  uiField: `
    fragment uiBackgroundImageList on uiBackgroundImageList_MatrixField {
      ... on uiBackgroundImageList_block_BlockType {
        ${blockFields}
      }
    }
  `,
}
