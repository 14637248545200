import classNames from 'classnames'
import throttle from 'lodash/throttle'
import { useEffect, useMemo, useRef, useState } from 'react'
import './c-questions-and-answers.css'

export type CQuestionsAndAnswersBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  questions?: Array<{
    question?: string
    answer?: string
  }>
}

type Props = {
  field: Array<CQuestionsAndAnswersBlock>
}

export const CQuestionsAndAnswers = ({ field }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const sectionRef = useRef<HTMLElement>(null)

  // const [animate, setAnimate] = useState<boolean>(false)
  const [animateDown, setAnimateDown] = useState<boolean>(false)

  const handleWindowScroll = () => {
    const rect = sectionRef.current?.getBoundingClientRect()

    if (rect) {
      // setAnimate((rect.top <= window.innerHeight * 0.85) && (rect.bottom >= window.innerHeight * 0.85))
      setAnimateDown(rect.top <= window.innerHeight * 0.85)
    }
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScrollThrottled)
    handleWindowScroll()

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
    }
  }, [])

  if (!content?.questions?.length) {
    return <></>
  }

  return (
    <section
      id={content.idName}
      ref={sectionRef}
      className={classNames(
        'c-questions-and-answers mx-6 my-18 md:m-28 md:flex',
        { 'c-questions-and-answers--animate': animateDown }
      )}
    >
      <div className="md:w-1/2 md:pie-4">
        <h2 className="c-questions-and-answers__heading text-neutral-dark-gray text-h5 md:text-h3 md:sticky md:top-20">
          {content.heading}
        </h2>
      </div>
      <div className="c-questions-and-answers__qas md:w-1/2 md:pis-4 mt-12 md:mt-0">
        {content.questions?.map((item, i) => (
          <div key={i} className="c-questions-and-answers__item">
            <h3 className="text-neutral-dark-gray text-h7 md:text-h5 font-semibold md:font-normal">
              {item.question}
            </h3>
            <p className="text-primary-gray text-p md:text-p-lg font-semibold md:font-normal mt-2">
              {item.answer}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

export const CQuestionsAndAnswersFragments = {
  field: `
    fragment questionsAndAnswers on questionsAndAnswers_MatrixField {
      ... on questionsAndAnswers_block_BlockType {
        id
        typeHandle
        idName
        heading
        questions {
          question
          answer
        }
      }
    }
  `,
}
