import classNames from 'classnames'
import { useRef, useState } from 'react'
import SvgPlayIcon from 'ui/svg/icons/stroke/play.svg'
import SvgCloseIcon from 'ui/svg/icons/stroke/close.svg'
import { useTranslation } from 'next-i18next'
import { AVideo, AVideoMethods, ASvg } from 'ui'
import './m-video-pip'

type Props = {
  className: string
  src: string
}

export const MVideoPip = ({ src, className }: Props) => {
  const { t } = useTranslation('common')

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [videoMaxHeight, setVideoMaxHeight] = useState<string>('0')

  const videoRef = useRef<AVideoMethods>(null)
  const videoOuterRef = useRef<HTMLDivElement>(null)

  const toggleVideo = () => {
    if (videoRef.current && videoOuterRef.current) {
      if (isOpen) {
        setIsOpen(false)
        setVideoMaxHeight('0')
        videoRef.current.pause()
      } else {
        setIsOpen(true)
        setVideoMaxHeight(videoOuterRef.current.scrollHeight + 'px')
        videoRef.current.play()
      }
    }
  }

  return (
    <div
      className={classNames(`m-video-pip relative ${className}`, {
        'is-open': isOpen,
      })}
    >
      <button
        className="rounded-full border-primary-teal text-primary-teal bg-neutral-white hover:bg-primary-teal hover:text-neutral-white absolute top-0 inline-start-0 z-10"
        onClick={toggleVideo}
      >
        <ASvg
          className={classNames('fill-current stroke-current', {
            hidden: isOpen,
          })}
          svg={SvgPlayIcon}
          aria-label={t('play')}
        />
        <ASvg
          className={classNames('fill-current stroke-current', {
            hidden: !isOpen,
          })}
          svg={SvgCloseIcon}
          aria-label={t('close')}
        />
      </button>
      <div
        ref={videoOuterRef}
        className="m-video-pip__video-outer relative overflow-hidden transition-all duration-500 pt-5 pis-5"
        style={{
          maxHeight: videoMaxHeight,
        }}
      >
        <AVideo
          ref={videoRef}
          className="m-video-pip__video"
          playsInline={true}
          controls={true}
          src={src}
        />
      </div>
    </div>
  )
}
