import { useMemo } from 'react'
import { CraftGlobals } from 'tsconfig/craft-types'
import { CAboutVideo, CAboutVideoBlock } from './c-about-video'
import {
  CBackgroundImageCopy,
  CBackgroundImageCopyBlock,
} from './c-background-image-copy'
import { CComparisonTable, CComparisonTableBlock } from './c-comparison-table'
import {
  CCopyBlock,
  CCopyBlockBlock,
  CCopyBlockFragments,
} from './c-copy-block'
import { CFindADoctor, CFindADoctorBlock } from './c-find-a-doctor'
import { CImageCopyBlocks, CImageCopyBlocksBlock } from './c-image-copy-blocks'
import {
  CSplitTestimonial,
  CSplitTestimonialBlock,
} from './c-split-testimonial'
import { CStats, CStatsBlock } from './c-stats'
import {
  CTestimonialsSlider,
  CTestimonialsSliderBlock,
} from './c-testimonials-slider'

type Props = {
  field: Array<
    | CAboutVideoBlock
    | CBackgroundImageCopyBlock
    | CComparisonTableBlock
    | CFindADoctorBlock
    | CImageCopyBlocksBlock
    | CSplitTestimonialBlock
    | CStatsBlock
    | CTestimonialsSliderBlock
    | CCopyBlockBlock
  >
  global: CraftGlobals
}

export const CCustomComponentsLp = ({ field, global }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field : []
  }, [field])

  return (
    <div>
      {content.map((block) => {
        switch (block.typeHandle) {
          case 'aboutVideo':
            return <CAboutVideo field={global.aboutVideo} />
          case 'backgroundImageCopy':
            return <CBackgroundImageCopy field={global.backgroundImageCopy} />
          case 'comparisonTable':
            return <CComparisonTable field={global.comparisonTable} />
          case 'findADoctor':
            return <CFindADoctor field={global.findADoctor} />
          case 'imageCopyBlocks':
            return <CImageCopyBlocks field={global.imageCopyBlocks} />
          case 'splitTestimonial':
            return <CSplitTestimonial field={global.splitTestimonial} />
          case 'stats':
            return <CStats field={global.stats} />
          case 'testimonialsSlider':
            return <CTestimonialsSlider field={global.testimonialsSlider} />
          case 'copyBlock':
            return <CCopyBlock field={[block]} />
        }

        return <></>
      })}
    </div>
  )
}

export const CCustomComponentsLpFragments = {
  field: `
    fragment customComponentsLandingPage on customComponentsLandingPage_MatrixField {
      ... on customComponentsLandingPage_aboutVideo_BlockType {
        typeHandle
      }
      ... on customComponentsLandingPage_backgroundImageCopy_BlockType {
        typeHandle
      }
      ... on customComponentsLandingPage_comparisonTable_BlockType {
        typeHandle
      }
      ... on customComponentsLandingPage_findADoctor_BlockType {
        typeHandle
      }
      ... on customComponentsLandingPage_imageCopyBlocks_BlockType {
        typeHandle
      }
      ... on customComponentsLandingPage_splitTestimonial_BlockType {
        typeHandle
      }
      ... on customComponentsLandingPage_stats_BlockType {
        typeHandle
      }
      ... on customComponentsLandingPage_testimonialsSlider_BlockType {
        typeHandle
      }
      ...customComponentsLandingPage_copyBlock
    }
    ${CCopyBlockFragments.customComponentsLandingPageBlock}
  `,
}
