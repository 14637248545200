import { useMemo } from "react";
import classNames from "classnames";
import "./c-simple-copy.css";
import { useTranslation } from "next-i18next";
import { AButton } from "ui";
import { useSiteInfo } from "shared";

export type CSimpleCopyBlock = {
  id?: string;
  typeHandle?: string;
  idName?: string;
  headingSize?: string;
  headingColor?: string;
  heading?: string;
  copy?: string;
};

type Props = {
  field: Array<CSimpleCopyBlock>;
  parentTypeHandle?: string;
};

export const CSimpleCopy = ({ field, parentTypeHandle }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field[0] : null;
  }, [field]);
  const { t } = useTranslation("common");
  const { quizUrl } = useSiteInfo();

  return (
    <section
      className={classNames("c-simple-copy mb-18 md:mb-30 mx-6", {
        "mt-25":
          content?.headingSize === "small" || content?.headingSize === null,
        "mt-30": content?.headingSize === "large",
      })}
    >
      <div className="c-simple-copy__container w-full">
        {content?.heading && (
          <h2
            className={classNames({
              "text-h4":
                content?.headingSize === "small" ||
                content?.headingSize === null,
              "text-h6 md:text-h3": content?.headingSize === "large",
              "text-neutral-dark-gray":
                content?.headingColor === "gray" ||
                content?.headingColor === null,
              "text-primary-teal": content?.headingColor === "teal",
            })}
          >
            {content?.heading}
          </h2>
        )}
        {content?.copy && (
          <div
            className={classNames(
              "text-p-lg text-primary-gray whitespace-pre-line",
              {
                "mt-4": content.heading && content.headingSize === "small",
                "mt-10 md:mt-20":
                  content.heading && content.headingSize === "large",
              }
            )}
            dangerouslySetInnerHTML={{ __html: content.copy }}
          />
        )}
        {parentTypeHandle === "testimonialPages" && (
          <AButton
            type="a"
            href={quizUrl}
            target="_blank"
            rel="noreferrer"
            style="outline"
            color="gray"
            className="mt-4"
          >
            <span>{t("titles.take-our-quiz")}</span>
          </AButton>
        )}
      </div>
    </section>
  );
};

export const CSimpleCopyFragments = {
  field: `
    fragment simpleCopy on simpleCopy_MatrixField {
      ... on simpleCopy_block_BlockType {
        id
        typeHandle
        idName
        headingSize
        headingColor
        heading
        copy
      }
    }
  `,
  field2: `
    fragment simpleCopy2 on simpleCopy2_MatrixField {
      ... on simpleCopy2_block_BlockType {
        id
        typeHandle
        idName
        headingSize
        headingColor
        heading
        copy
      }
    }
  `,
};
