/* eslint-disable react/no-unescaped-entities */

import { useRouter } from 'next/router'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { AButton, ASvg, MModal } from 'ui'
import SvgCheckFalseIcon from 'ui/svg/icons/fill/check-false.svg'
import SvgCheckTrueIcon from 'ui/svg/icons/fill/check-true.svg'
import { AFormInput } from 'doctors/components/atoms/a-form-input'
import {
  MDropdown,
  MDropdownOption,
} from 'doctors/components/molecules/m-dropdown'
import * as CONST from 'ui/constants/index'
import css from 'styled-jsx/css'
import { useSiteInfo } from 'shared'

export const CExitIntentModal = () => {
  const router = useRouter()
  const path = router.asPath
  const backButtonText = pathName[path]

  const siteInfo = useSiteInfo()
  const { t } = useTranslation('common')

  const [email, setEmail] = useState<string>('')
  const [zipcode, setZipcode] = useState<string>('')
  const [value, setValue] = useState<string>('')

  const [show, setShow] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [type, setType] = useState<number>(1)

  const [formConfirmed, setFormConfirmed] = useState<boolean>(false)
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

  const options: Array<MDropdownOption> = useMemo(
    () => [
      {
        selected: value === 'contacts',
        value: 'contacts',
        option: 'I wear contacts',
      },
      {
        selected: value === 'glasses',
        value: 'glasses',
        option: 'I wear glasses',
      },
      { selected: value === 'both', value: 'both', option: 'I wear both' },
    ],
    [value]
  )

  const submitHandler = async (event: any) => {
    event.preventDefault()

    setFormSubmitted(true)

    if (!formConfirmed && siteInfo?.iso === 'en-US') return

    setStep(2)

    TagManager.dataLayer({
      dataLayer: {
        event: type === 1 ? 'originalSubmit' : 'variantTwo',
        selectedOption: value,
      },
    })

    const body: any = {
      contact: {
        Email: email,
        MailingPostalCode: zipcode,
        custom: {
          'string--FaD--Exit--Intent--Selection': value,
        },
      },
    }

    if (type === 2) {
      body.contact.custom['boolean--Fad--ICL--Download--Guide'] = true
    }

    try {
      fetch(
        process.env.NEXT_PUBLIC_DOCTORS_API_PROXY_URL +
          '/autopilot/captureUser.js',
        {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        }
      )
      setEmail('')
    } catch (e) {}
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('variant2')) {
      setType(2)
    }
  }, [])

  return (
    <MModal open={show} onClose={() => setShow(false)}>
      <>
        {step === 1 && type === 1 && (
          <div>
            <div className="px-0 md:px-24">
              <h2 className="dropdown-heading text-3xl font-body text-center">
                Feeling unsure?
              </h2>

              <p className="mt-4 font-base font-semibold text-gray-700 leading-7 text-center">
                We get it. Decisions like this take time to consider and
                research. We can help guide your decision with information
                that's the most relevant to you.
              </p>

              <p className="mt-4 font-base font-semibold text-primary-teal leading-7 text-center">
                Do you currently wear glasses or contacts?
              </p>

              <form onSubmit={submitHandler} className="form">
                <div className="mt-4">
                  <MDropdown
                    label={t('select') + '...'}
                    full={true}
                    placeholder={t('select') + '...'}
                    options={options}
                    onChange={(option) => setValue(option.value)}
                  />
                </div>
                <div className="mt-4">
                  <AFormInput
                    placeholder={t('Email address') ?? ''}
                    required={true}
                    type="email"
                    value={email}
                    version="standalone"
                    onChangeHandler={setEmail}
                  />
                </div>
                {siteInfo?.isUS && (
                  <>
                    <div className="mt-4">
                      <AFormInput
                        placeholder={t('Zip Code') ?? ''}
                        required={true}
                        type="text"
                        value={zipcode}
                        version="standalone"
                        onChangeHandler={setZipcode}
                      />
                    </div>
                    <button
                      type="button"
                      className="cursor-pointer flex mt-4 p-5"
                      onClick={() => setFormConfirmed(!formConfirmed)}
                    >
                      <ASvg
                        className={classNames(
                          'flex-shrink-0 fill-current mr-4 w-6 h-6',
                          {
                            'text-primary-teal':
                              (formConfirmed || !formSubmitted) &&
                              siteInfo?.layout === CONST.SITE_LAYOUT.ICL,
                            'text-pink-500':
                              (formConfirmed || !formSubmitted) &&
                              siteInfo?.layout === CONST.SITE_LAYOUT.EVO,
                            'text-secondary-red': !formConfirmed && formSubmitted,
                          }
                        )}
                        svg={formConfirmed ? SvgCheckTrueIcon : SvgCheckFalseIcon}
                      />
                      <div>
                        <p
                          className="text-xs text-gray-700 w-full [&>a]:underline [&>a]:text-primary-dark-teal"
                          dangerouslySetInnerHTML={{
                            __html: t('form-subscription-text') ?? '',
                          }}
                        />
                        {!formConfirmed && formSubmitted && (
                          <p className="text-xs text-secondary-red mt-3">
                            Checkbox is required.
                          </p>
                        )}
                      </div>
                    </button>
                  </>
                )}
                <div className="mt-4 text-center">
                  <AButton className="w-full" size="lg" type="submit">
                    <>{t('submit') ?? ''}</>
                  </AButton>
                </div>
              </form>
            </div>
          </div>
        )}

        {step === 2 && type === 1 && (
          <div className="thankYouPage">
            <h2 className="dropdown-heading text-3xl font-body text-center">
              You're on your way to visual freedom.
            </h2>

            <p className="mt-4 font-base font-semibold text-gray-700 leading-7 text-center">
              Thank you for your interest in EVO Visian ICL.
            </p>

            <AButton
              type="button"
              className="mt-4 w-full"
              style="outline"
              color="black"
              size="lg"
              onClick={() => setShow(false)}
            >
              <>Back to {backButtonText}</>
            </AButton>
          </div>
        )}

        {step === 1 && type === 2 && (
          <div>
            <div className="w-3/5">
              <h2 className="dropdown-heading text-3xl font-body">
                Want the ultimate guide to EVO Visian ICL?
              </h2>

              <p className="mt-4 font-base font-semibold text-primary-teal leading-7">
                In this guide, you will learn:
              </p>

              <ul className="ml-5 font-base text-gray-700 leading-7 list-disc">
                <li>If you're a good candidate for EVO Visian ICL</li>
                <li>Why EVO Visian ICL is an excellent vision solution</li>
                <li>All the steps of the 20-30 minute procedure</li>
                <li>Everything else you need to know about EVO Visian ICL</li>
              </ul>

              <form onSubmit={submitHandler}>
                <div className="mt-4">
                  <AFormInput
                    placeholder={t('email-address') ?? ''}
                    required={true}
                    type="email"
                    value={email}
                    version="standalone"
                    onChangeHandler={setEmail}
                  />
                </div>
                <div className="mt-4 text-center">
                  <AButton size="lg" type="submit">
                    <>Download the EVO Visian ICL guide</>
                  </AButton>
                </div>
              </form>

              <p className="mt-4 text-[10px] text-gray-700 text-left">
                STAAR Surgical®, as the data controller, would like to contact
                you periodically to provide information regarding our products,
                services, events and content that may be of interest to you. I
                would like to recieve marketing or market research
                communications from STAAR Surgical® via email. If you wish to
                stop receiving marketing or market research communications from
                STAAR Surgical® you can unsubscribe via the link at the bottom
                of the relevant marketing email at any time. For further
                information on how we use your personal data please refer to our
                online Privacy Statement.
              </p>
            </div>
          </div>
        )}

        {step === 2 && type === 2 && (
          <div className="thankYouPage">
            <h2 className="dropdown-heading text-3xl font-body text-center">
              You're on your way to visual freedom.
            </h2>

            <p className="mt-4 font-base font-semibold text-gray-700 leading-7 text-center">
              Thank you for your interest in EVO Visian ICL. The Ultimate Guide
              to EVO Visian ICL has been sent to your email.
            </p>

            <AButton
              type="button"
              className="mt-4 w-full"
              style="outline"
              color="black"
              size="lg"
              onClick={() => setShow(false)}
            >
              <>Back to {backButtonText}</>
            </AButton>
          </div>
        )}
        {/* @ts-ignore */}
        <style jsx>{styles}</style>
      </>
    </MModal>
  )
}

const styles = css`
  .thankYouPage {
    @media (min-width: 767px) {
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }
`

const pathName: { [key: string]: string } = {
  '/': 'Homepage',
  '/evo-visian-icl-lens': 'What is Evo?',
  '/sharp-clear-vision-day-and-night': 'Sharp, Clear Vision Day and Night',
  '/harmony': 'Harmony with Natural Eye',
  '/dry-eye': 'Dry Eye',
  '/procedure': 'Procedure',
  '/life-with-evo': 'Testimonials',
  '/faq': 'FAQ',
  '/testimonials/jimmy-o-yang': 'Jimmy O. Yang Testimonial',
  '/testimonials/peyton': 'Peyton Testimonial',
}
