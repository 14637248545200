/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'
import { useMemo } from 'react'
import './c-photo-collage.css'

export type CPhotoCollageBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  images?: Array<{
    url?: string
    title?: string
  }>
}

type Props = {
  images?: CPhotoCollageBlock['images']
  field?: Array<CPhotoCollageBlock>
}

const isDouble = (n: number, l: number) => {
  return (l % 3 === 2 && n === 1) || (l % 3 === 1 && [1, 2].includes(n))
}

export const CPhotoCollage = ({ images, field }: Props) => {
  const content = useMemo(() => {
    return images?.length ? { images } : field?.length ? field[0] : null
  }, [field])

  if (!content?.images?.length) {
    return <></>
  }

  return (
    <div id={content.idName} className="c-photo-collage">
      <div className="c-photo-collage__container o-container o-container--md flex flex-wrap relative">
        {content.images.map((image, n, a) => (
          <div
            key={n}
            className={classNames('c-photo-collage__image-container', {
              'c-photo-collage__image-container--double': isDouble(n, a.length),
            })}
          >
            <div className="c-photo-collage__ratio">
              <img
                className="c-photo-collage__image"
                src={image.url}
                alt={image.title}
                loading="lazy"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const CPhotoCollageFragments = {
  field: `
    fragment photoCollage on photoCollage_MatrixField {
      ... on photoCollage_block_BlockType {
        id
        typeHandle
        idName
        images {
          url
          title
        }
      }
    }
  `,
}
