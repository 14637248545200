import { useMemo } from 'react'
import { AButton, APicture } from 'ui'
import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import './c-partial-text-masthead.css'
import { normalizeHref } from 'discovericl/helpers/normalize-href'

export type CPartialTextMastheadBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  subheading?: string
  button?: CraftLinkItField
  imageDesktop?: Array<CraftAssetField>
  imageMobile?: Array<CraftAssetField>
}

type Props = {
  field: Array<CPartialTextMastheadBlock>
}

export const CPartialTextMasthead = ({ field }: Props) => {
  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  return (
    <section className="c-partial-text-masthead">
      <div className="c-partial-text-masthead__spacer mb-6 lg:mb-16" />
      <div className="relative">
        <div className="o-container o-container--xl">
          <div className="c-partial-text-masthead__left">
            <h1 className="lg:font-light text-h6 lg:text-h3 text-neutral-dark-gray">
              {content?.heading}
            </h1>
            {content?.subheading && (
              <div className="mt-4 text-h7 text-primary-gray">
                {content.subheading}
              </div>
            )}
            {content?.button && content?.button.url && (
              <div className="mt-4">
                <AButton
                  type={content.button.type === 'entry' ? 'link' : 'a'}
                  target={content.button.target}
                  href={
                    content.button?.type === 'entry'
                      ? normalizeHref(content.button?.url || '')
                      : content.button?.url || ''
                  }
                  style="outline"
                  color="teal"
                >
                  <span>{content?.button?.text ?? ''}</span>
                </AButton>
              </div>
            )}
          </div>
          <div className="c-partial-text-masthead__right hidden md:block mt-10 xl:mt-0 -mx-6 xl:mx-0">
            <APicture
              className="block w-full h-full object-cover"
              imageDesktop={content?.imageDesktop && content.imageDesktop[0]}
              imageMobile={content?.imageMobile && content.imageMobile[0]}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const CPartialTextMastheadFragments = {
  field: `
    fragment partialTextMasthead on partialTextMasthead_MatrixField {
      ... on partialTextMasthead_block_BlockType {
        id
        typeHandle
        idName
        heading
        subheading
        button {
          text
          url
        }
        imageDesktop {
          title
          url
          hasFocalPoint
          focalPoint
        }
        imageMobile {
          title
          url
          hasFocalPoint
          focalPoint
        }
      }
    }
  `,
}
