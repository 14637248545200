import classNames from 'classnames'
import Link from 'next/link'
import SvgArrowRightIcon from 'ui/svg/icons/stroke/arrow-right.svg'
import { ASvg } from 'ui'

type Props = {
  type?: string
  children?: JSX.Element
  href?: string
  className?: string
  target?: string
  rel?: string
  color?: string // teal, white, gray, pink
  onClick?: any
  shallow?: boolean
  size?: string // md, sm
}

type ComponentProps = {
  className?: string
}

const defaultProps = {
  type: 'button',
  color: 'white',
  size: 'md',
}

const AHyperlink = (props: Props) => {
  const Icon = () => {
    return (
      <ASvg
        className="stroke-current w-6 h-6 ml-1 transform group-hover:translate-x-1 transition-transform duration-200"
        svg={SvgArrowRightIcon}
      />
    )
  }

  const Component = (componentProps: ComponentProps): JSX.Element => {
    if (props.type === 'button') {
      return (
        <button className={componentProps.className} onClick={props.onClick}>
          {props.children}
          <Icon />
        </button>
      )
    } else if (props.type === 'span') {
      return (
        <span className={componentProps.className}>
          {props.children}
          <Icon />
        </span>
      )
    } else if (props.type === 'a') {
      return (
        <a
          className={componentProps.className}
          href={props.href}
          target={props.target}
          rel={props.rel}
          onClick={props.onClick}
        >
          {props.children}
          <Icon />
        </a>
      )
    } else if (props.type === 'link') {
      let url = ''
      try {
        const urlObject = new URL(props.href ?? '')
        url = urlObject.pathname
      } catch {
        url = props.href ?? '#'
      }

      return (
        <Link
          className={componentProps.className}
          href={url}
          target={props.target}
          rel={props.target}
          onClick={props.onClick}
          prefetch={false}
          shallow={props.shallow}
        >
          {props.children}
          <Icon />
        </Link>
      )
    }

    return <></>
  }

  return (
    <Component
      className={classNames(
        `${props.className} inline-flex items-center justify-center font-bold text-center uppercase transition-all duration-300 align-middle group`,
        {
          'text-neutral-white hover:text-neutral-light-gray':
            props.color === 'white',
          'text-primary-teal hover:text-primary-light-teal':
            props.color === 'teal',
          'text-pink-500 hover:text-pink-600': props.color === 'pink',
          'text-button': props.size === 'md',
          'text-p-sm': props.size === 'sm',
        }
      )}
    />
  )
}

AHyperlink.defaultProps = defaultProps

export { AHyperlink }
