import { useMemo } from 'react'
import './c-copy-block.css'

export type CCopyBlockBlock = {
  typeHandle?: string
  idName?: string
  containerMaxWidth?: string
  copy?: string
}

type Props = {
  field: Array<CCopyBlockBlock>
  block?: any
}

export const CCopyBlock = ({ field, block }: Props) => {
  const content = useMemo(() => {
    return block && Object.keys(block).length ? block : field ? field[0] : null
  }, [field, block])

  return (
    <div id={content.idName} className="c-copy-block">
      <div
        className={`
          o-container
          ${
            content.containerMaxWidth
              ? `o-container o-container--${content.containerMaxWidth}`
              : ''
          }
        `}
      >
        <div
          className="s-wysiwyg text-p-lg"
          dangerouslySetInnerHTML={{ __html: content.copy }}
        />
      </div>
    </div>
  )
}

export const CCopyBlockFragments = {
  wysiwygBlock: `
    fragment wysiwyg_copyBlock on wysiwyg_copyBlock_BlockType {
      typeHandle
      idName
      containerMaxWidth
      copy
    }
  `,
  customComponentsLandingPageBlock: `
    fragment customComponentsLandingPage_copyBlock on customComponentsLandingPage_copyBlock_BlockType {
      typeHandle
      idName
      containerMaxWidth
      copy
    }
  `,
}
