import classNames from 'classnames'
import { ChangeEvent, InputHTMLAttributes, useState } from 'react'
import './a-input.css'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  invalid?: boolean
  onChangeHandler?: (value: string) => void
}

export const AInput = ({
  className,
  disabled,
  invalid,
  name,
  placeholder,
  required,
  type,
  value,
  onChangeHandler,
}: Props) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onBlur = () => {
    setDirty(true)
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true)
    onChangeHandler && onChangeHandler(event.target.value)
  }

  return (
    <input
      className={classNames('a-input', { dirty, invalid }, className)}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  )
}
