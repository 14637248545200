import { useTranslation } from 'next-i18next'
import { MouseEvent } from 'react'
import './c-legacy-footer-icl.css'

type Props = {
  components: any
}

export const CLegacyFooterIcl = ({ components }: Props) => {
  const { t } = useTranslation('common')

  const handleOpenRegionModal = (e: MouseEvent) => {
    e.preventDefault()

    const event = new CustomEvent('showIclRegionModal')
    window.dispatchEvent(event)
  }

  return (
    <footer className="c-legacy-footer-icl pl-5 pr-5 pt-10 xl:pr-10 pb-12 xl:pl-10 flex flex-col xl:flex-row font-body font-bold">
      <div className="flex-grow order-2 xl:order-1">
        <div className="mb-10 uppercase c-legacy-footer-icl__primary hidden xl:block">
          {components.footer?.menu?.map((item: any) => {
            return (
              <a
                key={item.id}
                className="c-legacy-footer-icl__primary-link"
                href={item.url}
              >
                {item.text}
              </a>
            )
          })}
        </div>
        <div className="uppercase c-legacy-footer-icl__secondary text-center xl:text-left pt-10 xl:pt-0">
          <span>
            &copy; {new Date().getFullYear()}
            <a href={components.footer?.copy[0]?.url}>
              {components.footer?.copy[0]?.text}
            </a>
            . All rights reserved.
          </span>
          <div className="mt-1 xl:mt-0 xl:inline">
            {components.footer?.copy?.slice(1)?.map((item: any) => {
              return (
                <a
                  key={item.id}
                  className="c-legacy-footer-icl__secondary-link"
                  href={item.url}
                >
                  {item.text}
                </a>
              )
            })}
            <a
              className="c-legacy-footer-icl__secondary-link"
              href="#"
              onClick={(e) => handleOpenRegionModal(e)}
            >
              {t('international-sites')}
            </a>
          </div>
        </div>
      </div>
      {/* { !!components.social?.length &&
        <div className="flex-shrink-0 order-1 xl:order-2 pb-12 xl:pb-0">
          <div className="flex justify-center xl:justify-end mb-10 xl:mb-5">
            <a
              key={key}
              :href="item"
              class="c-legacy-footer-icl__social-link block"
            >
              <ASvg
                class="fill-current social-icon"
                :icon="key"
              />
            </a>
          </div>
        </div>
      } */}
    </footer>
  )
}
