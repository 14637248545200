import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import { MImageWrapper } from '../molecules/m-image-wrapper'
import Image from 'next/image'
import classNames from 'classnames'
import './c-content-with-side-image.css'
import { AButton } from '../atoms/a-button'
import { useEffect, useContext, useState } from 'react'

export interface CContentWithSideImageBlock {
  heading?: string
  sectionContent?: string
  imageAligment?: string
  image?: Array<CraftAssetField>
  cta?: CraftLinkItField
  bgColor?: string
}

interface CContentWithSingleImageProps {
  field: CContentWithSideImageBlock[]
  isSimple?: boolean
  key?: string
}

export const CContentWithSideImage = ({
  field,
  isSimple = false,
  key,
}: CContentWithSingleImageProps) => {
  const { heading, image, imageAligment, sectionContent, cta, bgColor } =
    field?.[0] ?? []
  const isImage = Boolean(image?.[0]?.mimeType.includes('image/'))
  const isImageRight = Boolean(imageAligment === 'right')
  const isDark = Boolean(bgColor === 'dark')

  return (
    <div
      className={classNames('md:px-18 my-10 c-content-with-image', {
        'c-content-with-side-image__wrapper': !isSimple,
        'c-content-with-side-image__dark': !isSimple && isDark,
        'c-content-with-side-image__default': !isSimple && !isDark,
      })}
    >
      <div
        className={classNames('o-container flex gap-6 lg:gap-16 flex-col', {
          'md:flex-row': isImageRight,
          'md:flex-row-reverse': !isImageRight,
          'o-container--2xl items-center': !isSimple,
          'o-container--xl': isSimple || isDark,
          'xl:gap-[128px] items-center px-0': isSimple,
        })}
      >
        <div
          className={classNames('flex-1 max-w-[530px]', {
            'px-[24px] mb-[40px] md:mb-0': isSimple,
          })}
        >
          {heading && (
            <h3
              className={classNames(
                'mb-4 md:mb-12 text-3xl lg:text-heading font-semibold text-center md:text-left',
                {
                  'text-gray-600': !isDark,
                  'text-white': isDark,
                }
              )}
            >
              {heading}
            </h3>
          )}
          {sectionContent && (
            <div
              className={classNames(
                'lg:text-xl c-content-with-side-image__content font-light',
                {
                  'text-gray-600': !isDark,
                  'text-white': isDark,
                }
              )}
              dangerouslySetInnerHTML={{ __html: sectionContent }}
            />
          )}
          {cta?.url && (
            <AButton
              className={classNames('normal-case hidden md:inline-block', {
                'md:min-w-[210px] mt-[20px]': isSimple,
                'md:min-w-[315px] mt-[45px]': !isSimple,
              })}
              type={cta.type === 'url' ? 'a' : 'link'}
              href={cta?.url}
              rel={cta.type === 'url' ? 'noreferrer' : ''}
              target={cta.target}
              size="lg"
              color={isSimple ? 'neutral-teal' : 'white'}
            >
              <span>{cta.text}</span>
            </AButton>
          )}
        </div>
        {image?.[0]?.url && (
          <>
            {isSimple ? (
              <div className="flex-1">
                {isImage ? (
                  <Image
                    src={image?.[0].url ?? ''}
                    alt={image?.[0].title ?? ''}
                    width={image?.[0].width ?? 0}
                    height={image?.[0].height ?? 0}
                  />
                ) : (
                  <video
                    className="w-full"
                    width="657"
                    height="452"
                    autoPlay
                    muted
                    loop
                    playsInline
                    aria-label={image?.[0].title ?? ''}
                  >
                    <source
                      src={image?.[0].url ?? ''}
                      type={image?.[0]?.mimeType}
                    />
                  </video>
                )}
              </div>
            ) : (
              <MImageWrapper
                size="md"
                type="accent"
                className={classNames('flex-1', {
                  'hidden md:block': isDark,
                })}
              >
                {isImage ? (
                  <Image
                    src={image?.[0].url ?? ''}
                    alt={image?.[0].title ?? ''}
                    width={image?.[0].width ?? 0}
                    height={image?.[0].height ?? 0}
                  />
                ) : (
                  <video
                    className="w-full"
                    width="657"
                    height="452"
                    autoPlay
                    muted
                    loop
                    playsInline
                    aria-label={image?.[0].title ?? ''}
                  >
                    <source
                      src={image?.[0].url ?? ''}
                      type={image?.[0]?.mimeType}
                    />
                  </video>
                )}
              </MImageWrapper>
            )}
          </>
        )}
        {cta?.url && (
          <AButton
            className={classNames('mt-[30px] normal-case md:hidden mx-auto', {
              'md:min-w-[210px]': isSimple,
              'md:min-w-[315px]': !isSimple,
              'w-full md:w-auto': isDark,
            })}
            type={cta.type === 'url' ? 'a' : 'link'}
            href={cta?.url}
            rel={cta.type === 'url' ? 'noreferrer' : ''}
            target={cta.target}
            size="lg"
            color={isSimple ? 'neutral-teal' : 'white'}
          >
            <span>{cta.text}</span>
          </AButton>
        )}
      </div>
    </div>
  )
}

const contentWithSideImage = `
  heading
  sectionContent
  image {
    url @transform(mode: "crop", width: 900, immediately: true)
    title
    width
    height
    mimeType
  }
  imageAligment
  bgColor
  cta {
    type
    url
    text
    target
  }
`
export const CContentWithSideImageFragment = {
  default: `
    fragment contentWithSideImage on contentWithSideImage_MatrixField {
    ...on contentWithSideImage_block_BlockType {
      ${contentWithSideImage}
    }
  }
  `,
  simple: `
    fragment simpleContentWithSideImage on simpleContentWithSideImage_MatrixField {
    ...on simpleContentWithSideImage_block_BlockType {
      ${contentWithSideImage}
    }
  }
  `,
}
