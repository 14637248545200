import { createClient, fetchExchange } from '@urql/core'
import { cacheExchange } from '@urql/exchange-graphcache'
import schema from './schema.json'

const defaultClients: {
  [localeHandle: string]: ReturnType<typeof createClient>
} = {}

const schemaString = JSON.stringify(schema) || '{}'

const defaultClient = (localeHandle: string = 'unitedStates') => {
  if (!defaultClients[localeHandle]) {
    defaultClients[localeHandle] = createClient({
      url: process.env.NEXT_PUBLIC_DISCOVERICL_GRAPHQL_URL ?? '',
      exchanges: [
        fetchExchange,
        cacheExchange({ schema: JSON.parse(schemaString) }),
      ],
    })
  }

  return defaultClients[localeHandle]
}

const doctorsClient = createClient({
  url: 'https://doctors.discovericl.com/api',
  exchanges: [fetchExchange],
})

export { defaultClient, doctorsClient }
