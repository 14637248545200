import { ASvg, CLocaleSwitcherBody } from 'ui'
import { useTranslation } from 'next-i18next'
import SvgStaarSurgicalLogo from 'ui/svg/logos/staar-surgical/teal-black.svg'
import './c-locale-switcher-screen.css'

export const CLocaleSwitcherScreen = () => {
  const { t } = useTranslation('common')

  /* eslint-disable @next/next/no-img-element */
  return (
    <div className="c-locale-switcher-screen px-5 pb-5 bg-primary-teal w-full min-h-screen flex flex-col items-center relative overflow-hidden">
      <div className="c-locale-switcher-screen__background absolute inset-0 pointer-events-none">
        <img
          className="c-locale-switcher-screen__background-image absolute w-full right-0 md:h-full object-cover object-bottom md:object-left opacity-50"
          src={`${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/locale-switcher-bg-image.png`}
          alt="Eye background"
        />
        <div className="c-locale-switcher-screen__background-gradient md:h-full absolute bg-gradient-to-t md:bg-gradient-to-r from-primary-teal to-transparent" />
        <img
          src={`${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/locale-switcher-bg-ellipses.svg`}
          className="c-locale-switcher-screen__background-ellipses absolute hidden md:block opacity-75"
          alt="Background shape"
        />
      </div>
      <ASvg
        className="c-locale-switcher-screen__logo my-5 fill-current text-neutral-white z-10"
        svg={SvgStaarSurgicalLogo}
        aria-label={`Staar Surgical ${t('logo')}`}
      />
      <CLocaleSwitcherBody
        domain={process.env.NEXT_PUBLIC_DISCOVERICL_BASE_DOMAIN ?? ''}
      />
    </div>
  )
}
