import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import useAllLocaleInfo from "doctors/hooks/use-all-locale-info";
import SvgSearchIcon from "ui/svg/icons/stroke/search.svg";
import SvgCloseIcon from "ui/svg/icons/stroke/close.svg";
import SvgStaarSurgicalLogo from "ui/svg/logos/staar-surgical/teal-black.svg";
import SvgEvoVisianIclLogo from "ui/svg/logos/evo-visian-icl/evo-icl-linear-black.svg";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { ASvg } from "../atoms/a-svg";
import { AButton } from "../atoms/a-button";
import { MModal } from "../molecules/m-modal";
import "./c-legacy-navigation-evo.css";
import { useSiteInfo } from "shared";

type Props = {
  components: any;
  transparent: boolean;
};

export const CLegacyNavigationEvo = ({ components, transparent }: Props) => {
  const { t } = useTranslation("common");
  const siteInfo = useSiteInfo();
  const allLocaleInfo = useAllLocaleInfo();

  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [regionActive, setRegionActive] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const menuItems = useMemo(() => {
    return components.navigation?.menu?.filter((item: any) => {
      return item.type === "text";
    });
  }, [components]);

  const normalizedLocales = useMemo(() => {
    return components.selectRegion?.blocks
      ?.map((item: any) => {
        type AllLocaleInfoKey = keyof typeof allLocaleInfo;
        return {
          label: item.label,
          subitems: item.languages
            ?.map((lang: string) => allLocaleInfo[lang as AllLocaleInfoKey])
            .filter((v: any) => v),
        };
      })
      .filter((item: any) => item.subitems?.length);
  }, [components]);

  const handleToggleSearch = () => {
    setSearchActive(!searchActive);
  };

  useEffect(() => {
    if (dropdownRef.current) {
      if (dropdownActive) {
        disableBodyScroll(dropdownRef.current);
      } else {
        enableBodyScroll(dropdownRef.current);
      }
    }
  }, [dropdownActive]);

  useEffect(() => {
    window.addEventListener("showEvoRegionModal", () => {
      setRegionActive(true);
    });
  }, []);

  /* eslint-disable @next/next/no-img-element */
  return (
    <div
      className={classNames(
        "h-[64px] xl:h-[128px] fixed top-0 left-0 z-40 flex flex-col w-full transition-colors duration-200 ease-out",
        {
          "bg-white shadow": !transparent && !dropdownActive,
          "bg-black": dropdownActive,
        }
      )}
    >
      <div className="h-8 hidden xl:flex items-center justify-end flex-shrink-0 bg-neutral-black/[.1]">
        <button
          className={classNames(
            "flex items-center font-body text-xs font-bold uppercase mr-12 tracking-wider",
            {
              "text-gray-800": !transparent,
              "text-neutral-white": transparent,
            }
          )}
          title={t("change-region") ?? ""}
          onClick={() => setRegionActive(true)}
        >
          <div>{t("change-region")}</div>
          <div className="w-[1px] h-[18px] mx-4 bg-gray-400" />
          <img
            className="mr-2"
            src={`${siteInfo?.discoverUrl}/static/regions/flag-${siteInfo?.iso
              .slice(-2)
              .toLowerCase()}.png`}
            alt={siteInfo?.iso.slice(-2)}
          />
          <div>{siteInfo?.iso.slice(-2)}</div>
        </button>
      </div>
      <div className="flex-grow flex items-center relative">
        <div className="px-6 flex justify-between w-full items-center">
          <a
            href={`${
              siteInfo?.discoverUrl
            }${components.navigation?.logo?.url?.replace(/^\//, "")}`}
            title={components.navigation?.logo?.text}
          >
            {components.navigation?.logo?.icon === "static/logos/staar.svg" && (
              <ASvg
                className={classNames(
                  "logo w-[127px] h-[38px] lg:w-[183px] lg:h-[55px]",
                  {
                    "fill-current text-neutral-white":
                      transparent || dropdownActive,
                  }
                )}
                svg={SvgStaarSurgicalLogo}
              />
            )}
            {components.navigation?.logo?.icon === "static/logos/evo.svg" && (
              <ASvg
                className={classNames(
                  "logo w-[127px] h-[38px] lg:w-[183px] lg:h-[55px]",
                  {
                    "fill-current text-neutral-white":
                      transparent || dropdownActive,
                  }
                )}
                svg={SvgEvoVisianIclLogo}
              />
            )}
          </a>
          <div className="hidden xl:flex items-center">
            {menuItems?.map((item: any, n: number) => {
              return (
                <a
                  key={n}
                  href={`${siteInfo?.discoverUrl}${item.url?.replace(
                    /^\//,
                    ""
                  )}`}
                  className={classNames(
                    "tracking-[0.82px] font-body font-bold tracking-wider uppercase text-center",
                    {
                      "text-gray-900 hover:text-gray-800": !transparent,
                      "text-white": transparent,
                      "text-xs mx-3": components.navigation?.textSize === "sm",
                      "text-sm mx-4": components.navigation?.textSize !== "sm",
                    }
                  )}
                >
                  {item.text}
                </a>
              );
            })}
            <button title={t("search") ?? ""} onClick={handleToggleSearch}>
              <ASvg
                svg={SvgSearchIcon}
                className={classNames("w-8 h-8 stroke-current mx-4", {
                  "text-pink-500": !transparent,
                  "text-neutral-white": transparent,
                })}
              />
            </button>
            <AButton
              className="ml-4"
              style={transparent ? "outline" : "solid"}
              color={transparent ? "white" : "pink"}
              size="lg"
              title={t("find-a-doctor") ?? ""}
            >
              <span>{t("find-a-doctor")}</span>
            </AButton>
          </div>
          <div className="xl:hidden flex">
            <button
              className="w-12 h-12 flex items-center justify-center"
              title={t("search") ?? ""}
              onClick={handleToggleSearch}
            >
              <ASvg
                className={classNames(
                  "w-6 h-6 stroke-current transition-colors duration-100 ease-out",
                  {
                    "text-gray-900": !transparent && !dropdownActive,
                    "text-neutral-white": transparent || dropdownActive,
                  }
                )}
                svg={SvgSearchIcon}
              />
            </button>
            <button
              className="w-12 h-12 flex items-center justify-center"
              type="button"
              title={
                dropdownActive ? t("close-menu") ?? "" : t("open-menu") ?? ""
              }
              onClick={() => setDropdownActive(!dropdownActive)}
            >
              <div
                className={classNames("w-[30px] h-[20px] relative", {
                  active: dropdownActive,
                })}
              >
                <div
                  className={classNames(
                    "c-legacy-navigation-evo__bar c-legacy-navigation-evo__bar--top w-full absolute transition-all duration-100 ease-out",
                    {
                      "bg-gray-900": !dropdownActive && !transparent,
                      "bg-neutral-white": dropdownActive || transparent,
                    }
                  )}
                />
                <div
                  className={classNames(
                    "c-legacy-navigation-evo__bar c-legacy-navigation-evo__bar--middle w-full absolute transition-all duration-100 ease-out",
                    {
                      "bg-gray-900": !dropdownActive && !transparent,
                      "bg-neutral-white": dropdownActive || transparent,
                    }
                  )}
                />
                <div
                  className={classNames(
                    "c-legacy-navigation-evo__bar c-legacy-navigation-evo__bar--bottom w-full absolute transition-all duration-100 ease-out",
                    {
                      "bg-gray-900": !dropdownActive && !transparent,
                      "bg-neutral-white": dropdownActive || transparent,
                    }
                  )}
                />
              </div>
            </button>
          </div>
          <form
            method="GET"
            action={`${
              siteInfo?.discoverUrl
            }${components.navigation?.searchUrl?.replace(/^\//, "")}`}
            className={classNames(
              "absolute w-full h-full bg-white top-0 left-0 flex items-center justify-center opacity-0 transition-opacity duration-200 ease-out px-6",
              {
                "pointer-events-none": !searchActive,
                "opacity-100": searchActive,
              }
            )}
          >
            <button
              className="w-8 h-8 flex items-center justify-center"
              type="submit"
              title={t("search") ?? ""}
            >
              <ASvg
                className="w-8 h-8 stroke-current text-gray-900"
                svg={SvgSearchIcon}
              />
            </button>
            <input
              className="search-input outline-none mx-3 p-2 font-body font-base font-semibold text-gray-900 w-full max-w-xl border-b border-gray-900"
              name="q"
              placeholder={t("search-for-something") ?? ""}
              aria-label={t("search-for-something") ?? ""}
              required
            />
            <button
              className="w-8 h-8 flex items-center justify-center"
              type="button"
              title={t("close") ?? ""}
              onClick={handleToggleSearch}
            >
              <ASvg
                className="w-8 h-8 stroke-current text-gray-900"
                svg={SvgCloseIcon}
              />
            </button>
          </form>
        </div>
        <div
          ref={dropdownRef}
          className={classNames(
            "h-[calc(100vh-128px)] top-16 lg:h-[100vh-192px] lg:top-32 xl:hidden flex flex-col items-center bg-black fixed right-0 w-full transform transition-all duration-200 opacity-0 ease-out px-6 overflow-y-auto",
            {
              "translate-x-0 opacity-100": dropdownActive,
              "translate-x-full": !dropdownActive,
            }
          )}
        >
          <ul className="flex flex-col items-center">
            {menuItems?.map((item: any, n: number) => {
              return (
                <li key={n} className="flex flex-col items-center">
                  <a
                    href={`${siteInfo?.discoverUrl}${item.url?.replace(
                      /^\//,
                      ""
                    )}`}
                    className="text-white text-3xl font-body font-light mt-6 text-center"
                  >
                    {item.text}
                  </a>
                </li>
              );
            })}
          </ul>
          <AButton
            title={t("find-a-doctor") ?? ""}
            className="my-6 w-full max-w-md"
            color="pink"
            size="lg"
          >
            <span>{t("find-a-doctor")}</span>
          </AButton>
        </div>
        <div
          className={classNames(
            "h-[64px] bg-black fixed bottom-0 left-0 w-full transform transition-all duration-200 ease-out",
            {
              "translate-y-0 opacity-100": dropdownActive,
              "translate-y-full": !dropdownActive,
            }
          )}
        >
          <button
            className="h-8 w-full bg-gray-900 flex items-center justify-center uppercase font-body text-xs font-bold tracking-wider text-white"
            title={t("change-region") ?? ""}
            onClick={() => setRegionActive(true)}
          >
            <div>{t("change-region")}</div>
            <div className="region-spacer mx-4 bg-gray-400" />
            <img
              className="mr-2"
              src={`${siteInfo?.discoverUrl}/static/regions/flag-${siteInfo?.iso
                .slice(-2)
                .toLowerCase()}.png`}
              alt={siteInfo?.iso.slice(-2)}
            />
            <div>{siteInfo?.iso.slice(-2)}</div>
          </button>
        </div>
        <MModal open={regionActive} onClose={() => setRegionActive(false)}>
          <>
            <h2 className="text-4xl font-body text-gray-900 font-light text-center">
              {t("select-your-region")}
            </h2>
            {normalizedLocales?.map((block: any, n: number) => {
              return (
                <div
                  key={n}
                  className="mt-10 flex flex-col items-center font-body text-base font-semibold uppercase"
                >
                  <h3 className="text-gray-400">{block.label}</h3>
                  {block.subitems?.map((lang: any, m: number) => {
                    return (
                      <a
                        key={m}
                        href={lang.hostUrl}
                        className="mt-5 text-gray-900 hover:text-gray-800 transition-colors duration-100 ease-out"
                      >
                        {lang.name}
                      </a>
                    );
                  })}
                </div>
              );
            })}
          </>
        </MModal>
      </div>
    </div>
  );
};
