import classNames from "classnames";
import { useEffect, useRef } from "react";
import { ASvg } from "ui/components/atoms/a-svg";
import SvgCloseIcon from "ui/svg/icons/stroke/close.svg";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import "./m-modal.css";

type Props = {
  onClose?: Function;
  children: JSX.Element;
  open: boolean;
  className?: string;
  size?: string;
};

export const MModal = ({ children, onClose, open, size, className }: Props) => {
  const modalBody = useRef<HTMLDivElement>(null);

  const close = () => {
    if (onClose instanceof Function) {
      onClose();
    }
    if (modalBody.current) {
      enableBodyScroll(modalBody.current);
    }
  };

  useEffect(() => {
    if (open && modalBody.current) {
      disableBodyScroll(modalBody.current);
    }
  }, [open]);

  return (
    <div
      className={classNames(className, "m-modal relative", {
        "m-modal--open": open,
      })}
    >
      <div
        className="m-modal__dim z-80 fixed top-0 left-0 w-full h-screen"
        onClick={close}
      />
      <div
        ref={modalBody}
        className={classNames(
          "m-modal__modal z-80 fixed w-full bg-neutral-white p-12 max-h-screen overflow-y-auto",
          {
            "m-modal__modal--large": size === "lg",
          }
        )}
      >
        {onClose && (
          <button className="absolute top-2 inline-end-2" onClick={close}>
            <ASvg className="stroke-current w-8 h-8" svg={SvgCloseIcon} />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};
