import classNames from "classnames";
import useSettings from "doctors/hooks/use-settings";
import { useTranslation } from "next-i18next";
import { MouseEvent, useMemo, useState } from "react";
import { MLegacyBurgerIcl } from "../molecules/m-legacy-burger-icl";
import { MModal } from "../molecules/m-modal";
import "./c-legacy-menu-icl.css";
import { useSiteInfo } from "shared";

type Props = {
  components: any;
  active: boolean;
  onClose?: () => void;
  onRegionChange?: () => void;
};

export const CLegacyMenuIcl = ({
  components,
  active,
  onClose,
  onRegionChange,
}: Props) => {
  const siteInfo = useSiteInfo();
  const settings = useSettings();
  const { t } = useTranslation("common");

  const country = useMemo(() => {
    return (
      siteInfo?.iso.slice(3) ||
      (settings.country?.length ? settings.country[0] : "")
    ).toLowerCase();
  }, [siteInfo, settings]);

  const normalizedDropdownColumns = useMemo(() => {
    const items = components.index?.menu;
    return [items.slice(0, 1), items.slice(1, 2), items.slice(2)];
  }, [components]);

  const [activeSubmenus, setActiveSubmenus] = useState<Array<boolean>>(
    Array(components.index?.menu?.length ?? 0).fill(false)
  );
  const [activeSubmenuMoreLinks, setActiveSubmenuMoreLinks] =
    useState<boolean>(false);

  const handleChangeRegion = () => {
    if (onRegionChange instanceof Function) {
      onRegionChange();
    }
  };

  const handleClickLink = (e: MouseEvent, index: number) => {
    if (components.index.menu[index].submenu.length && !activeSubmenus[index]) {
      e.preventDefault();
    }
    setActiveSubmenus(
      activeSubmenus.map((item, i) => {
        return i === index ? true : item;
      })
    );
  };

  const handleClose = () => {
    setActiveSubmenus(
      activeSubmenus.map(() => {
        return false;
      })
    );

    setActiveSubmenuMoreLinks(false);

    if (onClose instanceof Function) {
      onClose();
    }
  };

  const handleClickLinkMoreLinks = (e: MouseEvent) => {
    if (components.index?.moreLinks?.length && activeSubmenuMoreLinks) {
      e.preventDefault();
    }
    setActiveSubmenuMoreLinks(true);
  };

  /* eslint-disable @next/next/no-img-element */
  return (
    <nav className="c-legacy-menu-icl">
      <div
        className={classNames(
          "c-legacy-menu-icl__content c-legacy-menu-icl__content--mobile fixed top-0 right-0 bottom-0 left-0 overflow-auto md:hidden transition-all duration-500",
          {
            "opacity-0 invisible": !active,
          }
        )}
      >
        <div className="c-legacy-menu-icl__bar flex items-center justify-between px-5 py-4">
          <button
            className="flex items-center font-body text-xs font-bold uppercase tracking-wider text-neutral-white"
            title={t("change-region") ?? ""}
            onClick={handleChangeRegion}
          >
            <img
              className="h-3 mx-2"
              src={`${siteInfo?.discoverUrl}/assets/img/flags/${country}.png`}
              alt={country}
            />
            <div>{country}</div>
          </button>
          <MLegacyBurgerIcl
            className="mx-2 flex-shrink-0"
            color="white"
            active={true}
            onClick={handleClose}
          />
        </div>
        <ul className="c-legacy-menu-icl__list">
          <li className="c-legacy-menu-icl__item c-legacy-menu-icl__item--primary border-t border-neutral-white bg-primary">
            <a
              className="c-legacy-menu-icl__link block py-6 font-body font-bold text-neutral-white uppercase text-center"
              href="/"
            >
              {t("find-a-doctor")}
            </a>
          </li>
          {components.index?.menu?.map((item: any, index: number) => {
            return (
              <li
                key={item.id}
                className="c-legacy-menu-icl__item border-t border-neutral-white"
              >
                <a
                  className="c-legacy-menu-icl__link block py-6 font-body font-bold text-neutral-white uppercase text-center"
                  href={item.url}
                  onClick={(e) => handleClickLink(e, index)}
                >
                  {item.title}
                </a>
                {!!item.submenu?.length && activeSubmenus[index] && (
                  <ul className="c-legacy-menu-icl__sublist">
                    {item.submenu?.map((subitem: any, subindex: number) => {
                      return (
                        <li
                          key={`${item.id}-${subindex}`}
                          className="c-legacy-menu-icl__subitem"
                        >
                          <a
                            className="c-legacy-menu-icl__sublink block py-6 font-body font-bold text-neutral-white uppercase text-center"
                            href={subitem.url}
                          >
                            {subitem.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
          <li className="c-legacy-menu-icl__item border-t border-neutral-white">
            <a
              className="c-legacy-menu-icl__link block py-6 font-body font-bold text-neutral-white uppercase text-center"
              tabIndex={0}
              onClick={(e) => handleClickLinkMoreLinks(e)}
            >
              {components.index?.moreLinksHeading}
            </a>
            {!!components.index?.moreLinks?.length &&
              activeSubmenuMoreLinks && (
                <ul className="c-legacy-menu-icl__sublist">
                  {components.index?.moreLinks?.map((subitem: any) => {
                    return (
                      <li
                        key={subitem.id}
                        className="c-legacy-menu-icl__subitem"
                      >
                        <a
                          className="c-legacy-menu-icl__sublink block py-6 font-body font-bold text-neutral-white uppercase text-center"
                          href={subitem.url}
                        >
                          {subitem.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
          </li>
        </ul>
        <ul className="c-legacy-menu-icl__socials-list flex justify-center p-5">
          {/* <li
            :key="key"
            class="c-menu-icl__socials-item"
            :class="[index > 0 ? 'ml-10' : '']"
          >
            <a
              class="c-menu-icl__socials-link"
              :href="item"
            >
              <Icon
                class="c-menu-icl__socials-icon w-8 fill-current"
                :icon="key"
              />
            </a>
          </li> */}
        </ul>
      </div>
      <MModal
        className="hidden md:flex"
        size="lg"
        open={active}
        onClose={handleClose}
      >
        <>
          <h2 className="c-legacy-menu-icl__dropdown-heading text-4xl font-light font-body text-center">
            {components.index?.heading}
          </h2>
          <div className="c-legacy-menu-icl__dropdown-menus flex mt-12 pb-6 px-5 w-full">
            {normalizedDropdownColumns.map((column, n) => {
              return (
                <div key={n} className="c-legacy-menu-icl__dropdown-column">
                  {column.map((menu: any) => {
                    return (
                      <div
                        key={menu.id}
                        className="c-legacy-menu-icl__dropdown-menu flex flex-col"
                      >
                        <a
                          href={menu.url}
                          className="c-legacy-menu-icl__dropdown-menu-item block relative text-primary-teal hover:text-primary-bright-teal font-bold font-body tracking-wider uppercase transition-colors duration-200 ease-out"
                        >
                          {menu.title}
                        </a>
                        {menu.submenu?.map((submenu: any, m: number) => {
                          return (
                            <a
                              key={m}
                              href={submenu.url}
                              className="c-legacy-menu-icl__dropdown-submenu-item block uppercase tracking-wider transition-colors duration-200 ease-out font-bold"
                            >
                              {submenu.title}
                            </a>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="pt-6">
            <h3 className="c-legacy-menu-icl__dropdown-more-links-heading font-body text-base font-semibold">
              {components.index?.moreLinksHeading}
            </h3>
            <div className="c-legacy-menu-icl__dropdown-more-links mt-3">
              {components.index?.moreLinks?.map((link: any) => {
                return (
                  <a
                    key={link.id}
                    className="block c-legacy-menu-icl__dropdown-submenu-item uppercase tracking-wider transition-colors duration-200 ease-out font-bold"
                    href={link.url}
                  >
                    {link.title}
                  </a>
                );
              })}
            </div>
          </div>
        </>
      </MModal>
    </nav>
  );
};
