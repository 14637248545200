import classNames from 'classnames'
import { throttle } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftAssetField } from 'tsconfig/craft-types'
import { APicture } from 'ui'
import './c-image-section-break.css'

export type CImageSectionBreakBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  desktopImage?: Array<CraftAssetField>
  mobileImage?: Array<CraftAssetField>
}

type Props = {
  field: Array<CImageSectionBreakBlock>
}

export const CImageSectionBreak = ({ field }: Props) => {
  const [animateDown, setAnimateDown] = useState(false)
  const sectionRef = useRef<HTMLDivElement>(null)

  const handleWindowScroll = () => {
    setAnimateDown(
      sectionRef.current
        ? sectionRef.current.getBoundingClientRect().top <=
            window.innerHeight * 0.5
        : false
    )
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  useEffect(() => {
    if (content) {
      window.addEventListener('scroll', handleWindowScrollThrottled)
      handleWindowScroll()
    }

    return () => {
      if (content) {
        window.removeEventListener('scroll', handleWindowScrollThrottled)
      }
    }
  }, [content])

  if (!content) {
    return <></>
  }

  return (
    <div
      ref={sectionRef}
      id={content?.idName}
      className={classNames(
        'c-image-section-break m-3 md:m-6 overflow-hidden',
        {
          'c-image-section-break--animate': animateDown,
        }
      )}
    >
      <APicture
        className="c-image-section-break__image w-full object-cover"
        imageDesktop={content?.desktopImage && content.desktopImage[0]}
        imageMobile={content?.mobileImage && content.mobileImage[0]}
      />
    </div>
  )
}

export const CImageSectionBreakFragments = {
  field: `
    fragment imageSectionBreak on imageSectionBreak_MatrixField {
      ... on imageSectionBreak_block_BlockType {
        id
        typeHandle
        idName
        desktopImage {
          url @transform(mode: "crop", width: 2000 immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        mobileImage {
          url @transform(mode: "crop", width: 640, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
      }
    }
    `,
}
