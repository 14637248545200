import { ReactElement } from 'react'
import './m-image-wrapper.css'

interface MImageWrapperProps {
  children: ReactElement
  className?: string
  size?: 'sm' | 'md'
  type?: 'accent' | 'white'
}
export const MImageWrapper = ({
  children,
  className,
  size,
  type,
}: MImageWrapperProps) => {
  return (
    <div
      className={`m-image-wrapper m-image-wrapper__${
        size ?? 'sm'
      } m-image-wrapper__type-${type ?? 'white'} ${className}`}
    >
      {children}
    </div>
  )
}
