type Props = {
  copy: string
}

export const CCtaBoxLegacy = ({ copy }: Props) => {
  if (!copy) {
    return <></>
  }

  return (
    <div className="c-cta-box-legacy h-[354px] m-3 md:m-6 bg-primary-teal text-neutral-white p-4 flex items-center justify-center">
      <div
        className="c-cta-box-legacy__copy max-w-[808px] text-center s-wysiwyg text-p-lg md:text-h6"
        dangerouslySetInnerHTML={{ __html: copy }}
      />
    </div>
  )
}
