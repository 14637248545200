import { useMemo } from 'react'
import { CraftLinkItField } from 'tsconfig/craft-types'
import SvgStaarIconLogo from 'ui/svg/logos/staar-icon/teal.svg'
import { ASvg } from 'ui/components/atoms/a-svg'
import { AButton } from 'ui/components/atoms/a-button'
import './c-stats.css'
import { normalizeHref } from 'discovericl/helpers/normalize-href'
import classNames from 'classnames'

export type CStatsBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  badgeCopy?: string
  badgeHyperlink?: CraftLinkItField
  number?: string
  text?: string
  copy?: string
  button?: CraftLinkItField
}

type Props = {
  field: Array<CStatsBlock>
  small?: boolean
}

export const CStats = ({ field, small }: Props) => {
  const content = useMemo(() => {
    if (field && field.length) {
      return {
        ...field.find((block) => block.typeHandle === 'main'),
        stats: field.filter((block) => block.typeHandle === 'stat'),
      }
    } else {
      return null
    }
  }, [field])

  if (!content) {
    return null
  }

  return (
    <section
      id={content?.idName}
      className={classNames('c-stats bg-primary-teal', {
        'm-3 md:m-6 ': !small,
        'md:p-18': small && content != null && content.stats.length < 3,
      })}
    >
      <div
        className={classNames(
          'flex flex-col justify-center w-full h-full mx-auto lg:items-center lg:justify-start lg:flex-wrap lg:flex-row lg:content-center',
          {
            'c-stats__container': !small,
          }
        )}
      >
        {content?.stats.map((stat) => {
          return (
            <section
              key={stat.id}
              className={classNames('mx-3 text-center', {
                'c-stats__stat lg:text-start border-opacity-40 border-neutral-white':
                  !small,
                'flex-1': small,
                'py-12 md:mx-6 lg:px-12 lg:py-16 xl:px-24 xxl:px-32 lg:w-1/2 lg:mx-0 lg:my-10 ':
                  (small && content != null && content.stats.length < 3) ||
                  !small,
              })}
            >
              {stat.number && (
                <h2 className="c-stats__number text-neutral-white">
                  {stat.number}
                </h2>
              )}
              {stat.text && (
                <p className="text-h6 text-neutral-white">{stat.text}</p>
              )}
              {stat.copy && (
                <p
                  className="my-2 text-lg text-neutral-white"
                  dangerouslySetInnerHTML={{ __html: stat.copy }}
                ></p>
              )}
              {stat.button?.url && (
                <AButton
                  className="mt-8 c-stats__button lg:mt-14"
                  size="lg"
                  target={stat.button.target}
                  href={stat.button.url}
                />
              )}
              {stat.badgeCopy && (
                <AButton
                  type={stat.badgeHyperlink?.type === 'url' ? 'a' : 'link'}
                  target={stat.badgeHyperlink?.target}
                  href={normalizeHref(stat.badgeHyperlink?.url ?? '')}
                >
                  <div className="flex items-center w-full p-2 mx-auto mt-10 text-start rounded-full lg:mx-0 c-stats__badge bg-neutral-white">
                    <div className="flex items-center justify-center flex-shrink-0 rounded-full w-18 h-18 bg-neutral-dark-gray">
                      <ASvg
                        className="w-12 h-12 fill-current text-neutral-white"
                        svg={SvgStaarIconLogo}
                        ariaHidden="true"
                      />
                    </div>
                    <div
                      className="pis-4 pie-6 text-p-sm text-primary-gray s-wysiwyg"
                      dangerouslySetInnerHTML={{ __html: stat.badgeCopy }}
                    />
                  </div>
                </AButton>
              )}
            </section>
          )
        })}
        {content?.badgeCopy && (
          <div className="w-full px-3 pb-10 md:px-6 lg:px-12 xl:px-24 xxl:px-32 c-stats__main">
            <a
              target={content.badgeHyperlink?.target}
              href={content.badgeHyperlink?.url}
              className="flex items-center w-full p-2 mx-auto text-start rounded-full c-stats__badge bg-neutral-white"
            >
              <div className="flex items-center justify-center flex-shrink-0 rounded-full w-18 h-18 bg-neutral-dark-gray">
                <ASvg
                  className="w-12 h-12 fill-current text-neutral-white"
                  svg={SvgStaarIconLogo}
                  ariaHidden="true"
                />
              </div>
              <div
                className="pis-4 pie-6 text-p-sm text-primary-gray s-wysiwyg"
                dangerouslySetInnerHTML={{ __html: content.badgeCopy }}
              />
            </a>
          </div>
        )}
      </div>
    </section>
  )
}

export const CStatsFragments = {
  field: `
    fragment stats on stats_MatrixField {
      ... on stats_main_BlockType {
        id
        typeHandle
        idName
        badgeCopy
        badgeHyperlink {
          type
          url
          text
          target
        }
      }
      ... on stats_stat_BlockType {
        id
        typeHandle
        number
        text
        button {
          type
          url
          text
          target
        }
        badgeCopy
        badgeHyperlink {
          type
          url
          text
          target
        }
      }
    }
  `,
}
