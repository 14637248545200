import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useContext, useMemo } from "react";
import SvgEvoVisianIclLogo from "ui/svg/logos/evo-visian-icl/evo-icl-linear-black.svg";
import SvgStaarSurgicalLogo from "ui/svg/logos/staar-surgical/teal-black.svg";
import SvgVisianIclLogo from "ui/svg/logos/visian-icl/teal-black.svg";
import SvgTimeIcon from "ui/svg/icons/fill/time.svg";
import Image from "next/image";
import * as CONST from "ui/constants/index";
import { AButton } from "../atoms/a-button";
import { ASvg } from "../atoms/a-svg";
import { HeaderContext } from "../../context/header";
import { CLocaleSwitcher } from "./c-locale-switcher";
import "./c-500.css";
import { useSiteInfo } from "shared";

export const C500 = () => {
  const { t } = useTranslation("common");
  const siteInfo = useSiteInfo();

  const headerContext = useContext(HeaderContext);

  const countryCode = useMemo(() => {
    switch (siteInfo.iso) {
      case "ar":
        return "ar";
      case "es":
        return "la";
      case "nl":
        return "be";
      case "en":
        return "ea";
      case "zh-Hans":
        return "us";
      default:
        return siteInfo?.iso?.slice(3);
    }
  }, [siteInfo]);

  /* eslint-disable @next/next/no-img-element */
  return (
    <section className="c-500 w-full h-screen flex flex-col">
      <div className="c-500__nav flex-shrink-0">
        <div className="bg-[#242424] text-[#817c7d] h-[34px] items-center justify-end hidden px-5 md:flex xl:px-10">
          {t("paths.safety-information") && (
            <Link
              className="font-bold tracking-wider uppercase transition duration-300 text-p-sm hover:text-neutral-gray"
              href={t("paths.safety-information") ?? ""}
              prefetch={false}
            >
              {t("titles.safety-information")}
            </Link>
          )}
          <div className="mx-4 bg-[#817c7d] w-[1px] h-[18px]" />
          <button
            title="Change region"
            className="flex items-center font-bold tracking-wider uppercase transition duration-300 text-p-sm hover:text-neutral-gray"
            onClick={() => headerContext?.setShowLocaleSwitcher(true)}
          >
            <Image
              className="mx-2"
              src={`${
                process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL
              }img/flags/${countryCode?.toLowerCase()}.png`}
              aria-hidden="true"
              alt={countryCode ?? ""}
              width={23}
              height={12}
            />
            <span className="uppercase">{countryCode}</span>
          </button>
        </div>
        <div className="c-500__logo-bar flex items-center justify-center">
          {siteInfo.branded === CONST.BRANDED.VISIAN_ICL && (
            <ASvg
              className="c-500__logo"
              svg={SvgVisianIclLogo}
              ariaLabel={`Visian ICL ${t("logo")}`}
            />
          )}
          {siteInfo.branded === CONST.BRANDED.STAAR_SURGICAL && (
            <ASvg
              className="c-500__logo"
              svg={SvgStaarSurgicalLogo}
              ariaLabel={`Staar Surgical ${t("logo")}`}
            />
          )}
          {siteInfo.branded === CONST.BRANDED.EVO_VISIAN_ICL && (
            <ASvg
              className="c-500__logo"
              svg={SvgEvoVisianIclLogo}
              ariaLabel={`EVO Visian ${t("logo")}`}
            />
          )}
        </div>
      </div>
      <div className="c-500__body flex-grow bg-cover">
        <div className="o-container o-container--xl h-full flex items-center">
          <div className="c-500__content w-full flex flex-col items-center md:items-start">
            <h1 className="text-h7 md:text-h3 text-white font-light text-center md:text-left">
              {t("500-line1")}
            </h1>
            <p className="text-p text-white mt-11 flex flex-col md:flex-row items-center text-center md:text-left">
              <ASvg className="fill-current w-6 h-6 mr-2" svg={SvgTimeIcon} />
              <span className="mt-2 md:mt-0">{t("500-line2")}</span>
            </p>
            <AButton
              type="a"
              href="https://www.youtube.com/c/EVOVisianICL"
              className="a-button a-button--size_lg bg-white text-button uppercase py-3 font-bold mt-14 hover:bg-primary-teal hover:text-white transition-colors duration-200 ease-out"
              style="solid"
              color="white"
            >
              <span>{t("500-button")}</span>
            </AButton>
          </div>
        </div>
      </div>
      <CLocaleSwitcher
        open={headerContext?.showLocaleSwitcher ?? false}
        onClose={() => headerContext?.setShowLocaleSwitcher(false)}
        domain={process.env.NEXT_PUBLIC_DISCOVERICL_BASE_DOMAIN ?? ""}
      />
    </section>
  );
};
