import classNames from 'classnames'
import { ASvg } from 'ui'
import SvgCloseIcon from 'ui/svg/icons/stroke/close.svg'
import { useMemo, useContext, useEffect, useRef, MouseEvent } from 'react'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { HeaderContext } from '../../context/header'

export type CReferencesModalBlock = {
  id?: string
  typeHandle?: string
  heading?: string
  copy?: string
}

type Props = {
  field?: Array<CReferencesModalBlock>
}

const defaultProps = {
  open: false,
}

const CReferencesModal = ({ field }: Props) => {
  const headerContext = useContext(HeaderContext)

  const modalRef = useRef<HTMLDivElement>(null)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const close = () => {
    headerContext?.setShowReferencesModal(false)
    if (modalRef.current) {
      enableBodyScroll(modalRef.current)
    }
  }

  const handleBodyClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (modalRef.current && headerContext?.showReferencesModal) {
      disableBodyScroll(modalRef.current)
    }
  }, [headerContext?.showReferencesModal])

  if (!content) {
    return <></>
  }

  return (
    <section
      className={classNames(
        'transition-opacity duration-300 ease-out c-references-modal z-80',
        {
          'opacity-0 pointer-events-none': !headerContext?.showReferencesModal,
        }
      )}
      ref={modalRef}
    >
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-75 bg-neutral-black"
        onClick={close}
      >
        <div
          className="max-w-5xl max-h-screen px-6 overflow-hidden bg-neutral-white md:px-28 py-14 md:py-28 flex flex-col"
          onClick={handleBodyClick}
        >
          <div className="relative">
            <h3 className="mb-6 md:mb-16 text-neutral-black text-h5 md:text-h2 md:text-center">
              {content?.heading}
            </h3>
            <button
              className="absolute top-0 mt-2 inline-end-0 md:mt-6"
              onClick={close}
            >
              <ASvg
                className="stroke-current text-primary-teal w-8 h-8"
                svg={SvgCloseIcon}
              />
            </button>
          </div>
          <div
            className="s-wysiwyg text-primary-gray text-p-sm md:text-p overflow-y-auto h-full"
            dangerouslySetInnerHTML={{ __html: content?.copy ?? '' }}
          />
        </div>
      </div>
    </section>
  )
}

CReferencesModal.defaultProps = defaultProps

export { CReferencesModal }

export const CReferencesModalFragments = {
  field: `
    fragment referencesModal on referencesModal_MatrixField {
      ... on referencesModal_block_BlockType {
        id
        typeHandle
        heading
        copy
      }
    }
  `,
}
