import { useState, useRef } from 'react'

export default function useStateRef(initialValue: any) {
  const [value, _setValue] = useState(initialValue)

  const ref = useRef(value)

  const setValue = (data: any) => {
    ref.current = data
    _setValue(data)
  }

  return [value, setValue, ref]
}
