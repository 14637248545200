import classNames from "classnames";
import useSettings from "doctors/hooks/use-settings";
import SvgSearchIcon from "ui/svg/icons/stroke/search.svg";
import { useTranslation } from "next-i18next";
import { useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { useSiteInfo } from "shared";
import { AButton } from "../atoms/a-button";
import { MLegacyBurgerIcl } from "../molecules/m-legacy-burger-icl";
import { CLegacyMenuIcl } from "./c-legacy-menu-icl";
import { CLegacyRegionModalIcl } from "./c-legacy-region-modal-icl";

type Props = {
  components: any
  transparent: boolean
}

export const CLegacyNavigationIcl = ({ components, transparent }: Props) => {
  const siteInfo = useSiteInfo()
  const settings = useSettings()
  const { t } = useTranslation('common')

  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [modalRegionActive, setModalRegionActive] = useState<boolean>(false)

  const country = useMemo(() => {
    switch (siteInfo?.iso) {
      case 'ar':
        return 'ar'
      case 'es':
        return 'la'
      case 'nl':
        return 'be'
      case 'en':
        return 'ea'
      case 'zh-Hans':
        return 'us'
      case 'en-GB':
        return 'UK'
      default:
        return (
          siteInfo?.iso?.slice(3) ||
          (settings.country?.length ? settings.country[0] : '')
        )
    }
  }, [siteInfo, settings])

  const countryFlagCode = useMemo(() => {
    switch (siteInfo?.iso) {
      case 'ar':
        return 'ar'
      case 'es':
        return 'la'
      case 'nl':
        return 'be'
      case 'en':
        return 'ea'
      case 'zh-Hans':
        return 'us'
      default:
        return (
          siteInfo?.iso?.slice(3) ||
          (settings.country?.length ? settings.country[0] : '')
        )
    }
  }, [siteInfo, settings])

  const navigationButtons = useMemo(() => {
    return components.navigation?.menu?.filter((item: any) => {
      return item.type === 'button'
    })
  }, [components])

  const navigationLinks = useMemo(() => {
    return components.navigation?.menu?.filter((item: any) => {
      return item.type === 'text'
    })
  }, [components])

  const handleOpenModalRegion = () => {
    setModalRegionActive(true)
  }

  const handleCloseModalRegion = () => {
    setModalRegionActive(false)
  }

  const handleButtonClick = () => {
    if (window.analytics && siteInfo?.iso === 'en-US') {
      window.analytics.track('Take The Vision Quiz', {})
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'Quiz Start',
        placement: 'doctors_website',
      },
    })
  }

  const handleMenuRegionChange = () => {
    setModalRegionActive(true)
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <div
      className={classNames(
        'navigation fixed top-0 left-0 z-40 flex flex-col w-full transition-colors duration-200 ease-out',
        {
          'bg-white shadow': !transparent,
        }
      )}
    >
      <div className="bg-[#242424] hidden md:flex items-center justify-end flex-shrink-0 px-10 py-2 text-gray-700">
        {components.navigation?.safety && (
          <a
            href={components.safety?.url}
            className="font-body text-xs font-bold uppercase tracking-wider"
          >
            {components.safety?.heading || components.safety?.title}
          </a>
        )}
        {components.safaty && (
          <div className="w-[1px] h-[18px] mx-4 bg-gray-700" />
        )}
        <button
          className="flex items-center font-body text-xs font-bold uppercase tracking-wider"
          title={t('change-region') ?? ''}
          onClick={handleOpenModalRegion}
        >
          <img
            className="h-3 mx-2"
            src={`${siteInfo?.discoverUrl}/assets/img/flags/${countryFlagCode}.png`}
            alt={country}
          />
          <div>{country}</div>
        </button>
      </div>
      <div className="flex-grow flex items-center relative">
        <div className="p-5 lg:px-10 flex justify-between w-full items-center">
          <a className="w-[135px] lg:w-[175px]" href={siteInfo?.discoverUrl}>
            {transparent && (
              <img
                alt=""
                src={`${siteInfo?.discoverUrl}/assets/img/${components.navigation?.logos?.logo}`}
              />
            )}
            {!transparent && (
              <img
                alt=""
                src={`${siteInfo?.discoverUrl}/assets/img/${components.navigation?.logos?.logo_alt}`}
              />
            )}
          </a>
          <div className="hidden xl:flex items-center">
            {navigationButtons?.map((item: any, n: number) => {
              return (
                <AButton
                  key={n}
                  className="mx-4"
                  type="a"
                  style="outline"
                  color={transparent ? 'white' : 'teal'}
                  title={item.text}
                  href={item.url}
                  onClick={handleButtonClick}
                >
                  <span>{item.text}</span>
                </AButton>
              )
            })}
            {navigationLinks?.map((item: any) => {
              return (
                <a
                  key={item.id}
                  href={item.url}
                  className={classNames(
                    'text-[13px] font-body font-bold tracking-wider uppercase text-center mx-4',
                    {
                      'text-gray-900 hover:text-primary-teal': !transparent,
                      'text-neutral-white': transparent,
                    }
                  )}
                >
                  {item.text}
                </a>
              )
            })}
            <AButton
              className="mx-4"
              icon={SvgSearchIcon}
              iconAlign="right"
              iconClass="w-6 h-6 stroke-current"
              style={transparent ? 'outline' : 'solid'}
              color={transparent ? 'white' : 'teal'}
              title={t('find-a-doctor') ?? ''}
            >
              <span>{t('find-a-doctor')}</span>
            </AButton>
            <MLegacyBurgerIcl
              className="flex-shrink-0"
              active={menuOpen}
              color={transparent ? 'white' : 'teal'}
              onClick={() => setMenuOpen(!menuOpen)}
            />
          </div>
          <MLegacyBurgerIcl
            className="ml-2 flex-shrink-0 xl:hidden"
            active={menuOpen}
            color={transparent ? 'white' : 'teal'}
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </div>
      </div>
      <CLegacyRegionModalIcl
        active={modalRegionActive}
        onClose={handleCloseModalRegion}
        components={components}
      />
      <CLegacyMenuIcl
        components={components}
        active={menuOpen}
        onClose={() => setMenuOpen(false)}
        onRegionChange={handleMenuRegionChange}
      />
    </div>
  )
}
