import React, { useState, useRef, useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'
import { AVideo } from '../atoms/a-video'
import { ASvg } from '../atoms/a-svg'
import SvgCloseIcon from '../../svg/icons/stroke/close.svg'
import { AButton } from '../atoms/a-button'
import { CFormie, CFormieField } from '../organisms/c-formie'

interface FormVideoModalProps {
  videoSrc: string | undefined
  openModalLabel: string
  form?: Array<CFormieField>
}

const FormVideoModal = ({
  videoSrc,
  openModalLabel,
  form,
}: FormVideoModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [csrfToken, setCsrfToken] = useState<string | undefined>()
  const modalRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const openModalButtonRef = useRef<HTMLButtonElement>(null)
  const nameInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!isOpen) return

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    }

    document.addEventListener('keydown', handleKeydown)

    // Focus the first input when modal opens
    nameInputRef.current?.focus()

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen])

  useEffect(() => {
    const targetElement = modalRef.current
    if (!targetElement) return

    disableBodyScroll(targetElement)

    return () => {
      enableBodyScroll(targetElement)
    }
  }, [isOpen])

  const openModal = () => {
    setIsOpen(true)
    setShowVideo(false)
  }

  const closeModal = () => {
    setIsOpen(false)
    setShowVideo(false)
    videoRef.current?.pause()
    openModalButtonRef.current?.focus() // Return focus to the trigger button
  }

  const handleSubmit = () => {
    setShowVideo(true)
  }

  const getFormTokenInfo = async () => {
    fetch('/actions/users/session-info', {
      cache: 'no-cache',
      headers: { accept: 'application/json' },
    })
      .then((res) => {
        return res.json()
      })
      .then((res) => {
        if (!csrfToken) {
          setCsrfToken(res?.csrfTokenValue || '')
        }
      })
      .catch((err) => {
        console.log('Error getting form token:', err)
      })
  }

  useEffect(() => {
    if (csrfToken) {
      return
    }
    getFormTokenInfo()
  }, [form])

  return (
    <>
      <AButton
        ref={openModalButtonRef}
        onClick={openModal}
        className="min-w-14 !block mx-3 sm:mx-auto my-5"
        type="button"
        color="teal"
        style="solid"
      >
        <span
          style={{
            fontSize: '18px',
          }}
        >
          {openModalLabel}
        </span>
      </AButton>

      {isOpen && (
        <div
          ref={modalRef}
          role="dialog"
          aria-modal="true"
          className={classNames(
            'm-video-modal fixed top-0 left-0 w-full h-screen z-80 flex items-center justify-center transition-opacity duration-200 ease-out',
            {
              'opacity-100 pointer-events-auto': open,
              'opacity-0 pointer-events-none': !open,
            }
          )}
        >
          <div
            className="m-video-modal__dim fixed top-0 left-0 w-full h-screen z-80"
            onClick={closeModal}
          />
          <div
            onClick={(e) => e.stopPropagation()}
            className="m-video-modal__video w-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-80"
          >
            <button
              className="absolute top-5 inline-end-2 z-50 bg-white"
              onClick={closeModal}
            >
              <ASvg
                className="stroke-current text-neutral-black w-8 h-8"
                svg={SvgCloseIcon}
              />
            </button>
            {!showVideo ? (
              <CFormie
                csrfToken={csrfToken}
                field={form}
                className="mt-4 bg-white p-8 max-w-xl mx-auto w-[100vw]"
                onSuccessHandler={handleSubmit}
              />
            ) : (
              <AVideo
                ref={videoRef}
                src={videoSrc ?? ''}
                controls={true}
                className="w-[100vw] max-w-4xl mt-4"
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default FormVideoModal
