import { MutableRefObject } from 'react'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { ASvg } from '../atoms/a-svg'
import SvgTopArrowIcon from '../../svg/icons/stroke/chevron-top.svg'
import { CExpandableFaqsBlock } from './c-expandable-faqs'
import './c-expandable-faqs-evo.css'

interface CExpandableFaqsUsProps {
  content: CExpandableFaqsBlock | { idName: string; faqs: any[] }
  showSearchBar: boolean
  searchValue: string
  handleSearchInput: (event: any) => void
  expandedItem: number | null
  setExpandedItem: (param: number | null) => void
  blockRef: MutableRefObject<(HTMLDivElement | null)[]>
  itemHeights: number[]
}
export const CExpandableFaqsEvo = ({
  content,
  showSearchBar,
  searchValue,
  handleSearchInput,
  expandedItem,
  setExpandedItem,
  blockRef,
  itemHeights,
}: CExpandableFaqsUsProps) => {
  const { t } = useTranslation('common')

  return (
    <section
      id={content.idName}
      className="c-expandable-faqs-evo bg-white py-[50px] md:pb-[70px]"
    >
      <div className="o-container o-container--lg bg-gray-100 pt-[60px] pb-[100px]">
        <div className="max-w-[850px] mx-auto">
          <h2 className="text-4xl font-semibold text-gray-600">
            Frequently Asked Questions
          </h2>
          <div className="c-expandable-faqs-evo__items mt-5 md:mt-8">
            {content.faqs?.map((faq, n) => (
              <div
                key={faq.id}
                className={classNames('c-expandable-faqs-evo__item', {
                  'c-expandable-faqs-evo__item--expanded': n === expandedItem,
                })}
              >
                <button
                  className="text-neutral-dark-gray text-lg md:text-p-lg w-full flex items-center"
                  onClick={() => setExpandedItem(n === expandedItem ? null : n)}
                >
                  <span
                    className="flex-grow text-start font-semibold text-primary-dark-teal [&>sup]:text-xs [&>sup]:align-super"
                    dangerouslySetInnerHTML={{ __html: faq.title }}
                  />

                  <div className="c-expandable-faqs-evo__icon flex-shrink-0 mis-8 text-primary-dark-teal">
                    <ASvg
                      className="stroke-current w-6 h-6 md:w-8 md:h-8"
                      svg={SvgTopArrowIcon}
                    />
                  </div>
                </button>
                <div
                  ref={(el) => {
                    blockRef.current[n] = el
                  }}
                  className="c-expandable-faqs-evo__answer md:text-lg text-primary-gray w-full s-wysiwyg"
                  style={{ height: n === expandedItem ? itemHeights[n] : 0 }}
                  dangerouslySetInnerHTML={{ __html: faq.copy }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
