import classNames from 'classnames'
import { throttle } from 'lodash'
import { useRef, useState, useMemo, useEffect } from 'react'
import { CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { AButton, ASvg, MVideoModal } from 'ui'
import SvgPlayIcon from 'ui/svg/icons/stroke/play.svg'
import './c-about-video.css'
import * as CONST from 'ui/constants/index'

export type CAboutVideoBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  copy?: string
  buttonLabel?: string
  video?: Array<CraftEmbeddedAssetField>
}

type Props = {
  field: Array<CAboutVideoBlock>
  lensBranding?: string
}

const defaultProps = {
  lensBranding: '',
}

const CAboutVideo = ({ field, lensBranding }: Props) => {
  const [active, setActive] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const containerRef = useRef<HTMLElement>(null)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  const handleScroll = () => {
    if (containerRef.current) {
      setActive(
        containerRef.current.getBoundingClientRect().top <=
          window.innerHeight * 0.5
      )
    }
  }

  const handleScrollThrottled = throttle(handleScroll, 100)

  const handleModalOpenButtonClick = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollThrottled)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScrollThrottled)
    }
  }, [])

  if (!content) {
    return <></>
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <div id={content?.idName} className="c-about-video">
      <section
        ref={containerRef}
        className="m-3 c-about-video__container md:m-6 lg:mx-6 md:flex bg-primary-teal text-neutral-white overflow-hidden px-6 md:px-0 pt-10 md:pt-0"
      >
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <div
            className={classNames(
              'c-about-video__copy flex flex-col items-start w-full transition-opacity duration-200 ease-out',
              {
                'opacity-0': !active,
                'opacity-100': active,
              }
            )}
          >
            <h2 className="text-h6">{content?.heading}</h2>
            {content?.copy && (
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{ __html: content.copy }}
              />
            )}
            <AButton
              type="button"
              style="outline"
              color="white"
              icon={SvgPlayIcon}
              iconClass="stroke-current w-6 h-6"
              iconAlign="left"
              className={classNames('mt-6', {
                'translate-y-0': active,
                'translate-y-8': !active,
              })}
              onClick={handleModalOpenButtonClick}
            >
              <span>{content?.buttonLabel}</span>
            </AButton>
          </div>
        </div>
        <div className="w-full md:w-1/2 relative flex justify-center md:block py-8 md:py-0">
          <div className="c-about-video__animation-container flex items-center justify-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-y-1/2 md:-translate-x-1/2">
            <img
              className="c-about-video__rings w-full h-full hidden md:block"
              src={`${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/about-video-rings.svg`}
              alt="Background shapes"
            />
            <img
              className="c-about-video__rings w-full h-full md:hidden"
              src={`${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/about-video-rings-mobile.svg`}
              alt="Background shapes"
            />
            <img
              className="c-about-video__lens absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              src={
                lensBranding === CONST.BRANDED.EVO_VISIAN_ICL
                  ? `${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/about-video-lens-icl.svg`
                  : `${process.env.NEXT_PUBLIC_DISCOVERICL_BACKEND_URL}img/about-video-lens-evo.svg`
              }
              alt="Lens image"
            />
            <button
              className="c-about-video__play-button absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-neutral-white rounded-full flex items-center justify-center"
              onClick={handleModalOpenButtonClick}
            >
              <ASvg
                className="text-neutral-dark-gray stroke-current fill-current transform md:scale-150 w-8 h-8"
                svg={SvgPlayIcon}
              />
            </button>
          </div>
        </div>
      </section>
      {!!content?.video?.length && (
        <MVideoModal
          open={modalOpen}
          video={content.video[0].embeddedAsset?.url}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

CAboutVideo.defaultProps = defaultProps

export { CAboutVideo }

export const CAboutVideoFragments = {
  field: `
    fragment aboutVideo on aboutVideo_MatrixField {
      ... on aboutVideo_block_BlockType {
        id
        typeHandle
        idName
        heading
        copy
        buttonLabel
        video {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
      }
    }
  `,
}
