import classNames from 'classnames'
import { debounce, throttle } from 'lodash'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ASvg } from 'ui/components/atoms/a-svg'
import SvgIconSearch from 'ui/svg/icons/stroke/search.svg'
import './c-expandable-faqs.css'
import { CExpandableFaqsUs } from './c-expandable-faqs-us'
import { useSiteInfo } from 'shared'
import { CExpandableFaqsEvo } from './c-expandable-faqs-evo'

export type CExpandableFaqsEntry = {
  title?: string
  id?: string
  copy?: string
}

export type CExpandableFaqsBlock = {
  idName?: string
  faqs?: Array<CExpandableFaqsEntry>
}

type Props = {
  field?: Array<CExpandableFaqsBlock>
  faqs?: Array<any>
  showSearchBar?: boolean
  isEvo?: boolean
}

export const CExpandableFaqs = ({
  field,
  faqs,
  showSearchBar = false,
  isEvo = false,
}: Props) => {
  const { t } = useTranslation('common')
  const siteInfo = useSiteInfo()

  const [faqsHits, setFaqsHits] = useState<Array<any>>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const [expandedItem, setExpandedItem] = useState<number | null>(null)
  const [itemHeights, setItemHeights] = useState<number[]>([])

  const blockRef = useRef<Array<HTMLDivElement | null>>([])

  const content = useMemo(() => {
    if (faqsHits && faqsHits.length) {
      return { idName: '', faqs: faqsHits }
    } else if (faqs && faqs.length) {
      return { idName: '', faqs }
    } else if (field && field.length) {
      return field[0]
    } else {
      return {}
    }
  }, [field, faqs, faqsHits])

  const calculateItemHeights = () => {
    setItemHeights(blockRef.current.map((el) => el?.scrollHeight || 0))
  }

  const handleSearchInput = (event: any) => {
    setExpandedItem(null)
    setSearchValue(event.target.value)
    submitFaqsSearchDebounced(event.target.value)
  }

  const submitFaqsSearch = async (value: string) => {
    if (value) {
      const { default: algoliasearch } = await import('algoliasearch')
      const client = algoliasearch(
        process.env.NEXT_PUBLIC_DISCOVERICL_ALGOLIA_APPLICATION_ID ?? '',
        process.env.NEXT_PUBLIC_DISCOVERICL_ALGOLIA_SEARCH_ONLY_API_KEY ?? ''
      )
      const index = client.initIndex(`${siteInfo.handle}Faq`)
      const { hits } = await index.search(value, {
        attributesToRetrieve: ['title', 'faqAnswer'],
      })
      setFaqsHits(
        hits.map((hit: any) => ({
          title: hit.title,
          id: hit.objectID,
          copy: hit.faqAnswer,
        }))
      )
    } else {
      setFaqsHits([])
    }
  }

  const submitFaqsSearchDebounced = useMemo(
    () => debounce(submitFaqsSearch, 300),
    []
  )
  const handleWindowResizeThrottled = useMemo(
    () => throttle(calculateItemHeights, 300),
    []
  )

  useEffect(() => {
    calculateItemHeights()
    window.addEventListener('resize', handleWindowResizeThrottled)

    return () => {
      window.removeEventListener('resize', handleWindowResizeThrottled)
    }
  }, [])

  useEffect(() => {
    calculateItemHeights()
    content.faqs?.length === 1 && setExpandedItem(0)
  }, [content.faqs])

  if (!content.faqs?.length && !searchValue) {
    return <></>
  }

  if (isEvo) {
    return (
      <CExpandableFaqsEvo
        content={content}
        showSearchBar={false}
        searchValue={searchValue}
        handleSearchInput={handleSearchInput}
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
        blockRef={blockRef}
        itemHeights={itemHeights}
      />
    )
  }

  if (siteInfo.isUS) {
    return (
      <CExpandableFaqsUs
        content={content}
        showSearchBar={showSearchBar}
        searchValue={searchValue}
        handleSearchInput={handleSearchInput}
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
        blockRef={blockRef}
        itemHeights={itemHeights}
      />
    )
  }

  return (
    <section id={content.idName} className="c-expandable-faqs mt-18 mb-25">
      <div className="o-container o-container--md">
        {showSearchBar && (
          <>
            <h3 className="text-heading text-primary-teal">
              {t('search-faqs') || ''}
            </h3>
            <form
              className="mt-4 flex border-2 border-neutral-dark-gray rounded-full overflow-hidden pis-6 py-3 pie-4"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className="flex-grow font-button text-neutral-dark-gray placeholder-primary-gray uppercase font-bold outline-none"
                placeholder={t('type-a-question') || ''}
                value={searchValue}
                onChange={handleSearchInput}
              />
              <ASvg
                className="stroke-current flex-shrink-0 w-8 h-8"
                svg={SvgIconSearch}
              />
            </form>
          </>
        )}
        <div className="c-expandable-faqs__items mt-8">
          {content.faqs?.map((faq, n) => (
            <div
              key={faq.id}
              className={classNames('c-expandable-faqs__item py-10', {
                'c-expandable-faqs__item--expanded': n === expandedItem,
              })}
            >
              <button
                className="text-neutral-dark-gray text-p-lg w-full flex items-center"
                onClick={() => setExpandedItem(n === expandedItem ? null : n)}
              >
                <span className="flex-grow text-start">{faq.title}</span>
                <div className="c-expandable-faqs__plus flex-shrink-0 mis-8">
                  <div className="c-expandable-faqs__plus-bar" />
                  <div className="c-expandable-faqs__plus-bar c-expandable-faqs__plus-bar--vertical" />
                </div>
              </button>
              <div
                ref={(el) => {
                  blockRef.current[n] = el
                }}
                className="c-expandable-faqs__answer font-semibold text-p text-primary-gray w-full s-wysiwyg"
                style={{ height: n === expandedItem ? itemHeights[n] : 0 }}
                dangerouslySetInnerHTML={{ __html: faq.copy }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const CExpandableFaqsFragments = {
  field: `
    fragment expandableFaqs on expandableFaqs_MatrixField {
      ... on expandableFaqs_block_BlockType {
        idName
        faqs {
          title
          id
          ... on faq_faq_Entry {
            copy: faqAnswer
          }
          ... on safetyInformation_safetyInformation_Entry {
            copy: safetyInformationCopy
          }
        }
      }
    }
  `,
  faqEntry: `
    fragment expandableFaqsFaqEntry on faq_faq_Entry {
      title
      id
      copy: faqAnswer
    }
  `,
}
