import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { doctorsClient } from "discovericl/graphql/clients";
import { CraftAssetField } from "tsconfig/craft-types";
import { AButton } from "ui/components/atoms/a-button";
import { ASelect } from "ui/components/atoms/a-select";
import { ASvg } from "ui/components/atoms/a-svg";
import { FormData, MFormSearch } from "ui/components/molecules/m-form-search";
import SvgLocationIcon from "ui/svg/icons/fill/location.svg";
import SvgSearchIcon from "ui/svg/icons/stroke/search.svg";
import { CraftRegion } from "doctors/craft-types";
import "./c-find-a-doctor.css";
import { gql } from "@urql/core";
import { CFindADoctorUs } from "./c-find-a-doctor-us";
import { useSiteInfo } from "shared";

export type CFindADoctorBlock = {
  id?: string;
  typeHandle?: string;
  idName?: string;
  backgroundImage?: Array<CraftAssetField>;
  heading?: string;
  copy?: string;
  additionalCopy?: string;
};

type Props = {
  field: Array<CFindADoctorBlock>;
  margins?: boolean;
};

const defaultProps = {
  margins: false,
};

const CFindADoctor = ({ field, margins }: Props) => {
  const siteInfo = useSiteInfo();
  const { t } = useTranslation("common");

  const content = useMemo(() => {
    return field && field.length ? field[0] : null;
  }, [field]);

  const [regions, setRegions] = useState<any[]>([]);

  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchRegions = async () => {
    try {
      const { data } = await doctorsClient.query(
        gql`
          query Entry($site: [String]) {
            entries(section: "regions", site: $site, orderBy: "title ASC") {
              ... on regions_regions_Entry {
                id
                slug
                title
                regionLocations {
                  id
                }
              }
            }
            globalSet(handle: "global", site: $site) {
              ... on global_GlobalSet {
                featuredRegions {
                  ... on regions_regions_Entry {
                    id
                    slug
                    title
                  }
                }
              }
            }
          }
        `,
        {
          site: siteInfo.handle,
        }
      );

      if (data?.entries?.length) {
        const {
          entries,
          globalSet: { featuredRegions },
        } = data;
        const featuredIds = featuredRegions.map(
          (region: CraftRegion) => region.id
        );
        const filteredRegions = entries
          .filter(
            (region: CraftRegion) =>
              !featuredIds.includes(region.id) && region.regionLocations?.length
          )
          .map(({ id, slug, title }: CraftRegion) => ({ id, slug, title }));
        const payload = featuredRegions.length
          ? [...featuredRegions, { title: "-" }, ...filteredRegions]
          : filteredRegions;

        setRegions([
          {
            label: t("select-a-region").toUpperCase(),
            value: "",
            disabled: true,
          },
          ...payload.map((entry: CraftRegion) => ({
            label: entry.title,
            value: entry.slug,
            disabled: !entry.slug,
          })),
        ]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleButtonCurrentLocationClick = (event: MouseEvent) => {
    event.preventDefault();

    // eslint-disable-next-line no-undef
    const handleSuccess = (position: GeolocationPosition) => {
      const coords = `lat=${position.coords.latitude}&lng=${position.coords.longitude}`;

      TagManager.dataLayer({
        dataLayer: {
          event: "doctorSearch",
          event_category: "Doctor Search",
          event_action: "Find a Doctor component before footer",
          event_label: coords,
        },
      });

      window.location.href = `${siteInfo.doctorUrl}?${coords}`;
    };

    const handleError = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "doctorSearch",
          event_category: "Doctor Search",
          event_action: "Find a Doctor component before footer",
          event_label: "",
        },
      });

      window.location.href = siteInfo.doctorUrl || "";
    };

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  };

  const submitDoctorSearch = (data: FormData) => {
    const piiRegex = /(\S+@\S+)/g;

    if (piiRegex.test(data.q)) {
      console.log(
        "Refusing to search for something that looks like personal information."
      );
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "doctorSearch",
        event_category: "Doctor Search",
        event_action: "Find a Doctor component before footer",
        event_label: data.q,
      },
    });

    window.location.href = `${siteInfo.doctorUrl}?q=${encodeURIComponent(
      data.q
    )}`;
  };

  const submitRegionSearch = (region: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "doctorSearch",
        event_category: "Doctor Search",
        event_action: "Find a Doctor component before footer",
        event_label: region,
      },
    });

    window.location.href = `${siteInfo.doctorUrl}?q=${region}`;
  };

  if (!content) {
    return <></>;
  }

  if (siteInfo.isUS) {
    return (
      <CFindADoctorUs
        content={content}
        regions={regions}
        submitRegionSearch={submitRegionSearch}
        submitDoctorSearch={submitDoctorSearch}
      />
    );
  }

  return (
    <section
      id={content.idName}
      className={classNames(
        "relative c-find-a-doctor bg-neutral-dark-gray",
        margins && "mx-3 md:mx-6"
      )}
    >
      {!!content.backgroundImage?.length && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={content.backgroundImage[0].url}
          alt={content.backgroundImage[0].title}
          className="absolute top-0 left-0 object-cover w-full h-full c-find-a-doctor__background-image"
        />
      )}
      <div className="relative flex items-center px-5 py-16 c-find-a-doctor__container">
        <div className="w-full c-find-a-doctor__content">
          <div className="mx-auto c-find-a-doctor__body">
            {content.heading && (
              <h2
                className={classNames(
                  "font-normal text-center text-neutral-white whitespace-pre-line c-find-a-doctor__heading",
                  siteInfo.iso === "ko-KR" ? "text-h7" : "text-h6"
                )}
                dangerouslySetInnerHTML={{ __html: content.heading }}
              />
            )}
            {content.copy && (
              <div
                className="mt-4 font-semibold text-center text-p text-neutral-white s-wysiwyg"
                dangerouslySetInnerHTML={{ __html: content.copy }}
              />
            )}
          </div>
          <div
            className={classNames(
              "mx-auto mt-8 md:flex md:bg-neutral-white md:rounded-full md:p-6",
              regions.length
                ? "c-find-a-doctor__regions-box"
                : "c-find-a-doctor__search-box"
            )}
          >
            {!!regions.length && (
              <ASelect
                className="regions"
                options={regions}
                value=""
                onChangeHandler={submitRegionSearch}
              />
            )}

            {!regions.length && (
              <>
                <div className="flex-grow p-4 rounded-full md:p-0 bg-neutral-white md:bg-transparent text-primary-gray">
                  <MFormSearch
                    icon={
                      <ASvg
                        aria-label="Search"
                        className="stroke-current w-8 h-8"
                        svg={SvgSearchIcon}
                      />
                    }
                    text={t("enter-your-city-zip") || ""}
                    type="text"
                    onSubmit={submitDoctorSearch}
                  />
                </div>

                <AButton
                  className="w-full mt-6 md:w-auto md:mt-0 md:mis-4"
                  href={siteInfo.doctorUrl}
                  onClick={handleButtonCurrentLocationClick}
                  icon={SvgLocationIcon}
                  iconAlign="left"
                  iconClass="fill-current w-6 h-6"
                  size="lg"
                  type="a"
                >
                  <span>{t("use-my-current-location")}</span>
                </AButton>
              </>
            )}
          </div>
          {content.additionalCopy && (
            <div className="mx-auto c-find-a-doctor__body">
              <div
                className="mt-8 italic text-center text-neutral-white c-find-a-doctor__additional-copy"
                dangerouslySetInnerHTML={{ __html: content.additionalCopy }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

CFindADoctor.defaultProps = defaultProps;

export { CFindADoctor };

const blockFields = `
  id
  typeHandle
  idName
  backgroundImage {
    url @transform(mode: "crop", width: 1440, height: 558, immediately: true)
    title
  }
  heading
  copy
  additionalCopy
`;

export const CFindADoctorFragments = {
  field: `
    fragment findADoctor on findADoctor_MatrixField {
      ... on findADoctor_block_BlockType {
        ${blockFields}
      }
    }
  `,
  uiField: `
    fragment uiFindADoctor on uiFindADoctor_MatrixField {
      ... on uiFindADoctor_block_BlockType {
        ${blockFields}
      }
    }
  `,
};
