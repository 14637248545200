import classNames from 'classnames'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { ASvg, AVideo, AVideoMethods } from 'ui'
import SvgCloseIcon from 'ui/svg/icons/stroke/close.svg'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import './m-video-modal.css'

type Props = {
  open?: boolean
  video?: string | null
  onClose?: Function
}

const defaultProps = {
  open: false,
  video: null,
}

const MVideoModal = ({ open, video, onClose }: Props) => {
  const playerRef = useRef<AVideoMethods>(null)
  const modalRef = useRef<HTMLDivElement>(null)

  const close = () => {
    if (onClose instanceof Function) {
      onClose()
    }

    playerRef.current?.pause()

    if (modalRef.current) {
      enableBodyScroll(modalRef.current)
    }
  }

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close()
    }
  }

  useLayoutEffect(() => {
    if (modalRef.current && open) {
      playerRef.current?.play()
      // avoid scrolling to top on iOS ref: https://github.com/willmcpo/body-scroll-lock/issues/237
      const storedRequestAnimationFrame = window.requestAnimationFrame;

      window.requestAnimationFrame = () => 42;
      disableBodyScroll(modalRef.current);
      window.requestAnimationFrame = storedRequestAnimationFrame;
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  return (
    <div
      ref={modalRef}
      className={classNames(
        'm-video-modal fixed top-0 left-0 w-full h-screen z-80 flex items-center justify-center transition-opacity duration-200 ease-out',
        {
          'opacity-100 pointer-events-auto': open,
          'opacity-0 pointer-events-none': !open,
        }
      )}
    >
      <div
        className="m-video-modal__dim fixed top-0 left-0 w-full h-screen z-80"
        onClick={close}
      />
      <div className="m-video-modal__video w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-80">
        <AVideo ref={playerRef} src={video ?? ''} controls={true} />
        <button
          className="absolute -top-9 lg:top-0 inline-end-2 lg:-inline-end-9"
          onClick={close}
        >
          <ASvg
            className="stroke-current text-neutral-white w-8 h-8"
            svg={SvgCloseIcon}
          />
        </button>
      </div>
    </div>
  )
}

MVideoModal.defaultProps = defaultProps

export { MVideoModal }
