import { CraftLinkItField } from 'tsconfig/craft-types'
import { AButton } from '../atoms/a-button'

export interface CCtaColumnsBlock {
  buttons?: Array<{
    heading?: string
    cta?: CraftLinkItField
  }>
}

interface CCtaColumnsProps {
  field: Array<CCtaColumnsBlock>
}

export const CCtaColumns = ({ field }: CCtaColumnsProps) => {
  const { buttons } = field?.[0]

  return (
    <div className="o-container o-container--lg py-20 px-[46px]">
      <div className="flex justify-around flex-col md:flex-row gap-[40px] md:gap-[unset] md:max-w-[90%] md:mx-auto">
        {buttons?.map((btn, i) => {
          const isOdd = Boolean(i % 2 === 0)
          return (
            <div key={i} className="flex flex-col items-center gap-[16px] md:w-[300px]">
              {btn.heading && (
                <h3 className="text-2xl text-primary-dark-teal text-center font-semibold">
                  {btn.heading}
                </h3>
              )}
              {btn.cta?.url && (
                <AButton
                  key={`${btn.cta?.url}-${i}`}
                  className="w-full md:w-auto md:min-w-[300px] md:mt-auto"
                  type={btn.cta.type === 'custom' || btn.cta.type === 
                  'entry' || btn.cta.type === 
                  'url' ? 'a' : btn.cta.type}
                  href={btn.cta?.url}
                  style={isOdd ? 'outline' : 'solid'}
                  color="teal"
                  size="lg"
                >
                  <span>{btn.cta?.text}</span>
                </AButton>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const CCtaColumnsFragment = `
  fragment ctaColumns on ctaColumns_MatrixField {
    ... on ctaColumns_block_BlockType {
      buttons {
        ... on buttons_BlockType {
          heading
          cta {
            type
            url
            text
            target
          }
        }
      }
    }
  }
`
