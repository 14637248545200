import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { normalizeHref } from "discovericl/helpers/normalize-href";
import { AButton, MVideoModal } from "ui";
import SvgPlayIcon from "ui/svg/icons/stroke/play.svg";
import { useSiteInfo } from "shared";

type Props = {
  className?: string;
  quote: string;
  avatar: string;
  avatarAlt?: string;
  name: string;
  occupation: string;
  url?: string;
  video?: string;
  showVideoButton?: boolean;
  theme?: string; // gray, teal, full-width
  mobileTheme?: string; // gray, teal
};

const defaultProps = {
  avatarAlt: "",
  url: null,
  video: null,
  showVideoButton: false,
  theme: "gray",
  mobileTheme: "gray",
};

const MTestimonial = (props: Props) => {
  const siteInfo = useSiteInfo();

  const { t } = useTranslation("common");

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  /* eslint-disable @next/next/no-img-element */
  return (
    <div
      className={classNames(
        "m-testimonial py-28 px-11 flex flex-col items-center justify-center",
        props.className,
        {
          "bg-neutral-light-gray text-neutral-dark-gray":
            props.mobileTheme === "gray",
          "bg-primary-bright-teal text-neutral-white":
            props.mobileTheme === "teal",
          "lg:bg-neutral-light-gray lg:text-neutral-dark-gray":
            props.theme === "gray",
          "lg:bg-primary-bright-teal lg:text-neutral-white":
            props.theme === "teal",
          "lg:bg-neutral-white lg:text-primary-teal":
            props.theme === "full-width",
        }
      )}
    >
      <h2 className="m-testimonial__heading text-p-lg md:text-h6 text-center w-full max-w-[592px]">
        <span className={`o-quote o-quote--${siteInfo.iso} o-quote--teal-md`}>
          {props.quote}
        </span>
      </h2>
      <div className="flex items-center mt-10">
        <div className="w-11 h-11 rounded-full bg-neutral-light-gray overflow-hidden mie-6">
          <img
            className="w-full h-full object-cover"
            src={props.avatar}
            alt={props.avatarAlt}
          />
        </div>
        <div
          className={classNames({
            "lg:text-neutral-dark-gray": props.theme === "full-width",
          })}
        >
          <h3 className="text-p md:text-h7 font-semibold">{props.name}</h3>
          <h4 className="text-p-sm md:text-p font-semibold">
            {props.occupation}
          </h4>
        </div>
      </div>
      {props.url && (
        <AButton
          type="link"
          href={normalizeHref(props.url)}
          className="hidden lg:block mt-8"
          size="md"
          style="outline"
          color={
            ["gray", "full-width"].includes(props.theme ?? "")
              ? "gray"
              : "white"
          }
        >
          <span>{t("read-the-full-story")}</span>
        </AButton>
      )}
      {props.url && (
        <AButton
          type="link"
          href={normalizeHref(props.url)}
          className="lg:hidden mt-8"
          size="md"
          style="outline"
          color={props.mobileTheme === "teal" ? "white" : "gray"}
        >
          <span>{t("read-the-full-story")}</span>
        </AButton>
      )}
      {!props.url && props.showVideoButton && props.video && (
        <AButton
          type="button"
          aria-label={`Play the Video: ${props.name}, ${props.occupation}`}
          className="hidden lg:flex"
          style="outline"
          color={
            ["gray", "full-width"].includes(props.theme ?? "")
              ? "gray"
              : "white"
          }
          size="md"
          onClick={() => setModalOpen(true)}
          icon={SvgPlayIcon}
          iconAlign="left"
          iconClass="stroke-current w-6 h-6"
        >
          <span>{t("watch-the-video")}</span>
        </AButton>
      )}
      {!props.url && props.showVideoButton && props.video && (
        <AButton
          type="button"
          aria-label={`Play the Video: ${props.name}, ${props.occupation}`}
          className="lg:hidden mt-8"
          style="outline"
          color={props.mobileTheme === "teal" ? "white" : "gray"}
          size="md"
          onClick={() => setModalOpen(true)}
          icon={SvgPlayIcon}
          iconAlign="left"
          iconClass="stroke-current w-6 h-6"
        >
          <span>{t("watch-the-video")}</span>
        </AButton>
      )}
      {props.showVideoButton && props.video && (
        <MVideoModal
          open={modalOpen}
          video={props.video}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

MTestimonial.defaultProps = defaultProps;

export { MTestimonial };
