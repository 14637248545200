import { useEffect } from 'react'
import { CraftLinkItField } from 'tsconfig/craft-types'
import { AButton, ASvg, MModal } from 'ui'
import SvgSuccessIcon from 'ui/svg/icons/stroke/email-success.svg'

type Props = {
  open: boolean
  onClose: Function
  heading?: string
  subheading?: string
  button?: CraftLinkItField
}

export const CContactModal = ({
  open,
  onClose,
  heading,
  subheading,
  button,
}: Props) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      closeModal()
    }
  }

  const closeModal = () => {
    if (onClose instanceof Function) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <MModal open={open} onClose={closeModal}>
      <>
        <div className="bg-primary-dark-teal flex justify-center py-14 relative">
          <ASvg
            className="stroke-current text-white w-28 h-28"
            svg={SvgSuccessIcon}
          />
        </div>

        <div className="p-8 text-center">
          {heading && (
            <h3 className="mb-2 text-h6 md:text-h5 text-neutral-dark-gray">
              {heading}
            </h3>
          )}

          {subheading && (
            <h4 className="mb-6 font-semibold text-p text-primary-gray">
              {subheading}
            </h4>
          )}

          {button?.url && (
            <AButton
              style="outline"
              color="teal"
              type={button.type === 'url' ? 'a' : 'link'}
              href={button.url}
              rel={button.type === 'url' ? 'noreferrer' : ''}
              target={button.target}
            >
              <span>{button.text}</span>
            </AButton>
          )}
        </div>
      </>
    </MModal>
  )
}
