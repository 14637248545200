import { CraftAssetField, CraftEmbeddedAssetField } from "tsconfig/craft-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { AButton, APicture } from "ui";
import classNames from "classnames";
import { throttle } from "lodash";
import Hls from "hls.js";
import "./c-full-width-testimonial.css";
import { useSiteInfo } from "shared";

export type CFullWidthTestimonialBlock = {
  id?: string;
  typeHandle?: string;
  idName?: string;
  testimonial: Array<{
    id?: string;
    title?: string;
    typeHandle?: string;
    uri?: string;
    testimonialOccupation?: string;
    testimonialQuote?: string;
    testimonialImage?: Array<CraftAssetField>;
    testimonialVideo?: Array<CraftEmbeddedAssetField>;
    testimonialBackgroundVideo?: Array<CraftEmbeddedAssetField>;
    testimonialMobileBackgroundVideo?: Array<CraftEmbeddedAssetField>;
  }>;
};

type Props = {
  field: Array<CFullWidthTestimonialBlock>;
};

export const CFullWidthTestimonial = ({ field }: Props) => {
  const { t } = useTranslation("common");

  const siteInfo = useSiteInfo();
  const [animate, setAnimate] = useState(false);

  const sectionRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleWindowScroll = () => {
    const isAnimate =
      (sectionRef.current
        ? sectionRef.current.getBoundingClientRect().top <=
          window.innerHeight * 0.5
        : false) &&
      (sectionRef.current
        ? sectionRef.current.getBoundingClientRect().bottom >=
          window.innerHeight * 0.5
        : false);
    setAnimate(isAnimate);
  };

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100);

  const content = useMemo(() => {
    return field && field.length ? field[0] : null;
  }, [field]);

  const validateData = useMemo(() => {
    return (
      content?.testimonial.length &&
      content.testimonial[0].testimonialImage?.length &&
      content.testimonial[0].testimonialImage[0].url &&
      content.testimonial[0].testimonialQuote &&
      content.testimonial[0].title &&
      content.testimonial[0].testimonialOccupation
    );
  }, [content]);

  useEffect(() => {
    if (content && validateData) {
      window.addEventListener("scroll", handleWindowScrollThrottled);
      handleWindowScroll();

      if (content.testimonial[0].testimonialBackgroundVideo?.length) {
        const assetUrl =
          content.testimonial[0].testimonialBackgroundVideo[0].embeddedAsset
            ?.url || "";
        const videoUrl =
          content.testimonial[0].testimonialBackgroundVideo[0].url || "";
        const videoEl = videoRef.current!;

        if (assetUrl && Hls.isSupported()) {
          const hls = new Hls();
          hls.on(Hls.Events.ERROR, console.error);
          hls.loadSource(assetUrl);
          hls.attachMedia(videoEl);
        } else if (
          assetUrl &&
          videoEl.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoEl.src = assetUrl;
        } else if (videoUrl) {
          videoEl.src = videoUrl;
        }
      }
    }

    return () => {
      if (content && validateData) {
        window.removeEventListener("scroll", handleWindowScrollThrottled);
      }
    };
  }, [content]);

  if (!content || !validateData) {
    return <></>;
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <section
      ref={sectionRef}
      className={classNames("relative m-3 c-full-width-testimonial md:m-6", {
        "c-full-width-testimonial--animate": animate,
      })}
    >
      {!!content?.testimonial[0].testimonialImage && (
        <APicture
          className="object-cover w-full h-full"
          imageDesktop={content.testimonial[0].testimonialImage[0]}
        />
      )}
      {!!content?.testimonial[0].testimonialBackgroundVideo?.length && (
        <video
          ref={videoRef}
          className="absolute top-0 left-0 object-cover w-full h-full"
          title={content.testimonial[0].testimonialBackgroundVideo[0].title}
          autoPlay
          muted
          playsInline
          loop
        />
      )}
      <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full p-6 c-full-width-testimonial__content md:items-center text-neutral-white md:text-center">
        <h2 className="w-full c-full-width-testimonial__quote text-h7 md:text-h5">
          <span className={`o-quote o-quote--${siteInfo.iso}`}>
            {content?.testimonial[0].testimonialQuote}
          </span>
        </h2>
        <h3 className="mt-8 font-semibold text-p md:text-h7">
          {content?.testimonial[0].title}
        </h3>
        <h4 className="font-semibold text-p-sm md:text-p">
          {content?.testimonial[0].testimonialOccupation}
        </h4>
        {content?.testimonial[0].typeHandle === "testimonialPages" && (
          <AButton
            type="link"
            href={
              content.testimonial[0].uri
                ? encodeURI(content.testimonial[0].uri)
                : ""
            }
            className="c-full-width-testimonial__button mt-8"
            color="white"
            size="lg"
          >
            <span>{t("read-the-full-story")}</span>
          </AButton>
        )}
      </div>
    </section>
  );
};

const entryFragment = `
  testimonialOccupation
  testimonialQuote
  testimonialImage {
    url @transform(mode: "crop", width: 2000, immediately: true)
    title
    hasFocalPoint
    focalPoint
  }
  testimonialVideo {
    url
    title
    embeddedAsset {
      url
      title
    }
  }
  testimonialBackgroundVideo {
    id
    url
    title
    embeddedAsset {
      title
      url
      providerName
    }
  }
  testimonialMobileBackgroundVideo {
    id
    url
    title
    embeddedAsset {
      title
      url
      providerName
    }
  }
`;

const blockFragment = `
  id
  typeHandle
  idName
  testimonial {
    id
    title
    typeHandle
    uri
    ... on testimonials_testimonials_Entry {
      ${entryFragment}
    }
    ... on testimonialPages_testimonialPages_Entry {
      ${entryFragment}
    }
  }
`;

export const CFullWidthTestimonialFragments = {
  field: `
    fragment fullWidthTestimonial on fullWidthTestimonial_MatrixField {
      ... on fullWidthTestimonial_block_BlockType {
        ${blockFragment}
      }
    }
  `,
  field2: `
    fragment fullWidthTestimonial2 on fullWidthTestimonial2_MatrixField {
      ... on fullWidthTestimonial2_block_BlockType {
        ${blockFragment}
      }
    }
  `,
  customComponentsBlogField: `
    fragment customComponentsBlog_fullWidthTestimonial on customComponentsBlog_MatrixField {
      ... on customComponentsBlog_fullWidthTestimonial_BlockType {
        ${blockFragment}
      }
    }
  `,
};
