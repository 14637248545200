import classNames from 'classnames'
import useStateRef from 'discovericl/hooks/use-state-ref'
import { throttle } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { CraftAssetField } from 'tsconfig/craft-types'
import SvgArrowDownIcon from 'ui/svg/icons/stroke/arrow-down.svg'
import { useTranslation } from 'next-i18next'
import { AnimationItem } from 'lottie-web'
import { ASvg } from '../atoms/a-svg'
import './c-wtw-slides.css'

export type CWtwSlidesBlock = {
  id?: string
  typeHandle?: string
  layout?: string
  label?: string
  heading?: string
  subheading?: string
  copy?: string
  animationFile?: Array<CraftAssetField>
}

type Props = {
  field: Array<CWtwSlidesBlock>
}

export const CWtwSlides = ({ field }: Props) => {
  const { t } = useTranslation('common')

  const [animateSlideIn, setAnimateSlideIn, animateSlideInRef] = useStateRef([])

  const slidesRef = useRef<Array<HTMLElement | null>>([])
  const animationContainersRef = useRef<Array<HTMLElement | null>>([])
  const lottieRef = useRef<Array<AnimationItem | null>>([])

  const slides = useMemo(() => {
    return field && field.length ? field : null
  }, [field])

  const handleWindowScroll = () => {
    slides?.forEach((slide, n) => {
      if (slidesRef.current.length && slidesRef.current[n]) {
        const element = slidesRef.current[n]
        if (element) {
          const slidePos =
            element.getBoundingClientRect().top +
            document.documentElement.scrollTop
          if (window.scrollY + window.innerHeight / 2 > slidePos) {
            setAnimateSlideIn(
              animateSlideInRef.current.map((item: boolean, i: number) => {
                return i === n ? true : item
              })
            )
          } else {
            setAnimateSlideIn(
              animateSlideInRef.current.map((item: boolean, i: number) => {
                return i === n ? false : item
              })
            )
          }
        }
      }
    })
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  const loadLottieAnimations = async () => {
    const { default: lottie } = await import('lottie-web')

    slides?.forEach((slide, n) => {
      if (slide?.animationFile?.length && !lottieRef.current[n]) {
        const container = animationContainersRef.current[n]
        if (container) {
          lottieRef.current[n] = lottie.loadAnimation({
            container,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: slide.animationFile[0].url,
          })

          if (n === 8) {
            lottieRef.current[n]?.setSpeed(0.5)
          }
        }
      }
    })
  }

  useEffect(() => {
    setAnimateSlideIn(new Array(slides?.length).fill(false))
    loadLottieAnimations()
    handleWindowScroll()

    window.addEventListener('scroll', handleWindowScrollThrottled)

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
    }
  }, [])

  return (
    <div className="c-wtw-slides">
      {slides?.map((slide, n) => {
        return (
          <section
            id={slide.label?.toLowerCase().replace(' ', '')}
            key={n}
            ref={(el) => {
              slidesRef.current[n] = el
            }}
            className="m-3 relative c-wtw-slides__slide md:m-6 py-28 md:py-12"
          >
            <div className="flex flex-col h-full px-6 mx-auto my-0 c-wtw-slides__slide-container md:px-10 md:flex-row">
              <div
                className={classNames(
                  'flex items-center flex-shrink-0 w-full h-full md:w-1/3',
                  {
                    'order-2 md:order-2': slide.layout === 'mediaLeft',
                    'order-2 md:order-1': slide.layout === 'mediaRight',
                    'md:justify-center': slide.layout === 'mediaRight',
                  }
                )}
              >
                <div
                  className={classNames(
                    'flex flex-col w-full mt-8 transition-all duration-300 ease-out transform c-wtw-slides__slide-copy md:mt-0',
                    {
                      'md:-translate-y-11 opacity-0':
                        animateSlideIn[n] === false,
                      'md:translate-y-0 opacity-100':
                        animateSlideIn[n] === true,
                    }
                  )}
                >
                  {slide.label && (
                    <h4 className="w-full font-bold text-primary-teal text-h7">
                      {slide.label}
                    </h4>
                  )}
                  {slide.heading && (
                    <h2
                      className="w-full font-light text-h1 text-neutral-dark-gray"
                      dangerouslySetInnerHTML={{
                        __html: slide.heading.replace('\n', '<br>'),
                      }}
                    />
                  )}
                  {slide.subheading && (
                    <h3
                      className="w-full mt-2 text-neutral-dark-gray text-h6"
                      dangerouslySetInnerHTML={{
                        __html: slide.subheading.replace('\n', '<br>'),
                      }}
                    />
                  )}
                  {slide.copy && (
                    <p className="w-full mt-4 text-p text-neutral-dark-gray">
                      {slide.copy}
                    </p>
                  )}
                </div>
              </div>
              <div
                ref={(el) => {
                  animationContainersRef.current[n] = el
                }}
                className={classNames(
                  'w-full h-full transition-all duration-300 ease-out transform md:w-3/4',
                  {
                    'order-1 md:order-1': slide.layout === 'mediaLeft',
                    'order-1 md:order-2': slide.layout === 'mediaRight',
                    'scale-50 opacity-0': animateSlideIn[n] === false,
                    'scale-100 opacity-100': animateSlideIn[n] === true,
                  }
                )}
              />
            </div>
          </section>
        )
      })}
      <div className="sticky left-1/2 right-1/2 md:bottom-8 bottom-20 z-30 font-bold text-neutral-dark-gray uppercase text-button scroll-wrapper flex flex-col items-center">
        <span>{t('scroll')}</span>
        <ASvg
          className="stroke-current c-masthead__scroll-icon w-8 h-8"
          svg={SvgArrowDownIcon}
          ariaLabel={t('arrow-down') ?? ''}
        />
      </div>
    </div>
  )
}

export const CWtwSlidesFragments = {
  field: `
    fragment wtwSlides on wtwSlides_MatrixField {
      ... on wtwSlides_slide_BlockType {
        id
        typeHandle
        layout
        label
        heading
        subheading
        copy
        animationFile {
          url
          title
        }
      }
    }
  `,
}
