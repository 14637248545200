import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { useRouter } from 'next/router'
import useStateRef from 'discovericl/hooks/use-state-ref'
import { MVideoPip, MPinnedSubnav } from 'ui'
import { throttle } from 'lodash'
import { AnimationItem } from 'lottie-web'
import './c-image-copy-slides.css'
import * as CONST from 'ui/constants/index'

export type CImageCopySlidesBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  imageAlignInline?: string
  animation?: Array<CraftAssetField>
  navigationText?: string
  animationSegment?: [
    {
      startFrame: number
      stopFrame: number
    }
  ]
  bodyVideo?: Array<CraftEmbeddedAssetField>
  heading?: string
  copy?: string
}

type Props = {
  field: Array<CImageCopySlidesBlock>
  lensBranding: string
}

const defaultProps = {
  lensBranding: '',
}

const CImageCopySlides = ({ field, lensBranding }: Props) => {
  const router = useRouter()

  const [isActive, setIsActive] = useState<boolean>(false)
  const [blocksActive, setBlocksActive, blocksActiveRef] = useStateRef([])
  const [blocksOpacity, setBlocksOpacity, blocksOpacityRef] = useStateRef([])

  const animationRef = useRef<HTMLDivElement>(null)
  const blockNormalRef = useRef<Array<HTMLElement | null>>([])

  const lottieRef = useRef<AnimationItem | null>(null)

  const content = useMemo(() => {
    if (field && field.length) {
      let main = field.find((block) => block.typeHandle === 'main')
      if (router.route === '/procedure') {
        main = Object.assign({}, main, {
          animation: [
            {
              url:
                lensBranding === CONST.BRANDED.VISIAN_ICL
                  ? '/animations/procedure-icl.json'
                  : '/animations/procedure-evo.json',
              title: 'Procedure Animation',
            },
          ],
        })
      }
      return {
        ...main,
        slides: field.filter((block) => block.typeHandle === 'slide'),
        navigation: field
          .filter((block) => block.typeHandle === 'slide')
          .map((slide, index) => ({
            label: `Step ${index + 1}`,
            button: {
              url: slide.idName ? `#${slide.idName}` : null,
              text: slide.navigationText,
            },
            isActive: blocksActive[index],
          })),
      }
    } else {
      return null
    }
  }, [field, blocksActive])

  const handleWindowScroll = () => {
    blockNormalRef.current.forEach((element, index) => {
      if (index > 0 && element) {
        let blockOpacity =
          (element.getBoundingClientRect().top / window.innerHeight) * -1 + 1
        blockOpacity = blockOpacity * (Math.abs(blockOpacity) + 0.5) // Make images stay solid for longer
        blockOpacity = Math.max(Math.min(blockOpacity, 1), 0)
        setBlocksOpacity(
          blocksOpacityRef.current.map((item: number, itemIndex: number) => {
            return itemIndex === index ? blockOpacity : item
          })
        )
      }

      let isBlockActive: boolean = false

      if (element) {
        if (window.innerWidth >= 1024) {
          isBlockActive =
            element.getBoundingClientRect().top <= window.innerHeight * 0.5 &&
            element.getBoundingClientRect().bottom >= window.innerHeight * 0.5
        } else {
          isBlockActive =
            element.getBoundingClientRect().top + 0.5 * element.offsetHeight <=
              window.innerHeight * 0.75 &&
            element.getBoundingClientRect().bottom >= window.innerHeight * 0.75
        }
      }

      if (
        content?.animation?.length &&
        isBlockActive &&
        !blocksActiveRef.current[index] &&
        lottieRef.current
      ) {
        setTimeout(() => {
          const slide = content.slides[index]
          if (slide.animationSegment?.length) {
            const animationSegment = slide.animationSegment[0]
            lottieRef.current?.playSegments(
              [animationSegment.startFrame, animationSegment.stopFrame],
              true
            )
          }
        }, 100)
      }

      setBlocksActive(
        blocksActiveRef.current.map((item: boolean, itemIndex: number) => {
          return index === itemIndex ? isBlockActive : item
        })
      )

      setIsActive(blocksActiveRef.current.includes(true))
    })
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  const loadLottieAnimation = async () => {
    const { default: lottie } = await import('lottie-web')
    if (
      !lottieRef.current &&
      animationRef.current &&
      content?.animation?.length
    ) {
      lottieRef.current = lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: content.animation[0].url,
      })
    }
  }

  useEffect(() => {
    setBlocksActive(Array(blockNormalRef.current.length).fill(false))
    setBlocksOpacity(
      Array(blockNormalRef.current.length).fill(1, 0, 1).fill(0, 1)
    )
    window.addEventListener('scroll', handleWindowScrollThrottled)
    handleWindowScroll()
    if (content?.animation?.length && !lottieRef.current) {
      loadLottieAnimation()
    }

    return () => {
      window.removeEventListener('scroll', handleWindowScrollThrottled)
      if (content?.animation?.length && lottieRef.current) {
        lottieRef.current?.destroy()
      }
    }
  }, [])

  return (
    <section
      id={content?.idName}
      className={classNames('my-3 c-image-copy-slides md:my-6', {
        'is-active': isActive,
      })}
    >
      <div
        className={classNames(
          'relative m-3 c-image-copy-slides__main md:m-6 lg:flex',
          {
            'c-image-copy-slides__main--image-align-inline_end lg:flex-row-reverse':
              content?.imageAlignInline === 'end',
            'c-image-copy-slides__main--image-align-inline_start':
              content?.imageAlignInline !== 'end',
          }
        )}
      >
        <div className="c-image-copy-slides__blocks c-image-copy-slides__blocks--fade sticky z-10 w-full lg:w-1/2">
          {content?.slides.map((block, blockIndex) => {
            return (
              <div
                key={block.id}
                className="c-image-copy-slides__block c-image-copy-slides__block--fade absolute inset-0"
                style={{
                  opacity: blocksOpacity[blockIndex],
                  pointerEvents: blocksActive[blockIndex] ? 'auto' : 'none',
                }}
              />
            )
          })}
          {!!content?.animation?.length && (
            <div className="absolute inset-0 flex items-center justify-center pointer-events-none bg-primary-bright-teal">
              <div
                ref={animationRef}
                className="w-full h-full c-image-copy-slides__animation"
                aria-label={content.animation[0].title}
                role="img"
              />
            </div>
          )}
        </div>
        <div className="c-image-copy-slides__blocks c-image-copy-slides__blocks--normal lg:w-1/2">
          {content?.slides.map((block, blockIndex) => {
            return (
              <section
                id={block.idName}
                key={block.id}
                ref={(el) => {
                  blockNormalRef.current[blockIndex] = el
                }}
                className={classNames(
                  'relative c-image-copy-slides__block c-image-copy-slides__block--normal',
                  {
                    'lg:pie-6': content.imageAlignInline === 'end',
                    'lg:pis-6': content.imageAlignInline !== 'end',
                  }
                )}
              >
                <div className="relative flex flex-col items-center justify-center w-full h-full px-6 py-3">
                  <div
                    className={classNames(
                      'w-full transition-opacity duration-500 c-image-copy-slides__body',
                      {
                        'opacity-0': !blocksActive[blockIndex],
                      }
                    )}
                  >
                    {!!block?.bodyVideo?.length && (
                      <MVideoPip
                        className="mb-6"
                        src={block.bodyVideo[0]?.embeddedAsset?.url ?? ''}
                      />
                    )}
                    {block.heading && (
                      <h2
                        className="font-semibold text-h7 text-neutral-dark-gray whitespace-pre-line"
                        dangerouslySetInnerHTML={{
                          __html: block.heading ?? '',
                        }}
                      />
                    )}
                    {block.copy && (
                      <div
                        className="mt-2 font-semibold text-p text-primary-gray s-wysiwyg"
                        dangerouslySetInnerHTML={{ __html: block.copy ?? '' }}
                      />
                    )}
                  </div>
                </div>
              </section>
            )
          })}
        </div>
      </div>
      <MPinnedSubnav list={content?.navigation} />
    </section>
  )
}

CImageCopySlides.defaultProps = defaultProps

export { CImageCopySlides }

export const CImageCopySlidesFragments = {
  field: `
    fragment imageCopySlides on imageCopySlides_MatrixField {
      ... on imageCopySlides_main_BlockType {
        id
        typeHandle
        idName
        imageAlignInline
        animation {
          url
          title
        }
      }
      ... on imageCopySlides_slide_BlockType {
        id
        typeHandle
        idName
        navigationText
        animationSegment {
          startFrame
          stopFrame
        }
        bodyVideo {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
        heading
        copy
      }
    }
  `,
}
