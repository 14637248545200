import classNames from 'classnames'
import './m-legacy-burger-icl.css'

type Props = {
  className?: string
  color?: string
  active?: boolean
  onClick?: () => void
}

const defaultProps = {
  active: false,
  color: 'white',
}

const MLegacyBurgerIcl = ({ className, color, active, onClick }: Props) => {
  const handleClick = () => {
    if (onClick instanceof Function) {
      onClick()
    }
  }

  return (
    <button
      className={classNames(
        className,
        'w-[40px] h-[40px] flex items-center justify-center border-2 border-current rounded-full hover:text-neutral-white hover:bg-primary-teal hover:border-primary-teal transition-colors duration-200 ease-out',
        {
          'text-neutral-white': color === 'white',
          'text-primary-teal': color === 'teal',
        }
      )}
      onClick={handleClick}
    >
      <div
        className={classNames('w-[15px] h-[15px] relative', {
          active,
        })}
      >
        <div className="c-legacy-burger-icl__burger-bar c-legacy-burger-icl__burger-bar--top w-full absolute transition-all duration-100 ease-out bg-current" />
        <div className="c-legacy-burger-icl__burger-bar c-legacy-burger-icl__burger-bar--middle w-full absolute transition-all duration-100 ease-out bg-current" />
        <div className="c-legacy-burger-icl__burger-bar c-legacy-burger-icl__burger-bar--bottom w-full absolute transition-all duration-100 ease-out bg-current" />
      </div>
    </button>
  )
}

MLegacyBurgerIcl.defaultProps = defaultProps

export { MLegacyBurgerIcl }
