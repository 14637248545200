import { useTranslation } from 'next-i18next'
import { ASvg } from '../atoms/a-svg'
import SvgArrowLeftIcon from '../../svg/icons/stroke/chevron-left.svg'
import SvgArrowRightIcon from '../../svg/icons/stroke/chevron-right.svg'
import classNames from 'classnames'

interface MSliderArrowsProps {
  className?: string
  totalPages: number
  currentPage: number
  onChangePage?: (index: number) => void
  arrowsClassNames?: string
}

export const MSliderArrows = ({
  totalPages,
  currentPage,
  onChangePage,
  className,
  arrowsClassNames,
}: MSliderArrowsProps) => {
  const { t } = useTranslation('common')

  const changePage = (index: number) => {
    if (index < 1 || index > (totalPages ?? 0)) {
      return
    }
    if (onChangePage instanceof Function) {
      onChangePage(index)
    }
  }

  return (
    <div className={className}>
      <button
        className="m-pagination-dots__arrow"
        onClick={() => changePage(currentPage - 1)}
      >
        <ASvg
          className={classNames(
            `m-pagination-dots__arrow-icon stroke-current ${arrowsClassNames}`,
            {
              'opacity-25': Boolean(currentPage === 1),
            }
          )}
          svg={SvgArrowLeftIcon}
          ariaLabel={t('arrow-left') ?? ''}
        />
      </button>
      <button
        className="m-pagination-dots__arrow"
        onClick={() => changePage(currentPage + 1)}
      >
        <ASvg
          className={classNames(
            `m-pagination-dots__arrow-icon stroke-current ${arrowsClassNames}`,
            {
              'opacity-25': Boolean(currentPage === totalPages),
            }
          )}
          svg={SvgArrowRightIcon}
          ariaLabel={t('arrow-right') ?? ''}
        />
      </button>
    </div>
  )
}
