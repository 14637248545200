export const createLocaleUrl = (subdomain: string, domain: string) => {
  const regex = /local$/
  if (process.env.NODE_ENV !== 'development' && !regex.test(domain)) {
    return `https://${subdomain}.${domain}`
  } else {
    return `http://${subdomain}.${domain}:3000`
  }
}

export const getI18nResolvedUrl = (url: string) => {
  const matchClient = decodeURIComponent(url).match(
    /\/[\w]{2}-[\w]{0,4}\/(?<uri>[^.]+).json/
  )

  if (matchClient && matchClient?.groups?.uri) {
    return matchClient?.groups?.uri
  }

  const matchServer = decodeURIComponent(url).match(
    /^\/(?<pathname>[^?]+)\?*.*$/
  )

  if (matchServer?.groups?.pathname) {
    return matchServer.groups.pathname
  }

  return decodeURIComponent(url).replace(/^\//, '')
}
