import { useMemo } from 'react'
import { CraftAssetField } from 'tsconfig/craft-types'
import { APicture } from 'ui'
import './c-article-masthead.css'

export type CArticleMastheadBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  image?: CraftAssetField
  imageMobile: CraftAssetField
}

type Props = {
  image?: CraftAssetField | null
  imageMobile?: CraftAssetField | null
  heading?: string
  field?: Array<CArticleMastheadBlock>
}

export const CArticleMasthead = (props: Props) => {
  const content = useMemo(() => {
    return props.image || props.heading
      ? props
      : props.field?.length
      ? props.field[0]
      : null
  }, [])

  return (
    <section className="c-article-masthead">
      {content?.heading && (
        <div className="o-container o-container--md">
          <h1 className="text-h6 md:text-h3 text-neutral-dark-gray">
            {content.heading}
          </h1>
        </div>
      )}

      {content?.image?.url && (
        <div className="c-article-masthead__picture-container o-container mt-8">
          <APicture
            className="w-full"
            imageDesktop={content.image}
            imageMobile={content.imageMobile}
            mediaQuery="(max-width: 767px)"
          />
        </div>
      )}
    </section>
  )
}

export const CArticleMastheadFragments = {
  field: `
    fragment articleMasthead on articleMasthead_MatrixField {
      ... on articleMasthead_block_BlockType {
        id
        typeHandle
        idName
        heading
        image {
          url
          title
          hasFocalPoint
          focalPoint
        }
        imageMobile {
          url
          title
          hasFocalPoint
          focalPoint
        }
      }
    }
  `,
}
