export const SITE_LAYOUT = {
  EVO: 'evo',
  ICL: 'icl',
} as const

export const BRANDED = {
  EVO_VISIAN_ICL: 'evo-visian-icl',
  STAAR_SURGICAL: 'staar-surgical',
  VISIAN_ICL: 'visian-icl',
} as const

export const PLATFORM = {
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  VIMEO: 'vimeo',
  YOUTUBE: 'youtube',
} as const

export const URL_DATA_TAB = {
  ABOUT: 'about',
  CONTACT: 'contact',
  DOCTORS: 'doctors',
  INFORMATION: 'information',
  LOCATIONS: 'locations',
}
