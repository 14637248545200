import { useEffect, useMemo, useRef, useState } from 'react'
import throttle from 'lodash/throttle'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { APicture, ASvg, AVideo, AVideoMethods, CFormieField } from 'ui'
import SvgIconClose from 'ui/svg/icons/stroke/close.svg'
import SvgIconPlay from 'ui/svg/icons/fill/play.svg'
import classNames from 'classnames'
import { useSiteInfo } from 'shared'
import './c-full-width-video.css'
import FormVideoModal from '../molecules/m-form-video-modal'

export type CFullWidthVideoBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  desktopPoster?: Array<CraftAssetField>
  mobilePoster?: Array<CraftAssetField>
  video?: Array<CraftEmbeddedAssetField>
  longVideo?: Array<CraftEmbeddedAssetField>
  videoOrientation?: 'horizontal' | 'vertical'
  position?: 'regular' | 'top'
}

type Props = {
  field: Array<CFullWidthVideoBlock>
  testimonialName: string | undefined
  form?: Array<CFormieField>
}

export const CFullWidthVideo = ({ field, testimonialName, form }: Props) => {
  const siteInfo = useSiteInfo()
  const [animateDown, setAnimateDown] = useState<boolean>(false)
  const [hideOverlay, setHideOverlay] = useState<boolean>(false)

  const sectionRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<AVideoMethods>(null)

  const handleWindowScroll = () => {
    const element = sectionRef.current
    setAnimateDown(
      element
        ? element.getBoundingClientRect().top <= window.innerHeight * 0.5
        : false
    )
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  const content = useMemo(() => {
    return field && field.length ? field[0] : null
  }, [field])

  useEffect(() => {
    setHideOverlay(false)
    if (content) {
      window.addEventListener('scroll', handleWindowScrollThrottled)
      handleWindowScroll()
    }

    return () => {
      if (content) {
        window.removeEventListener('scroll', handleWindowScrollThrottled)
      }
    }
  }, [content])

  const playVideo = () => {
    setHideOverlay(true)
    videoRef.current?.play()
  }

  const hideVideo = () => {
    setHideOverlay(false)
    videoRef.current?.pause()
  }

  return (
    <>
      <div
        ref={sectionRef}
        className={classNames(
          'c-full-width-video relative my-18 md:my-0 overflow-hidden',
          {
            'c-full-width-video--animate': animateDown,
            'mt-18 mb-5': Boolean(content?.longVideo?.[0]?.embeddedAsset?.url),
          }
        )}
      >
        {content?.video?.length && content.video[0].embeddedAsset && (
          <div
            className={classNames(
              'bg-white bg-opacity-80 transition-opacity duration-200',
              { 'opacity-0': !hideOverlay }
            )}
            onClick={hideVideo}
          >
            <div
              className={classNames('relative', {
                'w-1/2 lg:w-1/3 mx-auto':
                  content.videoOrientation === 'vertical',
              })}
              onClick={(e) => e.stopPropagation()}
            >
              <AVideo
                ref={videoRef}
                src={content.video[0].embeddedAsset.url}
                controls={true}
                orientation={content.videoOrientation}
              />
              <button
                className="absolute right-4 top-4 bg-white p-2 rounded-full text-primary-teal"
                onClick={hideVideo}
              >
                <ASvg
                  className="stroke-current w-8 h-8"
                  svg={SvgIconClose}
                  ariaLabel="Close"
                />
              </button>
            </div>
          </div>
        )}

        <div
          className={classNames(
            'absolute w-full h-full top-0 left-0 cursor-pointer transition-opacity duration-200 ease-out',
            {
              'pointer-events-none z-[-1]': hideOverlay,
            }
          )}
          onClick={playVideo}
        >
          <APicture
            className="c-full-width-video__poster w-full h-full object-cover"
            imageDesktop={content?.desktopPoster && content.desktopPoster[0]}
            imageMobile={content?.mobilePoster && content.mobilePoster[0]}
          />
          <button className="c-full-width-video__button bg-primary-teal hover:bg-primary-bright-teal transition-colors duration-300 text-neutral-white flex items-center justify-center w-24 h-24 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <ASvg
              className="fill-current w-8 h-8"
              svg={SvgIconPlay}
              ariaLabel="Play"
            />
          </button>
        </div>
      </div>
      {siteInfo.isUK && content?.longVideo?.[0]?.embeddedAsset?.url && (
        <FormVideoModal
          videoSrc={content?.longVideo?.[0]?.embeddedAsset?.url}
          openModalLabel={`Click here to access ${testimonialName}'s full story`}
          form={form}
        />
      )}
    </>
  )
}

export const CFullWidthVideoFragments = {
  field: `
    fragment fullWidthVideo on fullWidthVideo_MatrixField {
      ... on fullWidthVideo_block_BlockType {
        id
        typeHandle
        idName
        desktopPoster: poster {
          url @transform(mode: "crop", width: 2000, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        mobilePoster: poster {
          url @transform(mode: "crop", width: 640, immediately: true)
          title
          hasFocalPoint
          focalPoint
        }
        video {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
        longVideo {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
        videoOrientation
        position
      }
    }
  `,
}
