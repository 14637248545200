import getAllLocaleInfo from '../helpers/get-all-locale-info'
import { useSiteInfo } from 'shared'

const useAllLocaleInfo = () => {
  const siteInfo = useSiteInfo()

  return getAllLocaleInfo(siteInfo?.layout ?? 'icl')
}

export default useAllLocaleInfo
