import React, { useState } from 'react'
import classNames from 'classnames'
import { ASvg, FormData, MFormSearch } from 'ui'
import { useTranslation } from 'next-i18next'
import SvgIconLocation from 'ui/svg/icons/fill/location.svg'
import SvgIconError from 'ui/svg/icons/fill/error.svg'
import SvgIconCheck from 'ui/svg/icons/fill/check.svg'
import './c-kr-newsletter.css'

type Props = {
  margins?: boolean
}

export const CKrNewsletter = ({ margins = false }: Props) => {
  const { t } = useTranslation('common')

  const [formSearchValue, setFormSearchValue] = useState('')
  const [checked, setChecked] = useState(false)
  const [inputStatusLabel, setInputStatusLabel] = useState('')
  const [inputIsError, setInputIsError] = useState(false)

  const [checkedIsError, setCheckedIsError] = useState(false)
  const [checkStatusLabel, setCheckStatusLabel] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }

  const dispatchEvent = (e: React.MouseEvent) => {
    e.preventDefault()
    const event = new CustomEvent('showKrReferencesModal')
    document.dispatchEvent(event)
  }

  const submitSubscribeForNewsletter = (data: FormData) => {
    if (isLoading) {
      return
    }

    setFormSearchValue(data.q)

    const actionUrl =
      'https://stibee.com/api/v1.0/lists/j9Fe6vJxBnof-H9hblDJYri_gXBC/public/subscribers'

    setInputStatusLabel('')
    setInputIsError(false)

    setCheckStatusLabel('')
    setCheckedIsError(false)

    if (!checked) {
      setCheckStatusLabel('kr-newsletter.submit-no-check-error')
      setCheckedIsError(true)
    } else if (formSearchValue.length === 0) {
      setInputStatusLabel('kr-newsletter.submit-no-email-error')
      setInputIsError(true)
    } else if (!validateEmail(formSearchValue)) {
      setInputStatusLabel('kr-newsletter.submit-error')
      setInputIsError(true)
    } else {
      setIsLoading(true)
      fetch(actionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `email=${formSearchValue}`,
      })
        .then((response) => {
          return response.text()
        })
        .then((data) => {
          /*
            Stibee API call responds with HTML that contains the error message at the top of the
            page commented out. So Stibee's js script splits it out and just returns it as the
            error message.

            This implementation aims to recreate that, main difference being that I have just
            copied the success message from the API and return that rather than implement the
            extra logic they go through.
          */
          const errorMessage = data.split('ERROR_MSG:')

          if (errorMessage.length > 1) {
            setInputStatusLabel(errorMessage[1].split('-->')[0])
            setInputIsError(true)
          } else {
            setInputStatusLabel('kr-newsletter.submit-success')
            setInputIsError(false)
          }
        })
        .catch((error) => {
          console.log('error', error)
          setInputStatusLabel('kr-newsletter.submit-error')
          setInputIsError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const validateEmail = (email: string) => {
    // regex used in type=”email” from W3C:
    const re =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return re.test(String(email).toLowerCase())
  }

  return (
    <section
      className={classNames('relative c-kr-newsletter bg-primary-teal', {
        'mx-3 md:mx-6': margins,
      })}
    >
      <div className="relative flex items-center px-5 py-32 c-kr-newsletter__container">
        <div className="w-full c-kr-newsletter__content">
          <div className="mx-auto c-kr-newsletter__body">
            <h2
              className="font-normal text-center text-neutral-white text-h5 c-kr-newsletter__heading"
              dangerouslySetInnerHTML={{ __html: t('kr-newsletter.heading') }}
            />
            <h4
              className="mt-4 font-semibold text-center text-h7 text-neutral-white"
              dangerouslySetInnerHTML={{
                __html: t('kr-newsletter.sub-heading'),
              }}
            />
            <p
              className="mt-4 font-semibold text-center text-p-sm text-neutral-white"
              dangerouslySetInnerHTML={{
                __html: t('kr-newsletter.disclaimer'),
              }}
            />
          </div>
          <div className="relative flex flex-col mx-auto bg-neutral-white p-4 rounded-3xl mt-8 md:flex-row md:rounded-full md:p-6 c-kr-newsletter__search-box">
            <div
              className={classNames(
                'mb-8 md:flex-grow rounded-full md:p-0 md:mb-0 bg-neutral-white md:bg-transparent',
                [
                  [
                    !inputStatusLabel
                      ? 'text-primary-gray'
                      : inputIsError
                      ? 'text-secondary-red'
                      : 'text-secondary-green',
                  ],
                ]
              )}
            >
              <MFormSearch
                icon={
                  <ASvg
                    className="fill-current w-6 h-6"
                    svg={SvgIconLocation}
                    ariaLabel={t('kr-newsletter.submit-button') || ''}
                  />
                }
                text={t('kr-newsletter.input-label') || ''}
                type="text"
                onSubmit={submitSubscribeForNewsletter}
              />
            </div>
            <div
              className={classNames(
                'absolute flex w-auto opacity-0 bottom-0 mb-16 pl-4 text-p-sm font-bold md:mb-1',
                [
                  inputStatusLabel !== '' ? 'opacity-100' : '',
                  inputIsError ? 'text-secondary-red' : 'text-secondary-green',
                ]
              )}
            >
              {inputIsError && (
                <ASvg
                  className="fill-current w-5 h-5"
                  svg={SvgIconError}
                  ariaHidden="true"
                />
              )}

              {!inputIsError && (
                <ASvg
                  className="fill-current w-5 h-5"
                  svg={SvgIconCheck}
                  ariaHidden="true"
                />
              )}

              {t(inputStatusLabel)}
            </div>
          </div>
          <div className="relative px-4 mx-auto mt-3 c-kr-newsletter__search-box">
            <label className="pt-6 font-semibold text-neutral-white text-p-sm">
              <input type="checkbox" onInput={handleCheck} />
              <a href="#" className="underline ml-1" onClick={dispatchEvent}>
                {t('kr-newsletter.checkbox-link')}
              </a>
              {t('kr-newsletter.checkbox-label')}
            </label>
            <div
              className={classNames(
                'absolute w-auto opacity-0 bottom-0 pl-2 -mb-5 text-p-sm font-bold flex items-center',
                [
                  checkedIsError ? 'opacity-100' : '',
                  checkedIsError
                    ? 'text-secondary-red'
                    : 'text-secondary-green',
                ]
              )}
            >
              <ASvg
                className="fill-current w-5 h-5"
                svg={SvgIconError}
                ariaHidden="true"
              />
              {t(checkStatusLabel)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
