import { useTranslation } from 'next-i18next'
import { ASvg } from '../atoms/a-svg'
import SvgIconSearch from '../../svg/icons/stroke/search.svg'
import SvgTopArrowIcon from '../../svg/icons/stroke/chevron-top.svg'
import classNames from 'classnames'
import { CExpandableFaqsBlock } from './c-expandable-faqs'
import { MutableRefObject } from 'react'
import './c-expandable-faqs-us.css'
import { useRouter } from 'next/router'
import Link from 'next/link'

interface CExpandableFaqsUsProps {
  content: CExpandableFaqsBlock | { idName: string; faqs: any[] }
  showSearchBar: boolean
  searchValue: string
  handleSearchInput: (event: any) => void
  expandedItem: number | null
  setExpandedItem: (param: number | null) => void
  blockRef: MutableRefObject<(HTMLDivElement | null)[]>
  itemHeights: number[]
}
export const CExpandableFaqsUs = ({
  content,
  showSearchBar,
  searchValue,
  handleSearchInput,
  expandedItem,
  setExpandedItem,
  blockRef,
  itemHeights,
}: CExpandableFaqsUsProps) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const isPage = Boolean(router.asPath === '/faq')
  return (
    <section
      id={content.idName}
      className="c-expandable-faqs-us bg-neutral-teal-light py-[50px] md:py-[100px]"
    >
      <div className="o-container o-container--lg">
        <h2 className="text-4xl font-semibold text-gray-600">
          Frequently Asked Questions
        </h2>
        {showSearchBar && (
          <>
            <h3 className="text-h6 text-primary-teal">
              {t('search-faqs') || ''}
            </h3>
            <form
              className="mt-4 flex border-2 border-neutral-dark-gray rounded-full overflow-hidden pis-6 py-3 pie-4"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className="flex-grow font-button text-neutral-dark-gray placeholder-primary-gray uppercase font-bold outline-none"
                placeholder={t('type-a-question') || ''}
                value={searchValue}
                onChange={handleSearchInput}
              />
              <ASvg
                className="stroke-current flex-shrink-0 w-8 h-8"
                svg={SvgIconSearch}
              />
            </form>
          </>
        )}
        <div className="c-expandable-faqs-us__items md:mt-8">
          {content.faqs?.map((faq, n) => (
            <div
              key={faq.id}
              className={classNames('c-expandable-faqs-us__item py-7', {
                'c-expandable-faqs-us__item--expanded': n === expandedItem,
              })}
            >
              <button
                className="text-neutral-dark-gray text-lg md:text-p-lg w-full flex items-center"
                onClick={() => setExpandedItem(n === expandedItem ? null : n)}
              >
                <span className="flex-grow text-start font-semibold text-primary-dark-teal">
                  {faq.title}
                </span>
                <div className="c-expandable-faqs-us__icon flex-shrink-0 mis-8 text-primary-dark-teal">
                  <ASvg
                    className="stroke-current w-6 h-6 md:w-8 md:h-8"
                    svg={SvgTopArrowIcon}
                  />
                </div>
              </button>
              <div
                ref={(el) => {
                  blockRef.current[n] = el
                }}
                className="c-expandable-faqs-us__answer md:text-lg text-primary-gray w-full s-wysiwyg"
                style={{ height: n === expandedItem ? itemHeights[n] : 0 }}
                dangerouslySetInnerHTML={{ __html: faq.copy }}
              />
            </div>
          ))}
        </div>
        {!isPage && (
          <div className="flex justify-center w-full mt-[40px] md:mt-[60px]">
            <Link
              href="/faq"
              className="text-xl font-semibold text-primary-dark-teal underline"
            >
              {t('view-all')}
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}
