import { throttle } from 'lodash'
import { useMemo, useRef, useEffect } from 'react'
import {
  CraftEmbeddedAssetField,
  CraftAssetField,
  CraftLinkItField,
} from 'tsconfig/craft-types'
import useStateRef from 'discovericl/hooks/use-state-ref'
import { AButton, APicture } from 'ui'
import Hls from 'hls.js'
import './c-background-image-copy.css'

export type CBackgroundImageCopyBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  theme?: string
  bodyAlignInline?: string
  bodyAlignBlock?: string
  backgroundImageFade?: Array<CraftAssetField>
  backgroundImageFadeMobile?: Array<CraftAssetField>
  backgroundImage?: Array<CraftAssetField>
  backgroundImageMobile?: Array<CraftAssetField>
  backgroundVideo?: Array<CraftEmbeddedAssetField>
  heading?: string
  copy?: string
  button?: CraftLinkItField
}

type Props = {
  field: Array<CBackgroundImageCopyBlock>
}

export const CBackgroundImageCopy = ({ field }: Props) => {
  const [blocksActive, setBlocksActive, blocksActiveRef] = useStateRef([])
  const [blocksActiveDown, setBlocksActiveDown, blocksActiveDownRef] =
    useStateRef([])

  const backgroundVideoRef = useRef<Array<HTMLVideoElement | null>>([])
  const blockRef = useRef<Array<HTMLElement | null>>([])

  const content = useMemo(() => {
    return field && field.length ? field : []
  }, [field])

  const handleWindowScroll = () => {
    blockRef.current.forEach((element, index) => {
      const blockIsActive: boolean =
        (element
          ? element.getBoundingClientRect().top <= window.innerHeight * 0.5
          : false) &&
        (element
          ? element.getBoundingClientRect().bottom >= window.innerHeight * 0.5
          : false)

      setBlocksActive(
        blocksActiveRef.current.map((item: boolean, itemIndex: number) => {
          return itemIndex === index ? blockIsActive : item
        })
      )

      const blockIsActiveDown: boolean = element
        ? element.getBoundingClientRect().top <= window.innerHeight * 0.5
        : false
      if (
        backgroundVideoRef.current &&
        !backgroundVideoRef.current[index]?.played.length &&
        blockIsActiveDown
      ) {
        backgroundVideoRef.current[index]?.play()
      }
      blocksActiveDownRef[index] = blockIsActive
      setBlocksActiveDown(
        blocksActiveDownRef.current.map((item: boolean, itemIndex: number) => {
          return itemIndex === index ? blockIsActive : item
        })
      )
    })
  }

  const handleWindowScrollThrottled = throttle(handleWindowScroll, 100)

  useEffect(() => {
    blockRef.current = blockRef.current.slice(0, content.length)
  }, [content])

  useEffect(() => {
    if (content) {
      setBlocksActive(Array(content.length).fill(false))
      setBlocksActiveDown(Array(content.length).fill(false))
      window.addEventListener('scroll', handleWindowScrollThrottled)
      handleWindowScroll()

      content.forEach((block, blockIndex) => {
        if (block.backgroundVideo?.length) {
          const assetUrl = block.backgroundVideo[0].embeddedAsset?.url || ''
          const videoUrl = block.backgroundVideo[0].url || ''
          const videoEl = backgroundVideoRef.current[blockIndex]!

          if (assetUrl && Hls.isSupported()) {
            const hls = new Hls()
            hls.on(Hls.Events.ERROR, console.error)
            hls.loadSource(assetUrl)
            hls.attachMedia(videoEl)
          } else if (
            assetUrl &&
            videoEl.canPlayType('application/vnd.apple.mpegurl')
          ) {
            videoEl.src = assetUrl
          } else if (videoUrl) {
            videoEl.src = videoUrl
          }
        }
      })
    }

    return () => {
      if (content) {
        window.removeEventListener('scroll', handleWindowScrollThrottled)
      }
    }
  }, [content])

  return (
    <div className="c-background-image-copy">
      {content?.map((block, blockIndex) => {
        return (
          <section
            id={block.idName}
            key={blockIndex}
            ref={(el) => {
              blockRef.current[blockIndex] = el
            }}
            className={`
              relative m-3 overflow-hidden md:m-6 c-background-image-copy__block"
              ${
                block.theme === 'dark'
                  ? 'bg-neutral-dark-gray text-neutral-white'
                  : ''
              }
              ${
                block.theme === 'light'
                  ? 'bg-neutral-white text-neutral-dark-gray'
                  : ''
              }
              ${
                block.bodyAlignInline === 'start'
                  ? 'c-background-image-copy__block--body-align-inline_start'
                  : ''
              }
              ${
                block.bodyAlignInline === 'end'
                  ? 'c-background-image-copy__block--body-align-inline_end'
                  : ''
              }
              ${
                block.bodyAlignBlock === 'start'
                  ? 'c-background-image-copy__block--body-align-block_start'
                  : ''
              }
              ${
                block.bodyAlignBlock === 'end'
                  ? 'c-background-image-copy__block--body-align-block_end'
                  : ''
              }
            `}
          >
            {!!(
              block.backgroundImageFade && block.backgroundImageFade.length
            ) && (
              <APicture
                className="absolute top-0 left-0 object-cover w-full h-full c-background-image-copy__background-image-fade"
                imageDesktop={
                  block.backgroundImageFade && block.backgroundImageFade[0]
                }
                imageMobile={
                  block.backgroundImageFadeMobile &&
                  block.backgroundImageFadeMobile[0]
                }
              />
            )}
            {!!block.backgroundImage?.length && (
              <APicture
                className={`
                  absolute top-0 left-0 object-cover w-full h-full transition-all duration-700 c-background-image-copy__background-image
                  ${
                    !block.backgroundImageFade?.length &&
                    !blocksActiveDown[blockIndex]
                      ? 'transform scale-125'
                      : ''
                  }
                  ${
                    block.backgroundImageFade?.length &&
                    !blocksActiveDown[blockIndex]
                      ? 'opacity-0'
                      : ''
                  }
                  ${block.backgroundImageFade?.length ? 'delay-300' : ''}
                `}
                imageDesktop={block.backgroundImage && block.backgroundImage[0]}
                imageMobile={
                  block.backgroundImageMobile && block.backgroundImageMobile[0]
                }
              />
            )}
            {!!block.backgroundVideo?.length && (
              <video
                className={`
                  absolute top-0 left-0 object-cover w-full h-full transition-all duration-700 c-background-image-copy__background-video
                  ${
                    !block.backgroundImageFade?.length &&
                    !blocksActiveDown[blockIndex]
                      ? 'transform scale-125'
                      : ''
                  }
                  ${
                    block.backgroundImageFade?.length &&
                    !blocksActiveDown[blockIndex]
                      ? 'opacity-0'
                      : ''
                  }
                  ${block.backgroundImageFade?.length ? 'delay-300' : ''}
                `}
                ref={(el) => {
                  backgroundVideoRef.current[blockIndex] = el
                }}
                autoPlay
                muted
                playsInline
                loop
                aria-label={block.backgroundVideo[0].title}
              />
            )}
            <div
              className={`
                relative flex px-6 py-16 md:items-center c-background-image-copy__container
                ${block.bodyAlignInline === 'start' ? 'md:justify-start' : ''}
                ${block.bodyAlignInline === 'end' ? 'md:justify-end' : ''}
                ${block.bodyAlignBlock === 'start' ? 'items-start' : ''}
                ${block.bodyAlignBlock === 'end' ? 'items-end' : ''}
              `}
            >
              <div className="w-full md:w-1/2">
                <div
                  className={`
                    w-full mx-auto transition-opacity duration-500 c-background-image-copy__body
                    ${!blocksActive[blockIndex] ? 'opacity-0' : ''}
                  `}
                >
                  {block.heading && (
                    <h2
                      className="font-normal text-h6 whitespace-pre-line"
                      dangerouslySetInnerHTML={{ __html: block.heading }}
                    />
                  )}
                  {block.copy && (
                    <div
                      className="mt-4 font-semibold text-p s-wysiwyg"
                      dangerouslySetInnerHTML={{ __html: block.copy }}
                    />
                  )}
                  {block.button?.url && (
                    <div
                      className={`mt-8 transition-transform duration-500 delay-200 transform
                        ${!blocksActive[blockIndex] ? 'translate-y-8' : ''}
                      `}
                    >
                      <AButton
                        style="outline"
                        color={block.theme === 'dark' ? 'white' : 'gray'}
                        type={block.button.type === 'url' ? 'a' : 'link'}
                        href={block.button?.url}
                        rel={block.button.type === 'url' ? 'noreferrer' : ''}
                        target={block.button.target}
                      >
                        <span>{block.button.text}</span>
                      </AButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )
      })}
    </div>
  )
}

const blockFields = `
  id
  typeHandle
  idName
  theme
  bodyAlignInline
  bodyAlignBlock
  backgroundImageFade {
    url @transform(mode: "crop", width: 2000, immediately: true)
    title
  }
  backgroundImageFadeMobile {
    url @transform(mode: "crop", width: 640, immediately: true)
    title
  }
  backgroundImage {
    url @transform(mode: "crop", width: 2000, immediately: true)
    title
  }
  backgroundImageMobile {
    url @transform(mode: "crop", width: 640, immediately: true)
    title
  }
  backgroundVideo {
    url
    title
    embeddedAsset {
      title
      url
      providerName
    }
  }
  heading
  copy
  button {
    type
    url
    text
    target
  }
`

export const CBackgroundImageCopyFragments = {
  field: `
    fragment backgroundImageCopy on backgroundImageCopy_MatrixField {
      ... on backgroundImageCopy_block_BlockType {
        ${blockFields}
      }
    }
  `,
  field2: `
    fragment backgroundImageCopy2 on backgroundImageCopy2_MatrixField {
      ... on backgroundImageCopy2_block_BlockType {
        ${blockFields}
      }
    }
  `,
  field3: `
    fragment backgroundImageCopy3 on backgroundImageCopy3_MatrixField {
      ... on backgroundImageCopy3_block_BlockType {
        ${blockFields}
      }
    }
  `,
  uiField: `
    fragment uiBackgroundImageCopy on uiBackgroundImageCopy_MatrixField {
      ... on uiBackgroundImageCopy_block_BlockType {
        ${blockFields}
      }
    }
  `,
}
