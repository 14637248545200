import { AButton } from 'ui'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import classNames from 'classnames'
import './c-404.css'

type Props = {
  theme: string
}

const defaultProps = {
  theme: 'teal',
}

const C404 = ({ theme }: Props) => {
  const { t } = useTranslation('common')

  return (
    <div
      className={classNames(
        'c-404 w-full flex flex-col justify-center items-center bg-primary-teal relative overflow-hidden',
        {
          'bg-primary-teal': theme === 'teal',
          'bg-pink-500': theme === 'pink',
        }
      )}
    >
      <Image
        className="absolute top-0 left-0 hidden md:block"
        src="/img/bg-shapes1.png"
        alt="Background shape"
        width={470}
        height={796}
      />
      <Image
        className="absolute bottom-0 right-0 block"
        src="/img/bg-shapes2.png"
        alt="Background shape"
        width={318}
        height={317}
      />
      <h1
        className={classNames('c-404__heading font-black', {
          'text-[#27b1c1]': theme === 'teal',
          'text-pink-400': theme === 'pink',
        })}
      >
        404
      </h1>
      <h2 className="c-404__subheading text-h7 md:text-h6 text-white text-center mt-14">
        {t('404-line1')}
        <br className="hidden md:block" />
        {t('404-line2')}
      </h2>
      <AButton
        className="mt-14"
        type="link"
        href="/"
        size="lg"
        style="solid"
        color={theme === 'teal' ? 'white' : 'pink'}
      >
        <span>{t('go-to-homepage')}</span>
      </AButton>
    </div>
  )
}

C404.defaultProps = defaultProps

export { C404 }
