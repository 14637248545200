import { parseCookies } from 'nookies'
import { useEffect, useMemo, useState } from 'react'

export const CPreviewToast = () => {
  const cookies = parseCookies()
  const previewID = cookies.staar_use_preview
  const id = `preview-toast-${previewID}`
  const [showToast, setShowToast] = useState(false);

  const isValidPreviewID = useMemo(() => {
    const parsedNumber = Number(previewID)
    return !isNaN(parsedNumber) && parsedNumber > 0
  }, [previewID])

  const isDevPreview = Boolean(previewID === 'dev')

  useEffect(() => {
    if ((!isValidPreviewID && !isDevPreview) || showToast) return;

    setShowToast(true);

  }, [isValidPreviewID, isDevPreview, showToast]);

  if (!showToast) return null;

  return (
    <div
      id={id}
      className="fixed z-[999] bottom-0 left-0 right-0 bg-blue-500 text-white p-3 text-center"
    >
      {`You're currently looking at the preview environment from `}
      <a
        href={
          isDevPreview
            ? 'https://github.com/visiture-dev/staar-discovericl-unified/tree/staging'
            : `https://github.com/visiture-dev/staar-discovericl-unified/pull/${previewID}`
        }
        className="underline"
      >
        {isDevPreview ? 'staging' : `PR #${previewID}`}
      </a>
      {' - To switch back to the staging environment '}
      <a href="/?staar_use_preview=" className="underline">
        Click Here
      </a>
      .
    </div>
  );
}

export default CPreviewToast
